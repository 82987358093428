/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */

export enum InvalidInternalReportErrorErrorCode {
  invalid_internal_report_error = 'invalid_internal_report_error',
}
