import { StateCreator } from 'zustand'
import { StoreVendorProductDto } from '@vori/dashboard-rest-next/schemas/storeVendorProductDto'
import { createStoreVendorProductMerge } from '@vori/dashboard-rest-next/products/products'
import { queryClientInstance } from './networking/queryClient'
import { createSaveDataFromPendingItem } from './storeTypesAndUtils'
import { UpdateStoreVendorProductDto } from '@vori/dashboard-rest-next/schemas'
import { captureException } from '@vori/dashboard-integrations/Sentry/utils'
import { VendorsSlice } from './VendorsStore'

export type MergeProductsSlice = {
  loading: boolean
  error: Error | null
  success: boolean
  activeAbortController: null | AbortController
  mergeCandidates: StoreVendorProductDto[]
  updateMergeCandidates: (
    productOne: StoreVendorProductDto,
    productTwo: StoreVendorProductDto,
  ) => void
  saveMergedProduct: (
    pendingData: Partial<StoreVendorProductDto>,
    callback?: () => void,
  ) => void
}

export const createMergeProductsSlice: StateCreator<MergeProductsSlice> = (
  set,
  get,
  store,
) => ({
  loading: false,
  success: false,
  error: null,
  activeAbortController: null,
  mergeCandidates: [],
  updateMergeCandidates: (productOne, productTwo) => {
    set({
      mergeCandidates: [productOne, productTwo],
    })
  },
  saveMergedProduct: async (pendingData, callback) => {
    const mergeCandidates = get().mergeCandidates
    const currentAbortController = new AbortController()
    set({
      activeAbortController: currentAbortController,
      loading: true,
      error: null,
    })

    const convertedMergeProduct = createSaveDataFromPendingItem<
      StoreVendorProductDto,
      UpdateStoreVendorProductDto
    >(pendingData)

    convertedMergeProduct.storeProductID = pendingData.store_product_id
    convertedMergeProduct.storeVendorID = pendingData.store_vendor?.id

    try {
      await queryClientInstance.fetchQuery([], () =>
        createStoreVendorProductMerge(
          {
            keep: mergeCandidates[0].id,
            remove: mergeCandidates[1].id,
            updateData: convertedMergeProduct,
          },
          { signal: currentAbortController.signal },
        ),
      )

      set({
        loading: false,
        success: true,
        error: null,
        activeAbortController: null,
      })

      // Known issue: The type of this "store" param is incorrect
      const vendorsStore = (
        store.getState() as unknown as { vendors: VendorsSlice }
      ).vendors
      vendorsStore.addStoreVendorProductDuplicateOverrides({
        id: mergeCandidates[0].id,
      })

      if (callback) {
        callback()
      }
    } catch (error) {
      set({
        error: error as Error,
        loading: false,
        success: false,
        activeAbortController: null,
      })
      captureException(
        (scope) => {
          scope.setContext('createStoreVendorProductMerge failed', {
            errorInfo: error,
          })
          return error as Error
        },
        {
          sentryTag: 'stateManagementStoreVendorProductMerge',
        },
      )
    } finally {
      set({
        error: null,
        loading: false,
        success: false,
        activeAbortController: null,
      })
    }
  },
})
