import React from 'react'

import { AppSidebarUser } from '@vori/dashboard-components/AppSidebarUser'
import { SidebarGroup } from '@vori/dashboard-components/shadcn/ui/sidebar'

import { useCurrentUserState } from '@vori/dashboard-hooks/useCurrentUser'

export function AppSidebarHeader({
  icon,
}: {
  icon: React.ElementType
}): JSX.Element {
  const { user } = useCurrentUserState()

  return (
    <SidebarGroup>
      <div className="peer/menu-button flex w-full items-center gap-2 overflow-hidden rounded-md p-2 text-left outline-none ring-sidebar-ring transition-[width,height,padding] focus-visible:ring-2 group-has-[[data-sidebar=menu-action]]/menu-item:pr-8 aria-disabled:pointer-events-none aria-disabled:opacity-50 data-[active=true]:bg-sidebar-accent data-[active=true]:font-medium data-[active=true]:text-sidebar-accent-foreground group-data-[collapsible=icon]:!size-8 [&>span:last-child]:truncate [&>svg]:size-4 [&>svg]:shrink-0 h-12 text-sm group-data-[collapsible=icon]:!p-0 data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground justify-center">
        <AppSidebarUser
          displayName={user.data.displayName || 'N/A'}
          icon={icon}
          subTitle={user.data.email}
        />
      </div>
    </SidebarGroup>
  )
}
