import React from 'react'

import { HomeIcon } from 'lucide-react'

import {
  SidebarGroup,
  SidebarMenu,
} from '@vori/dashboard-components/shadcn/ui/sidebar'

import { CourierInbox } from '@vori/dashboard-integrations/Courier/CourierInbox'
import { ProductSearchTrigger } from '@vori/dashboard-components/products/ProductSearch/ProductSearchTrigger'
import { SidebarMenuLink } from '@vori/dashboard-components/SidebarMenuLink'

import { FeatureFlags } from '@vori/dashboard-constants'
import { routes } from '@vori/dashboard-routes/retail'
import { useFeatureConfig } from '@vori/dashboard-hooks/useFeatureConfig'

import { StoreSelector } from './StoreSelector'

export function RetailNavigationHeader(): JSX.Element | null {
  const canUseGlobalProductSearch = useFeatureConfig(
    FeatureFlags.WEB_FEATURE_GLOBAL_PRODUCT_SEARCH,
  )

  return (
    <>
      <StoreSelector />
      <SidebarGroup>
        <SidebarMenu>
          {canUseGlobalProductSearch && <ProductSearchTrigger />}
          <CourierInbox />
          <SidebarMenuLink to={routes.home.root.path}>
            <HomeIcon /> <span>Home</span>
          </SidebarMenuLink>
        </SidebarMenu>
      </SidebarGroup>
    </>
  )
}
