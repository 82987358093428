import React from 'react'

import { Location } from 'history'
import { NavLinkProps } from 'react-router-dom'

import {
  BanknoteIcon,
  BarChartIcon,
  BookOpenIcon,
  Building2Icon,
  NetworkIcon,
  GlobeIcon,
  CombineIcon,
  MailIcon,
  WrenchIcon,
  TruckIcon,
  UploadIcon,
  UploadCloudIcon,
  UsersIcon,
} from 'lucide-react'

import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuItem,
} from '@vori/dashboard-components/shadcn/ui/sidebar'

import { routes } from '@vori/dashboard-routes/dev'

import { SidebarMenuLink } from '@vori/dashboard-components/SidebarMenuLink'

function openOrderGuideUploadFlow(location: Location): boolean {
  return new URLSearchParams(location?.search)?.get('uploader-type') === 'true'
}

const links = [
  {
    href: routes.metrics.root.path,
    icon: <BarChartIcon />,
    label: 'Metrics',
  },
  {
    href: routes.users.root.path,
    icon: <UsersIcon />,
    label: 'Users',
  },
  {
    href: routes.stores.root.path,
    icon: <Building2Icon />,
    label: 'Stores',
  },
  {
    href: routes.vendors.root.path,
    icon: <TruckIcon />,
    label: 'Vendors',
  },
  {
    href: routes.adminVendors.root.path,
    icon: <TruckIcon />,
    label: 'Vendors (New)',
  },
  {
    href: routes.products.root.path,
    icon: <BookOpenIcon />,
    label: 'Products',
  },
  {
    href: routes.commandCenter.products.path,
    icon: <GlobeIcon />,
    label: 'Command Center',
  },
  {
    href: routes.storeVendorCatalog.root.path,
    icon: <CombineIcon />,
    label: 'Store Vendor Catalog',
  },
  {
    href: routes.products.upload.path,
    icon: <UploadIcon />,
    navLinkProps: {
      isActive: (match, location) =>
        !match || openOrderGuideUploadFlow(location) ? false : true,
    } as Partial<NavLinkProps>,
    label: 'Catalog Uploader',
  },
  {
    href: routes.products.uploadOrderGuide.path,
    navLinkProps: {
      isActive: (_, location) =>
        !openOrderGuideUploadFlow(location) ? false : true,
    } as Partial<NavLinkProps>,
    icon: <UploadCloudIcon />,
    label: 'Order Guide Upload',
  },
  {
    href: routes.banners.root.path,
    icon: <BanknoteIcon />,
    label: 'Banners',
  },
  {
    href: routes.invitations.adminVendorInvitations.path,
    icon: <MailIcon />,
    label: 'Vendor Invitations',
  },
  {
    href: routes.invitations.adminStoreInvitations.path,
    icon: <MailIcon />,
    label: 'Store Invitations',
  },
  {
    href: routes.retool.root.path,
    icon: <WrenchIcon />,
    label: 'Retool',
  },
  {
    href: routes.storeFiles.root.path,
    icon: <NetworkIcon />,
    label: 'Store Files',
  },
  {
    href: routes.revisions.root.path,
    icon: <UploadCloudIcon />,
    label: 'Revisions',
  },
  {
    href: routes.eslUpload.root.path,
    icon: <UploadCloudIcon />,
    label: 'ESL Upload',
  },
]

function DevNavigation(): JSX.Element | null {
  return (
    <SidebarGroup>
      <SidebarGroupLabel>Main</SidebarGroupLabel>
      <SidebarMenu>
        {links.map((link) => (
          <SidebarMenuItem key={link.href}>
            <SidebarMenuLink to={link.href} {...link.navLinkProps}>
              {link.icon}
              <span>{link.label}</span>
            </SidebarMenuLink>
          </SidebarMenuItem>
        ))}
      </SidebarMenu>
    </SidebarGroup>
  )
}

export { DevNavigation }
