import { CellValueChangedEvent, RowNode } from 'ag-grid-community'
import { compare, unescapePathComponent } from 'fast-json-patch'
import get from 'lodash/get'
import kebabCase from 'lodash/kebabCase'
import set from 'lodash/set'

import {
  APIGroupPatch,
  APIPatch,
  APIRowPatch,
  ChangesetRowStatus,
  JSONPatchEnvelope,
  PatchType,
} from './types'

function withForwardSlash<Endpoint extends string>(
  endpoint: Endpoint,
): `/${Endpoint}` {
  return `${!endpoint.startsWith('/') ? '/' : ''}${endpoint}` as `/${Endpoint}`
}

function generateJSONPatchEnvelope<TChanges extends Record<string, unknown>>(
  document: Record<string, unknown>,
  changes: TChanges,
  documentID?: string,
): JSONPatchEnvelope<TChanges> {
  const patch = compare(document, { ...document, ...changes })

  return {
    before: patch.reduce((before, { path }) => {
      const objectPath = unescapePathComponent(path)
        .replace(/\//gm, '.')
        .replace(/^\./, '')

      return set(before, objectPath, get(document, objectPath))
    }, {}),
    id: documentID || (document.id as string),
    patch,
    timestamp: new Date().toISOString(),
  }
}

function generateCellChangePatch<TChanges extends Record<string, unknown>>(
  rowID: string,
  params: CellValueChangedEvent,
): APIPatch<TChanges> {
  const groupCols = []
  let traversalNode = params.node
  while (traversalNode !== undefined && traversalNode.field !== undefined) {
    groupCols.push(traversalNode.field)
    traversalNode = traversalNode.parent as RowNode
  }

  const route = params.node.getRoute()

  const column = params.colDef.colId
  if (!column) {
    throw new Error(`Expected valid colId: got ${column}`)
  }

  const before = {
    [column]: params.oldValue,
  } as Record<string, unknown>
  const changes = {
    [column]: params.newValue,
  } as TChanges

  if (!route) {
    return {
      type: PatchType.ROW,
      status: ChangesetRowStatus.UNSTAGED,
      ...generateJSONPatchEnvelope(before, changes, rowID),
    }
  }

  const columns = [...groupCols].reverse()

  if (columns.length !== route.length) {
    throw new Error(`Expected groupCols length and groupKeys to be equal`)
  }

  return {
    type: PatchType.GROUP,
    status: ChangesetRowStatus.UNSTAGED,
    columns: columns as string[],
    groupKeys: route,
    ...generateJSONPatchEnvelope(before, changes),
  }
}

function generateRowPatchJSONEnvelope<TChanges extends Record<string, unknown>>(
  rowData: Record<string, unknown>,
  changes: TChanges,
  status: ChangesetRowStatus = ChangesetRowStatus.UNSTAGED,
): APIRowPatch<TChanges> {
  const patch = compare(rowData, { ...rowData, ...changes })

  return {
    before: patch.reduce((before, { path }) => {
      const objectPath = unescapePathComponent(path)
        .replace(/\//gm, '.')
        .replace(/^\./, '')

      return set(before, objectPath, get(document, objectPath))
    }, {}),
    id: rowData.id as string,
    patch,
    status,
    timestamp: new Date().toISOString(),
    type: PatchType.ROW,
  }
}

function generateGroupPatchJSONEnvelope<
  TChanges extends Record<string, unknown>,
>(
  groupData: Pick<APIGroupPatch<TChanges>, 'groupKeys' | 'columns'>,
  changes: TChanges,
  status: ChangesetRowStatus = ChangesetRowStatus.UNSTAGED,
): APIGroupPatch<TChanges> {
  return {
    ...groupData,
    patch: compare({}, changes),
    status,
    timestamp: new Date().toISOString(),
    type: PatchType.GROUP,
  }
}

function getClientSideKey(storageKey: string): string {
  return `client-key-${kebabCase(storageKey)}`
}

async function handleHTTPResponse<
  TResponse extends Record<string, unknown> | unknown,
>(_url: string, _request: RequestInit, response: Response) {
  let text: string = ''
  let data: TResponse = {} as TResponse

  text = await response.text()

  data = text ? JSON.parse(text) : {}

  return data as TResponse
}

export {
  generateCellChangePatch,
  generateGroupPatchJSONEnvelope,
  generateJSONPatchEnvelope,
  generateRowPatchJSONEnvelope,
  getClientSideKey,
  handleHTTPResponse,
  withForwardSlash,
}
