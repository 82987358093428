// These are used to query the feature flags endpoint
export enum FeatureFlags {
  CUSTOM_PRICING = 'dash.custom_pricing',
  IOS_INVENTORY = 'ios.inventory.final',
  IOS_ORDERING = 'ios.ordering',
  MISSCANS = 'dash.misscans',
  PROMOTIONS = 'dash.promotions',
  VENDOR_CRM_BULK_UPLOAD = 'dash.vendor.crm.bulkupload',
  WEB_FEATURE_B2B_PAYMENTS = 'web.feature.b2b_payments',
  WEB_FEATURE_BULK_PRODUCT_UPLOAD = 'web.feature.bulk_product_upload',
  WEB_FEATURE_CAMPAIGNS_BIRTHDAY_BONUS = 'web.feature.campaigns_birthday_bonus',
  WEB_FEATURE_CONTAINERS = 'web.feature.containers',
  WEB_FEATURE_COUNTRY_OF_ORIGIN = 'web.feature.country_of_origin',
  WEB_FEATURE_DEPARTMENT_KANBAN = 'web.feature.department_kanban',
  WEB_FEATURE_GLOBAL_PRODUCT_SEARCH = 'web.feature.global_product_search',
  WEB_FEATURE_IGNORE_POS_SYNC = 'web.feature.ignore_pos_sync',
  WEB_FEATURE_NATIVE_EOD = 'web.feature.native_eod',
  WEB_FEATURE_NEW_DEPARTMENTS = 'web.feature.new_department',
  WEB_FEATURE_NEW_PRODUCT_MODAL = 'web.feature.new_product_modal',
  WEB_FEATURE_PROMOTION_IMPORT = 'web.feature.promotion_import',
  WEB_FEATURE_REPORTING_BOOKMARKS = 'web.feature.reporting_bookmarks',
  WEB_FEATURE_REPORTING_BOOKMARKS_CREATE_DELETE = 'web.feature.reporting_bookmarks_create_delete',
  WEB_FEATURE_RETAIL_CAMPAIGNS = 'web.feature.retail_campaigns',
  WEB_FEATURE_RETAIL_EOD = 'web.feature.retail_eod',
  WEB_FEATURE_RETAIL_LOYALTY = 'web.feature.retail_loyalty',
  WEB_FEATURE_RETAIL_PROMOTIONS = 'web.feature.retail_promotions',
  WEB_FEATURE_RETAIL_TILLS = 'web.feature.retail_tills',
  WEB_FEATURE_SCHEDULED_EXPORT = 'web.feature.scheduled_export',
  WEB_FEATURE_SET_INVENTORY = 'web.feature.set_inventory',
  WEB_FEATURE_SIGMA = 'web.feature.sigma',
  WEB_FEATURE_STORE_SELECTOR = 'web.feature.store_selector',
  WEB_FEATURE_TRANSACTION_VIEWER = 'web.feature.transaction-viewer',
  WEB_FEATURE_USERS = 'web.feature.users',
  WEB_FEATURE_VENDORS_NEXT_DUPLICATES_FILTER = 'web.feature.vendors_next.duplicates_filter',
  WEB_NAVIGATION_BACK_OFFICE_ADMIN = 'web.navigation.back_office_admin',
  WEB_NAVIGATION_BACK_OFFICE_PRODUCTS = 'web.navigation.back_office_products',
  WEB_NAVIGATION_FRONT_OFFICE = 'web.navigation.front_office',
  WEB_NAVIGATION_INVOICES_NEXT = 'web.navigation.invoices_next',
  WEB_NAVIGATION_LEGACY = 'web.navigation.legacy',
  WEB_NAVIGATION_REPORTING = 'web.navigation.reporting',
  WEB_NAVIGATION_STORE_FILES = 'web.navigation.store_files',
  WEB_NAVIGATION_VENDORS_NEXT = 'web.navigation.vendors_next',
  WEB_NOTIFICATIONS_INBOX = 'web.notifications.inbox',
  // TODO (VOR-9787) Remove flag after deploying to POS
  WEB_ENABLE_PRODUCT_LOYALTY_TOGGLE = 'web.enable_product_loyalty_toggle',
  WEB_NAVIGATION_REVISIONS = 'web.navigation.revisions',
}

export enum PosBannerConfigurationFlags {
  WEB_FEATURE_LOYALTY_GIFT_CARDS = 'web.feature.loyalty_gift_cards',
  WEB_FEATURE_PHYSICAL_GIFT_CARDS = 'web.feature.physical_gift_cards',
  WEB_FEATURE_RETAIL_HOUSE_ACCOUNTS = 'web.feature.retail_house_accounts',
}

export enum SentryTags {
  AUTH_PREFIX = 'auth_',
  CAPTURE_EXCEPTION = 'capture_exception',
  CAPTURE_MESSAGE = 'capture_message',
  ERROR_BOUNDARY = 'error_boundary',
  FORM_VALIDATION = 'form_validation',
  GENERIC_ERROR_BOUNDARY = 'generic_error_boundary',
  GLOBAL_ERROR_BOUNDARY = 'global_error_boundary',
  NETWORK_EXCEPTION = 'network_exception',
  PRODUCT_SEARCH = 'product_search',
  STORE_PRODUCTS = 'store_products',
}

export { COUNTRY_DATA } from './countries'

export const GATED_FEATURE_IDs = Object.values(FeatureFlags)
