import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import {
  ApplicationDataSlice,
  createApplicationDataSlice,
} from './ApplicationDataStore'
import {
  createErrorManagementSlice,
  ErrorManagementSlice,
} from './ErrorManagementStore'
import { createGlobalModalSlice, GlobalModalSlice } from './GlobalModalStore'
import {
  createInvoiceDetailsSlice,
  InvoiceDetailsSlice,
} from './InvoiceDetailsStore'
import {
  createMergeProductsSlice,
  MergeProductsSlice,
} from './MergeProductsStore'
import { createProductSlice, ProductsSlice } from './ProductStore'
import { createRevisionSlice, RevisionSlice } from './RevisionStore'
import {
  GlobalStoreKeys,
  GlobalStoreType,
  sliceArgumentsCreator,
} from './storeTypesAndUtils'
import { createUserSlice, UserSlice } from './UserStore'
import {
  createVendorsSlice,
  StoreVendorProductDuplicateOverrides,
  VendorsSlice,
} from './VendorsStore'

const DEFAULT_STORE_PERSIST_KEY = 'dash-state-storage' as const

export const { getSessionPersistKey, updateSessionPersistKey } = (() => {
  let currentKey = `${DEFAULT_STORE_PERSIST_KEY.toString()}`

  return {
    updateSessionPersistKey: (sessionKey: string) => {
      currentKey = sessionKey.length
        ? `session-key-${sessionKey}`
        : DEFAULT_STORE_PERSIST_KEY.toString()

      if (useGlobalStore && useGlobalStore.persist) {
        useGlobalStore.persist.setOptions({ name: currentKey })
        useGlobalStore.persist.rehydrate()
      }
    },
    getSessionPersistKey: () => {
      return currentKey
    },
  }
})()

export const useGlobalStore = create<GlobalStoreType>()(
  devtools(
    persist(
      (set, get, store) => ({
        appData: createApplicationDataSlice(
          ...sliceArgumentsCreator<ApplicationDataSlice>(
            'appData',
            set,
            get,
            store,
          ),
        ),
        globalModal: createGlobalModalSlice(
          ...sliceArgumentsCreator<GlobalModalSlice>(
            'globalModal',
            set,
            get,
            store,
          ),
        ),
        revisions: createRevisionSlice(
          ...sliceArgumentsCreator<RevisionSlice>('revisions', set, get, store),
        ),
        invoice: createInvoiceDetailsSlice(
          ...sliceArgumentsCreator<InvoiceDetailsSlice>(
            'invoice',
            set,
            get,
            store,
          ),
        ),
        products: createProductSlice(
          ...sliceArgumentsCreator<ProductsSlice>('products', set, get, store),
        ),
        user: createUserSlice(
          ...sliceArgumentsCreator<UserSlice>('user', set, get, store),
        ),
        errorManagement: createErrorManagementSlice(
          ...sliceArgumentsCreator<ErrorManagementSlice>(
            'errorManagement',
            set,
            get,
            store,
          ),
        ),
        vendors: createVendorsSlice(
          ...sliceArgumentsCreator<VendorsSlice>('vendors', set, get, store),
        ),
        mergeProducts: createMergeProductsSlice(
          ...sliceArgumentsCreator<MergeProductsSlice>(
            'mergeProducts',
            set,
            get,
            store,
          ),
        ),
      }),
      {
        name: getSessionPersistKey() || DEFAULT_STORE_PERSIST_KEY,
        partialize: (state) => ({
          products: { searchHistory: state.products.searchHistory },
          appData: { courierToken: state.appData.courierToken },
          vendors: {
            storeVendorProductDuplicateOverridesV2:
              state.vendors.storeVendorProductDuplicateOverridesV2,
          },
        }),
        merge: (persistedState, currentState) => {
          /**
           * since the application is using a namespaced approach to state,
           * we want to ensure that any rehydrated state is merged properly using each slice
           * instead of a basic merge of the current and persisted as that would result in undesireable overwrites of properties
           */
          const typedPersistedState =
            persistedState as unknown as Partial<GlobalStoreType>

          return Object.entries(currentState).reduce((output, currentValue) => {
            const [key, slice] = currentValue
            const typedSliceKey = key as GlobalStoreKeys

            // @ts-expect-error : The output and key are properly type but the inference does not function well here
            output[typedSliceKey] = { ...slice } as Partial<GlobalStoreType>

            if (
              typedPersistedState &&
              Object.prototype.hasOwnProperty.call(typedPersistedState, key)
            ) {
              // @ts-expect-error : The output and key are properly type but the inference does not function well here
              output[typedSliceKey] = {
                ...slice,
                ...typedPersistedState[typedSliceKey],
              } as Partial<GlobalStoreType>
            }

            return output
          }, {} as GlobalStoreType)
        },
        storage: {
          getItem: (name) => {
            const serializedState = localStorage.getItem(name)
            if (!serializedState) {
              return { state: {} }
            }

            const deserializedState = JSON.parse(serializedState).state
            const svpOverrides: StoreVendorProductDuplicateOverrides = {
              ids: new Map(
                deserializedState.vendors?.storeVendorProductDuplicateOverridesV2?.ids,
              ),
              itemCodes: new Map(
                deserializedState.vendors?.storeVendorProductDuplicateOverridesV2?.itemCodes,
              ),
              descriptions: new Map(
                deserializedState.vendors?.storeVendorProductDuplicateOverridesV2?.descriptions,
              ),
              storeProductIDs: new Map(
                deserializedState.vendors?.storeVendorProductDuplicateOverridesV2?.storeProductIDs,
              ),
            }
            return {
              state: {
                ...deserializedState,
                vendors: {
                  storeVendorProductDuplicateOverridesV2: svpOverrides,
                },
              },
            }
          },
          setItem: (name, newValue) => {
            const svpOverrideEntries: {
              [key in keyof StoreVendorProductDuplicateOverrides]: Array<unknown>
            } = {
              ids: Array.from(
                newValue.state.vendors.storeVendorProductDuplicateOverridesV2.ids.entries(),
              ),
              itemCodes: Array.from(
                newValue.state.vendors.storeVendorProductDuplicateOverridesV2.itemCodes.entries(),
              ),
              descriptions: Array.from(
                newValue.state.vendors.storeVendorProductDuplicateOverridesV2.descriptions.entries(),
              ),
              storeProductIDs: Array.from(
                newValue.state.vendors.storeVendorProductDuplicateOverridesV2.storeProductIDs.entries(),
              ),
            }
            localStorage.setItem(
              name,
              JSON.stringify({
                state: {
                  ...newValue.state,
                  vendors: {
                    storeVendorProductDuplicateOverridesV2: svpOverrideEntries,
                  },
                },
              }),
            )
          },
          removeItem: (name) => localStorage.removeItem(name),
        },
      },
    ),
  ),
)
