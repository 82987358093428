import React from 'react'
import styled from 'styled-components'

import { Inbox } from '@trycourier/react-inbox'

import { BellIcon } from 'lucide-react'
import { SidebarMenuButton } from '@vori/dashboard-components/shadcn/ui/sidebar'

import { FeatureFlags } from '@vori/dashboard-constants'
import { useFeatureConfig } from '@vori/dashboard-hooks/useFeatureConfig'

import { useCourierToken } from './useCourierToken'

const StyledSidebarMenuButton = styled(SidebarMenuButton)({
  cursor: 'pointer',

  '.inbox-bell': {
    width: '100%',

    svg: {
      opacity: 0,
    },
  },
})

export function CourierInbox(): JSX.Element | null {
  const canSeeCourierInbox = useFeatureConfig(
    FeatureFlags.WEB_NOTIFICATIONS_INBOX,
  )

  const courierToken = useCourierToken()

  const canRenderCourierInbox = React.useMemo(() => {
    return canSeeCourierInbox && courierToken != null
  }, [canSeeCourierInbox, courierToken])

  if (!canRenderCourierInbox) {
    return null
  }

  return (
    <StyledSidebarMenuButton
      asChild
      onClick={(event) => {
        ;(event.target as HTMLButtonElement)
          .querySelector<HTMLDivElement>('.inbox-bell')
          ?.click()
      }}
    >
      <span>
        <BellIcon />
        Notifications
        <Inbox />
      </span>
    </StyledSidebarMenuButton>
  )
}
