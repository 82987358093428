import React, { useCallback, useEffect } from 'react'

import { Search } from 'lucide-react'
import { SidebarMenuButton } from '@vori/dashboard-components/shadcn/ui/sidebar'
import ProductSearch from './ProductSearch'

import { useGlobalStore } from '@vori/dashboard-state/store'
import { getPlatformSpecificControlKeyString } from '@vori/dashboard-utils/getPlatformSpecificControlKeyString'

export function ProductSearchTrigger(): JSX.Element {
  const setAndDisplayContent = useGlobalStore(
    (state) => state.globalModal.setAndDisplayContent,
  )

  const isSearchModalOpen = useGlobalStore((state) => state.globalModal.isOpen)

  const handleTriggerProductSearchModal = useCallback(() => {
    if (isSearchModalOpen) {
      return
    }
    setAndDisplayContent(<ProductSearch />, true, true)
  }, [isSearchModalOpen, setAndDisplayContent])

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key.toLowerCase() === 'k') {
        event.preventDefault()
        handleTriggerProductSearchModal()
      }
    }

    window.addEventListener('keydown', handleKeyPress)

    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleTriggerProductSearchModal])

  return (
    <SidebarMenuButton onClick={handleTriggerProductSearchModal}>
      <Search />
      <span>Search</span>
      <span className={'ml-auto text-xs tracking-widest opacity-60'}>
        {getPlatformSpecificControlKeyString()}K
      </span>
    </SidebarMenuButton>
  )
}
