import React from 'react'
import styled from 'styled-components'

import {
  AppFrameProps as OriginalAppFrameProps,
  FlexNext as Flex,
  Loader,
  PropsToStyledProps,
  TextNext as Text,
} from '@vori/gourmet-components'

import {
  AppSidebar,
  DevUserGate,
  RetailUserGate,
  VendorUserGate,
} from '@vori/dashboard-components'

import { useCurrentUserState } from '@vori/dashboard-hooks/useCurrentUser'
import { useIntegrations } from '@vori/dashboard-integrations/useIntegrations'
import { usePageContainerProps } from '@vori/dashboard-hooks/usePageContainerProps'

import { AuthRoutes } from './auth'
import { useGlobalStore } from '../state-management/store'

import {
  Modal,
  ModalContent,
  ModalHeader,
  useModal,
} from 'gourmet/web-components/src/ModalNext'

import {
  SidebarProvider,
  SidebarInset,
} from '@vori/dashboard-components/shadcn/ui/sidebar'

import { AppHeader } from '@vori/dashboard-components/app/AppHeader'

const AppContent = styled(Flex)<
  PropsToStyledProps<{
    noPaddingBottom?: boolean
    noPaddingLeft?: boolean
    noPaddingRight?: boolean
    noPaddingTop?: boolean
  }>
>(({ $noPaddingBottom, $noPaddingLeft, $noPaddingRight, $noPaddingTop }) => ({
  backgroundColor: 'rgb(250, 250, 250)',
  borderTop: `1px solid #e5e7eb`,
  overflowY: 'scroll',
  padding: '1.25rem',
  ...($noPaddingBottom && { paddingBottom: 0 }),
  ...($noPaddingLeft && { paddingLeft: 0 }),
  ...($noPaddingRight && { paddingRight: 0 }),
  ...($noPaddingTop && { paddingTop: 0 }),
}))

const StyledSidebarInset = styled(SidebarInset)({
  height: '100vh',
})

const DevRoutes = React.lazy(() => import('./dev'))
const RetailRoutes = React.lazy(() => import('./retail'))
const VendorRoutes = React.lazy(() => import('./vendor'))

type AppFrameProps = {
  pageContainerProps: OriginalAppFrameProps['containerProps']
}

function AppFrame({
  children,
  pageContainerProps,
}: React.PropsWithChildren<AppFrameProps>): JSX.Element {
  return (
    <SidebarProvider>
      <AppSidebar />
      <StyledSidebarInset>
        <AppHeader />
        <AppContent
          $noPaddingBottom={pageContainerProps?.noPaddingBottom}
          $noPaddingLeft={pageContainerProps?.noPaddingLeft}
          $noPaddingRight={pageContainerProps?.noPaddingRight}
          $noPaddingTop={pageContainerProps?.noPaddingTop}
          alignItems={pageContainerProps?.alignItems || 'flex-start'}
          center={pageContainerProps?.center}
          flex={1}
          justifyContent={pageContainerProps?.justifyContent || 'flex-start'}
        >
          {children}
        </AppContent>
      </StyledSidebarInset>
    </SidebarProvider>
  )
}

export function MainRoutes(): JSX.Element {
  useIntegrations()

  const { authenticationError, user } = useCurrentUserState()
  const isOpen = useGlobalStore((state) => state.globalModal.isOpen)
  const modalTitle = useGlobalStore((state) => state.globalModal.modalTitle)
  const pageContainerProps = usePageContainerProps()
  const showModal = useGlobalStore((state) => state.globalModal.toggleDisplay)

  const showModalHeader = useGlobalStore(
    (state) => state.globalModal.showModalHeader,
  )

  const { ...topLevelModal } = useModal({
    asModal: true,
    closeOnClickOutside: true,
    open: isOpen,
    onClose: () => {
      showModal(false)
    },
  })

  const modalDisplayContent = useGlobalStore(
    (state) => state.globalModal.displayContent,
  )

  const fullBleedContent = useGlobalStore(
    (state) => state.globalModal.fullBleedContent,
  )

  const disableBackgroundBlur = useGlobalStore(
    (state) => state.globalModal.disableBackgroundBlur,
  )

  const handleClickUpOutside = (event: React.MouseEvent<HTMLDialogElement>) => {
    const trueTarget = event.target as HTMLElement
    const containerClicked = Object.prototype.hasOwnProperty.call(
      trueTarget.attributes,
      'data-gourmet-modal-container',
    )
    if (containerClicked) {
      showModal(false)
    }
  }

  if (!user.state.isLoggedIn && user.state.isAuthenticating) {
    return (
      <AppFrame pageContainerProps={pageContainerProps}>
        <Flex center direction="column" flex={1} fullWidth rowGap="space.025">
          <Loader size="large" />
          <Text variant="secondary">Authenticating session...</Text>
        </Flex>
      </AppFrame>
    )
  }

  if (!user.state.isLoggedIn && authenticationError) {
    return (
      <AppFrame pageContainerProps={pageContainerProps}>
        <Flex center flex={1} fullWidth>
          <Text variant="negative">
            There was an error while authenticating your account, please try
            again.
          </Text>
        </Flex>
      </AppFrame>
    )
  }

  return (
    <AppFrame pageContainerProps={pageContainerProps}>
      <React.Suspense
        fallback={
          <Flex center direction="column" flex={1} fullWidth rowGap="space.025">
            <Loader size="large" />
            <Text variant="secondary">Loading view...</Text>
          </Flex>
        }
      >
        <AuthRoutes />
        <RetailUserGate>
          <RetailRoutes
            key={`selected-store-${user.metadata.selectedStoreID}`}
          />
        </RetailUserGate>
        <VendorUserGate>
          <VendorRoutes />
        </VendorUserGate>
        <DevUserGate>
          <DevRoutes />
        </DevUserGate>
      </React.Suspense>

      <Modal
        disableBackgroundBlur={disableBackgroundBlur}
        {...topLevelModal}
        onClick={handleClickUpOutside}
      >
        <ModalContent {...(fullBleedContent && { style: { padding: 0 } })}>
          {showModalHeader === true && <ModalHeader title={modalTitle || ''} />}
          {modalDisplayContent}
        </ModalContent>
      </Modal>
    </AppFrame>
  )
}
