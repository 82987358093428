import React from 'react'
import { ArrowRightLeftIcon, ChevronsUpDownIcon, ZapIcon } from 'lucide-react'

import {
  SidebarGroup,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from '@vori/dashboard-components/shadcn/ui/sidebar'

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@vori/dashboard-components/shadcn/ui/dropdown-menu'

import { AppSidebarUser } from '@vori/dashboard-components/AppSidebarUser'
import { ENV_NEW_APP_URL } from '@vori/dashboard-env'
import { toTimeZoneAbbreviation } from '@vori/dashboard-utils/format'
import { useCurrentUserState } from '@vori/dashboard-hooks/useCurrentUser'

export function AppSidebarHeader(): JSX.Element | null {
  const { user } = useCurrentUserState()
  const { isMobile } = useSidebar()

  return (
    <SidebarGroup>
      <SidebarMenu>
        <SidebarMenuItem>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <SidebarMenuButton
                size="lg"
                className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
              >
                <AppSidebarUser
                  displayName={user.data.companyName || 'N/A'}
                  icon={ZapIcon}
                  subTitle={`Timezone: ${toTimeZoneAbbreviation(user.metadata.timeZone)}`}
                />
                <ChevronsUpDownIcon className="ml-auto" />
              </SidebarMenuButton>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
              align="start"
              side={isMobile ? 'bottom' : 'right'}
              sideOffset={4}
            >
              <DropdownMenuItem
                onClick={() => {
                  window.open(ENV_NEW_APP_URL, '_blank')
                }}
                className="gap-2 p-2 text-accent-foreground"
              >
                <div className="flex size-6 items-center justify-center rounded-sm">
                  <ArrowRightLeftIcon className="size-4 shrink-0" />
                </div>
                Switch to the New App
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </SidebarMenuItem>
      </SidebarMenu>
    </SidebarGroup>
  )
}
