import { StoreDepartment } from '@vori/dashboard-hooks/entities'
import { StoreProduct } from '@vori/dashboard-types'

export type StoreProductFormValues = Pick<
  StoreProduct,
  | 'active'
  | 'brand_string'
  | 'case_upc'
  | 'category'
  | 'department_id'
  | 'ebt_enabled'
  | 'ecommerce_enabled'
  | 'id'
  | 'min_customer_age'
  | 'name'
  | 'pack_upc'
  | 'primary_case_size'
  | 'primary_case_upc'
  | 'primary_description'
  | 'primary_pack_upc'
  | 'primary_unit_upc'
  | 'primary_unit_volume'
  | 'prompt_for_quantity'
  | 'sold_by_weight'
  | 'store_id'
  | 'unit_upc'
  | 'unit_volume_string'
  | 'upc'
  | 'barcode'
  | 'variable_sale_price'
  | 'wic_enabled'
  | 'print_physical_tag'
  | 'esl_enabled'
  | 'pack_size'
  | 'last_pushed_to_pos_at'
  | 'updated_at'
  | 'manual_item'
  | 'retail_price_uom_id'
  | 'retail_price_uom_name'
  | 'is_tippable'
  | 'inventory_total'
  | 'country_of_origin'
> & {
  price?: number | string
  primary_case_cost: number | string
  primary_unit_cost: number | string
  requiredStores: string[]
  storeDepartmentIDs: string[]
  item_modifier_ids: string[]
  variable_weight_ids: string[]
  storeDepartments?: Array<StoreDepartment>
  target_margin?: number | string
  tax_rate_ids: string[] | null
  additional_barcodes: string[] | null
  unitOfMeasure?: string | null
  unitOfMeasureAmount?: string | null
  vendors: VendorCosts
  defaultVendorCosts: VendorCosts['costs']
  readOnlyCosts?: boolean
  initial_inventory_total?: number | null
}

export type BulkFields = Pick<
  StoreProductFormValues,
  | 'brand_string'
  | 'pack_size'
  | 'department_id'
  | 'ebt_enabled'
  | 'ecommerce_enabled'
  | 'min_customer_age'
  | 'price'
  | 'prompt_for_quantity'
  | 'sold_by_weight'
  | 'target_margin'
  | 'tax_rate_ids'
  | 'unit_volume_string'
  | 'unit_volume_string'
  | 'variable_sale_price'
  | 'wic_enabled'
  | 'print_physical_tag'
  | 'retail_price_uom_id'
  | 'is_tippable'
> & {
  price?: number | string
  target_margin?: number | string
  tax_rate_ids: string[] | null
  unitOfMeasure?: string | null
  unitOfMeasureAmount?: string | null
}

export type StoreVendorProductFormValues = Pick<
  StoreProduct,
  | 'primary_case_cost'
  | 'primary_case_size'
  | 'primary_case_upc'
  | 'primary_description'
  | 'primary_pack_upc'
  | 'primary_unit_cost'
  | 'primary_unit_upc'
  | 'primary_unit_volume'
>

export type FormType = 'create' | 'update' | 'bulk-update'

export enum StoreProductPointOfSaleStatus {
  READY_FOR_SALE = 'Ready for sale',
  DATA_INVALID = 'Invalid data',
  OUTDATED = 'Outdated data',
  SYNC_DISABLED = 'Sync disabled',
}

export type VendorCosts = {
  costConfiguration: 'LATEST_COST' | 'SELECTED_BY_USER'
  defaultCostConfiguration: 'LATEST_COST' | 'SELECTED_BY_USER'
  activeVendorIndex?: number
  editingVendorIndex?: number
  storeVendorProductsToUnlink?: string[]
  costs: Array<{
    vendor_product_cost_id: string | null
    cost: number
    store_vendor_product_id: string
    start_time: string // Date string
    item_code: string
    source_type: string
    uom_name: string
    store_vendor_id: string
    store_vendor_name: string
    case_size: number | null
    has_pending_margin_change?: boolean
  }>
}

/**
 * A result from the API providing the suggested retail price for a product.
 */
export type SuggestedRetailResult = {
  currentMargin: number | null // percentage, e.g. 5 = 5%
  currentProfit: number | null // fractional, e.g. 500 = $5.00
  currentRetail: number // fractional, e.g. 500 = $5.00
  targetMargin: number // percentage, e.g. 5 = 5%
  targetProfit: number | null // fractional, e.g. 500 = $5.00
  suggestedRetail: number | null // fractional, e.g. 500 = $5.00
  unitCost: number | null // fractional, e.g. 500 = $5.00
}

export type PromotionResult = {
  promotionalData: {
    storeProductID: string
    promotionID: string
    promotionEndDate: string
    displayText: string
    promotionType: string
  }
}

export enum PosUnitOfMeasure {
  Each = 'EACH',
  Ounce = 'OZ',
  Pound = 'LB',
}

export enum ProductDetailTab {
  DETAILS = 'details',
  SALES = 'sales',
}
