/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */

/**
 * Determines whether the bookmark will exist at the store or banner level
 */
export enum SigmaContextForBookmarks {
  Banner = 'Banner',
  Store = 'Store',
}
