import { useMemo } from 'react'
import { matchPath, useLocation } from 'react-router'

import { routes as authRoutes } from '@vori/dashboard-routes/auth'
import { routes as devRoutes } from '@vori/dashboard-routes/dev'
import { routes as retailRoutes } from '@vori/dashboard-routes/retail'
import { routes as vendorRoutes } from '@vori/dashboard-routes/vendor'
import { sharedRoutes } from '@vori/dashboard-shared-routes'
import { AppFrameProps } from '@vori/gourmet-components'
import { LoyaltyTab } from '../../pages/retail/pages/loyalty/types/types'

function usePageContainerProps(): AppFrameProps['containerProps'] {
  const location = useLocation()

  const props = useMemo<AppFrameProps['containerProps']>(() => {
    if (
      [
        ...Object.values(sharedRoutes().payments),
        authRoutes.login.root,
        authRoutes.logout.root,
        authRoutes.passwordRecovery.root,
        vendorRoutes.promotions.create,
        vendorRoutes.promotions.duplicate,
        vendorRoutes.promotions.edit,
      ].some((routeUtil) => routeUtil.match()?.isExact)
    ) {
      return {
        center: true,
      }
    }

    if (
      retailRoutes.home.root.match()?.isExact ||
      matchPath(location.pathname, {
        path: retailRoutes.loyalty.tab.generate({
          loyaltyTab: LoyaltyTab.INSIGHTS,
        }),
      })?.isExact
    ) {
      return {
        variant: 'next',
        alignItems: 'stretch',
      }
    }

    if (
      [
        ...Object.values(retailRoutes.campaigns),
        ...Object.values(vendorRoutes.promotions),
        devRoutes.gql.root,
        devRoutes.metrics.root,
        devRoutes.retool.root,
        retailRoutes.giftCards.detail,
        retailRoutes.promotions.root,
        retailRoutes.promotions.status,
        retailRoutes.tills.detailHistory,
        retailRoutes.tills.detailOverview,
        retailRoutes.tills.review,
        retailRoutes.tills.settings,
        retailRoutes.transactions.detail,
        retailRoutes.transactions.lineItemDetail,
        retailRoutes.users.edit,
        retailRoutes.users.settings,
        vendorRoutes.home.root,
        vendorRoutes.profile.root,
      ].some((routeUtil) => routeUtil.match()?.isExact)
    ) {
      return {
        alignItems: 'stretch',
      }
    }

    if (
      [retailRoutes.promotions.import, retailRoutes.promotions.notFound].some(
        (routeUtil) => routeUtil.match()?.isExact,
      )
    ) {
      return {
        alignItems: 'stretch',
        justifyContent: 'center',
      }
    }

    if (
      [
        retailRoutes.promotions.create,
        retailRoutes.promotions.detail,
        retailRoutes.promotions.update,
        retailRoutes.promotions.view,
      ].some((routeUtil) => routeUtil.match()?.isExact)
    ) {
      return {
        alignItems: 'stretch',
        justifyContent: 'center',
        noPaddingBottom: true,
        noPaddingTop: true,
      }
    }

    if (
      [retailRoutes.promotions.review].some(
        (routeUtil) => routeUtil.match()?.isExact,
      )
    ) {
      return {
        alignItems: 'stretch',
        justifyContent: 'center',
        noPaddingBottom: true,
        noPaddingLeft: true,
        noPaddingRight: true,
        noPaddingTop: true,
      }
    }

    const { tab: _tab, ...reportingRoutes } = retailRoutes.reporting
    if (Object.values(reportingRoutes).some((routeUtil) => routeUtil.match())) {
      return {
        variant: 'next',
        alignItems: 'stretch',
        noPaddingBottom: true,
      }
    }

    if (
      [
        ...Object.values(devRoutes.invitations),
        ...Object.values(devRoutes.products),
        ...Object.values(devRoutes.vendors),
        ...Object.values(retailRoutes.catalogs),
        ...Object.values(retailRoutes.departments),
        ...Object.values(retailRoutes.integrations),
        ...Object.values(retailRoutes.invoices),
        ...Object.values(retailRoutes.loyalty),
        ...Object.values(retailRoutes.products),
        ...Object.values(retailRoutes.search),
        ...Object.values(retailRoutes.stores),
        ...Object.values(retailRoutes.taxes),
        ...Object.values(retailRoutes.unauthorizedLineItems),
        ...Object.values(retailRoutes.vendors),
        ...Object.values(vendorRoutes.crm),
        ...Object.values(vendorRoutes.products),
        devRoutes.banners.create,
        devRoutes.banners.edit,
        devRoutes.banners.root,
        devRoutes.internalAutoInvoice.root,
        devRoutes.stores.buyers,
        devRoutes.stores.createBuyer,
        devRoutes.stores.createDepartmentBuyer,
        devRoutes.stores.createDepartmentVendor,
        devRoutes.stores.departmentBuyers,
        devRoutes.stores.departmentOrderDetail,
        devRoutes.stores.departmentOrders,
        devRoutes.stores.departments,
        devRoutes.stores.departmentVendors,
        devRoutes.stores.editDepartmentVendor,
        devRoutes.stores.root,
        devRoutes.users.root,
        retailRoutes.giftCards.create,
        retailRoutes.giftCards.root,
        retailRoutes.loyalty.root,
        retailRoutes.members.list,
        retailRoutes.members.root,
        retailRoutes.tills.root,
        retailRoutes.transactions.list,
        retailRoutes.transactions.root,
        retailRoutes.transactions.root,
        retailRoutes.users.create,
        retailRoutes.users.root,
      ].some((routeUtil) => routeUtil.match()?.isExact)
    ) {
      return {
        alignItems: 'stretch',
        noPaddingBottom: true,
      }
    }

    if (
      [...Object.values(vendorRoutes.orders)].some(
        (routeUtil) => routeUtil.match()?.isExact,
      )
    ) {
      return {
        alignItems: 'stretch',
        noPaddingBottom: true,
        noPaddingTop: true,
      }
    }

    if (
      [...Object.values(retailRoutes.invoices)].some(
        (routeUtil) => routeUtil.match()?.isExact,
      )
    ) {
      return {
        alignItems: 'stretch',
        noPaddingBottom: true,
        variant: 'next',
      }
    }

    return {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return props
}

export { usePageContainerProps }
