import clsx from 'clsx'
import React from 'react'

export type Props = {
  displayName: string
  icon: React.ElementType
  subTitle?: string | Array<string> | null
  value?: string // just used for testing
}

export function AppSidebarUser({
  displayName,
  icon,
  subTitle,
  value,
}: Props): JSX.Element {
  return (
    <>
      <div
        className="flex aspect-square size-8 items-center justify-center rounded-lg bg-sidebar-primary text-sidebar-primary-foreground"
        data-testid="app-sidebar-user"
        data-value={value}
      >
        {React.createElement(icon, { className: 'size-4' })}
      </div>
      <div className="grid flex-1 text-left text-sm leading-tight">
        <span className="truncate font-semibold">{displayName}</span>
        {subTitle ? (
          Array.isArray(subTitle) ? (
            <span className="flex gap-1">
              {subTitle.map((subTitlePart, index) => (
                <span
                  key={subTitlePart}
                  className={clsx(
                    index !== subTitle.length && 'truncate',
                    index !== subTitle.length && 'max-w-[75%]',
                    'text-xs',
                  )}
                >
                  {subTitlePart}
                </span>
              ))}
            </span>
          ) : (
            <span className="truncate text-xs">{subTitle}</span>
          )
        ) : null}
      </div>
    </>
  )
}
