import { DataOnlyResponse } from '@vori/dashboard-api/get'
import { patch } from '@vori/dashboard-api/patch'
import { put } from '@vori/dashboard-api/put'
import { ChangesetRowStatus, PatchType } from '@vori/dashboard-api/types'
import { generateJSONPatchEnvelope } from '@vori/dashboard-api/utils'

import {
  CreateStoreProductFields,
  EditableStoreProductFields,
  StoreProductPriceInputMethod,
  StoreProductPriceSource,
  StoreProductPriceSourcePlatform,
} from '@vori/gql-dashboard'

import { KeyedMutator } from 'swr'

import { UnitOfMeasure } from '@vori/dashboard-types'
import { toReadableEnum } from '@vori/dashboard-utils'
import { gtin } from 'cdigit'

import { StoreEntity } from '@vori/dashboard-hooks/entities'
import { StoreProduct } from '@vori/dashboard-types'
import { ColorVariant } from '@vori/gourmet-components'
import Decimal from 'decimal.js'
import { applyRoundingRule } from '../invoice-next/utils/currency'

import {
  BulkFields,
  StoreProductFormValues,
  StoreProductPointOfSaleStatus,
  VendorCosts,
} from './types'

export const getEANCountryPrefix = (barcode: string): string | null => {
  const prefixNum = parseInt(barcode.slice(0, 3))

  switch (true) {
    case prefixNum >= 0 && prefixNum <= 19:
    case prefixNum >= 30 && prefixNum <= 39:
    case prefixNum >= 60 && prefixNum <= 139:
    case prefixNum >= 20 && prefixNum <= 29:
    case prefixNum >= 40 && prefixNum <= 49:
    case prefixNum >= 200 && prefixNum <= 299:
      // return 'Restricted distribution';
      return null
    case prefixNum >= 50 && prefixNum <= 59:
      // 'Coupons';
      return null
    case prefixNum >= 300 && prefixNum <= 379:
      return 'GS1 France'
    case prefixNum === 380:
      return 'GS1 Bulgaria'
    case prefixNum === 383:
      return 'GS1 Slovenija'
    case prefixNum === 385:
      return 'GS1 Croatia'
    case prefixNum === 387:
      return 'GS1 BIH (Bosnia-Herzegovina)'
    case prefixNum === 389:
      return 'GS1 Montenegro'
    case prefixNum >= 400 && prefixNum <= 440:
      return 'GS1 Germany'
    case (prefixNum >= 450 && prefixNum <= 459) ||
      (prefixNum >= 490 && prefixNum <= 499):
      return 'GS1 Japan'
    case prefixNum >= 460 && prefixNum <= 469:
      return 'GS1 Russia'
    case prefixNum === 470:
      return 'GS1 Kyrgyzstan'
    case prefixNum === 471:
      return 'GS1 Taiwan'
    case prefixNum === 474:
      return 'GS1 Estonia'
    case prefixNum === 475:
      return 'GS1 Latvia'
    case prefixNum === 476:
      return 'GS1 Azerbaijan'
    case prefixNum === 477:
      return 'GS1 Lithuania'
    case prefixNum === 478:
      return 'GS1 Uzbekistan'
    case prefixNum === 479:
      return 'GS1 Sri Lanka'
    case prefixNum === 480:
      return 'GS1 Philippines'
    case prefixNum === 481:
      return 'GS1 Belarus'
    case prefixNum === 482:
      return 'GS1 Ukraine'
    case prefixNum === 484:
      return 'GS1 Moldova'
    case prefixNum === 485:
      return 'GS1 Armenia'
    case prefixNum === 486:
      return 'GS1 Georgia'
    case prefixNum === 487:
      return 'GS1 Kazakstan'
    case prefixNum === 488:
      return 'GS1 Tajikistan'
    case prefixNum === 489:
      return 'GS1 Hong Kong'
    case prefixNum >= 500 && prefixNum <= 509:
      return 'GS1 UK'
    case prefixNum >= 520 && prefixNum <= 521:
      return 'GS1 Association Greece'
    case prefixNum === 528:
      return 'GS1 Lebanon'
    case prefixNum === 529:
      return 'GS1 Cyprus'
    case prefixNum === 530:
      return 'GS1 Albania'
    case prefixNum === 531:
      return 'GS1 MAC (FYR Macedonia)'
    case prefixNum === 535:
      return 'GS1 Malta'
    case prefixNum === 539:
      return 'GS1 Ireland'
    case prefixNum >= 540 && prefixNum <= 549:
      return 'GS1 Belgium & Luxembourg'
    case prefixNum === 560:
      return 'GS1 Portugal'
    case prefixNum === 569:
      return 'GS1 Iceland'
    case prefixNum >= 570 && prefixNum <= 579:
      return 'GS1 Denmark'
    case prefixNum === 590:
      return 'GS1 Poland'
    case prefixNum === 594:
      return 'GS1 Romania'
    case prefixNum === 599:
      return 'GS1 Hungary'
    case prefixNum >= 600 && prefixNum <= 601:
      return 'GS1 South Africa'
    case prefixNum === 603:
      return 'GS1 Ghana'
    case prefixNum === 604:
      return 'GS1 Senegal'
    case prefixNum === 608:
      return 'GS1 Bahrain'
    case prefixNum === 609:
      return 'GS1 Mauritius'
    case prefixNum === 611:
      return 'GS1 Morocco'
    case prefixNum === 613:
      return 'GS1 Algeria'
    case prefixNum === 615:
      return 'GS1 Nigeria'
    case prefixNum === 616:
      return 'GS1 Kenya'
    case prefixNum === 618:
      return 'GS1 Ivory Coast'
    case prefixNum === 619:
      return 'GS1 Tunisia'
    case prefixNum === 620:
      return 'GS1 Tanzania'
    case prefixNum === 621:
      return 'GS1 Syria'
    case prefixNum === 622:
      return 'GS1 Egypt'
    case prefixNum === 623:
      return 'GS1 Brunei'
    case prefixNum === 624:
      return 'GS1 Libya'
    case prefixNum === 625:
      return 'GS1 Jordan'
    case prefixNum === 626:
      return 'GS1 Iran'
    case prefixNum === 627:
      return 'GS1 Kuwait'
    case prefixNum === 628:
      return 'GS1 Saudi Arabia'
    case prefixNum === 629:
      return 'GS1 Emirates'
    case prefixNum >= 640 && prefixNum <= 649:
      return 'GS1 Finland'
    case prefixNum >= 690 && prefixNum <= 699:
      return 'GS1 China'
    case prefixNum >= 700 && prefixNum <= 709:
      return 'GS1 Norway'
    case prefixNum === 729:
      return 'GS1 Israel'
    case prefixNum >= 730 && prefixNum <= 739:
      return 'GS1 Sweden'
    case prefixNum === 740:
      return 'GS1 Guatemala'
    case prefixNum === 741:
      return 'GS1 El Salvador'
    case prefixNum === 742:
      return 'GS1 Honduras'
    case prefixNum === 743:
      return 'GS1 Nicaragua'
    case prefixNum === 744:
      return 'GS1 Costa Rica'
    case prefixNum === 745:
      return 'GS1 Panama'
    case prefixNum === 746:
      return 'GS1 Republica Dominicana'
    case prefixNum === 750:
      return 'GS1 Mexico'
    case prefixNum >= 754 && prefixNum <= 755:
      return 'GS1 Canada'
    case prefixNum === 759:
      return 'GS1 Venezuela'
    case prefixNum >= 760 && prefixNum <= 769:
      return 'GS1 Schweiz, Suisse, Svizzera'
    case prefixNum >= 770 && prefixNum <= 771:
      return 'GS1 Colombia'
    case prefixNum === 773:
      return 'GS1 Uruguay'
    case prefixNum === 775:
      return 'GS1 Peru'
    case prefixNum === 777:
      return 'GS1 Bolivia'
    case prefixNum >= 778 && prefixNum <= 779:
      return 'GS1 Argentina'
    case prefixNum === 780:
      return 'GS1 Chile'
    case prefixNum === 784:
      return 'GS1 Paraguay'
    case prefixNum === 786:
      return 'GS1 Ecuador'
    case prefixNum >= 789 && prefixNum <= 790:
      return 'GS1 Brasil'
    case prefixNum >= 800 && prefixNum <= 839:
      return 'GS1 Italy'
    case prefixNum >= 840 && prefixNum <= 849:
      return 'GS1 Spain'
    case prefixNum === 850:
      return 'GS1 Cuba'
    case prefixNum === 858:
      return 'GS1 Slovakia'
    case prefixNum === 859:
      return 'GS1 Czech'
    case prefixNum === 860:
      return 'GS1 Serbia'
    case prefixNum === 865:
      return 'GS1 Mongolia'
    case prefixNum === 867:
      return 'GS1 North Korea'
    case prefixNum >= 868 && prefixNum <= 869:
      return 'GS1 Turkey'
    case prefixNum >= 870 && prefixNum <= 879:
      return 'GS1 Netherlands'
    case prefixNum === 880:
      return 'GS1 South Korea'
    case prefixNum === 884:
      return 'GS1 Cambodia'
    case prefixNum === 885:
      return 'GS1 Thailand'
    case prefixNum === 888:
      return 'GS1 Singapore'
    case prefixNum === 890:
      return 'GS1 India'
    case prefixNum === 893:
      return 'GS1 Vietnam'
    case prefixNum === 896:
      return 'GS1 Pakistan'
    case prefixNum === 899:
      return 'GS1 Indonesia'
    case prefixNum >= 900 && prefixNum <= 919:
      return 'GS1 Austria'
    case prefixNum >= 930 && prefixNum <= 939:
      return 'GS1 Australia'
    case prefixNum >= 940 && prefixNum <= 949:
      return 'GS1 New Zealand'
    case prefixNum === 950:
      return 'GS1 Global Office'
    case prefixNum === 951:
      return 'GS1 Global Office (EPCglobal)'
    case prefixNum >= 955 && prefixNum <= 956:
      return 'GS1 Malaysia'
    case prefixNum === 958:
      return 'GS1 Macau'
    case prefixNum >= 960 && prefixNum <= 969:
      return 'Global Office (GTIN-8s)'
    case prefixNum === 977:
      // 'Serial publications (ISSN)';
      return null
    case prefixNum >= 978 && prefixNum <= 979:
      // 'Bookland https://en.wikipedia.org/wiki/Bookland';
      return null
    case prefixNum === 980:
      // 'Refund receipts';
      return null
    case prefixNum >= 981 && prefixNum <= 984:
      // 'GS1 coupon identification for common currency areas';
      return null
    case prefixNum >= 990 && prefixNum <= 999:
      // 'GS1 coupon identification';
      return null
    default:
      return null
  }
}

export enum UOM {
  EACH = 'EACH',
  CASE = 'CASE',
  POUND = 'POUND',
  LB = 'LB',
}

const UNITS = [
  'PLASTIC GALLONS?',
  'Kilograms?',
  'VCAPS?',
  'CAPS?',
  'QUARTS?',
  'DOZEN?',
  'PACKETS?',
  'ROLLS?',
  'KG',
  'ML',
  'PINTS?',
  'SHEETS?',
  'GRAMS?',
  'GALLONS?',
  'FLOZ',
  'GAL',
  'OUNCES?',
  'POUNDS?',
  'LBS?',
  'BAGS?',
  'FL\\.?\\s+?OZ',
  'PCS?',
  'O\\s?Z',
  'CT',
  'SF',
  '\\d-?PLY',
  'L',
  'SQ\\s+?FT',
  'DZ',
  'YD?S',
  'FZ',
  'GRS?',
  'SHTS?',
  'LT',
  'QT',
  'CM',
  'PT',
  'TB',
  'G',
  'P$',
  'D',
  'C',
  'Z',
  '#',
  // truncated or misread units
  '0Z',
  'M',
]

const parseUOM = (
  productName: string,
): {
  amount?: string
  unit?: string
  packAmount?: string
  case?: string
  caseName?: string
  pack?: string
  uom?: string
  name: string
} => {
  const decimal = '(\\.\\d+|\\.|\\,\\d+|\\,)?'

  const regex = `((?<case>(\\d+${decimal}))\\s*\\/(?=\\S*\\/))?\\s*((?<packAmount>(\\d+${decimal}(pk?|pack)?))\\s*\\/)?\\s*(?<amount>\\.?\\d+${decimal})\\s*(?<unit>(?:${UNITS.join(
    '|',
  )})(?=\\s|x|\\.|$))\\.?`

  const amountR = new RegExp(regex, 'gi')
  const groups = amountR.exec(productName)?.groups
  let packString = productName
  if (groups?.amount) packString = packString.replace(groups.amount, '')
  if (groups?.unit) packString = packString.replace(groups.unit, '')

  const packs = /(?<packAmount>\d+(\.\d+|\.)?)-?\s*(?<pack>PK|PACK)/gi.exec(
    packString,
  )?.groups

  const caseForward =
    /\b(?<case>x|ctn|ct|cs|cases?)\s*(?<caseAmount>\d+(\.\d+|\.)?)/gi.exec(
      packString,
    )?.groups
  const caseBackward =
    /(?<caseAmount>\d+(\.\d+|\.)?)\s*(?<case>x|ctn|ct|cs|cases?)\b/gi.exec(
      packString,
    )?.groups

  const uomType = /\s?(?<type>(CASE|CS|CTN|PIECE|PC|EACH|EA|LB|POUND))/gi.exec(
    productName,
  )?.groups

  const caseType =
    uomType?.type && /(CASE|CS|CTN)/gi.test(uomType?.type?.toLowerCase())
      ? uomType?.type
      : undefined

  let uom = UOM.EACH

  if (
    (uomType?.type &&
      /(LBS?|POUNDS?|#)/gi.test(uomType?.type?.toLowerCase())) ||
    (groups?.unit?.trim() && /(LBS?|POUNDS?|#)/gi.test(groups?.unit?.trim()))
  ) {
    uom = UOM.POUND
  }

  const caseValue =
    groups?.case?.trim() ||
    caseForward?.caseAmount?.trim() ||
    caseBackward?.caseAmount?.trim()

  const caseNameValue =
    caseForward?.case?.trim() || caseBackward?.case?.trim() || caseType?.trim()
  if (
    (uomType?.type && /(CASE|CS|CTN)/gi.test(uomType?.type?.toLowerCase())) ||
    caseValue ||
    caseNameValue
  ) {
    uom = UOM.CASE
  }

  return {
    name: productName,
    packAmount: (
      groups?.packAmount?.trim() || packs?.packAmount?.trim()
    )?.replace(/(pk?|pack)/gi, ''),
    pack: groups?.pack?.trim() || packs?.pack?.trim(),
    case: caseValue,
    caseName: caseNameValue,
    amount: groups?.amount?.replace(/(,)/, '.')?.trim(),
    uom,
    unit: groups?.unit?.trim(),
  }
}

function calculateCheckDigit(barcode: string): {
  checkDigit: string
  result: string
} {
  let total = 0
  for (let i = barcode.length - 1, j = 0; i >= 0; i--, j++) {
    const digit = parseInt(barcode[i], 10)
    total += j % 2 === 0 ? digit * 3 : digit
  }
  const checkDigit = String(Math.ceil(total / 10) * 10 - total)
  return { checkDigit, result: barcode + checkDigit }
}
function expandUPCE(barcode: string) {
  const digits = barcode.split('')
  const numberSystem = digits.length > 7 ? digits.shift() : ''
  const checkDigit = digits.length > 6 ? digits.pop() : ''
  const lastDigit = `${digits?.pop()}`
  let expanded
  switch (lastDigit) {
    case '0':
    case '1':
    case '2':
      expanded =
        digits.slice(0, 2).join('') +
        lastDigit +
        '0000' +
        digits.slice(2, 5).join('')
      break
    case '3':
    case '4':
      expanded =
        digits.slice(0, Number(lastDigit)).join('') +
        '00000' +
        digits.slice(Number(lastDigit), 5).join('')
      break
    case '5':
    case '6':
    case '7':
    case '8':
    case '9':
      expanded = digits.join('') + '0000' + lastDigit
      break
  }
  return `${numberSystem}${expanded}${checkDigit}`
}

const getVendorInitials = (name: string): string | null => {
  if (!name) return null
  const stopwords = [
    'of',
    'and',
    'the',
    'inc',
    'co',
    'llc',
    'corp',
    'inc.',
    'co.',
    'llc.',
  ] // Add more stopwords as needed
  const words = name.split(' ')

  if (words.length > 1) {
    const initials = words
      .filter((word) => !stopwords.includes(word.toLowerCase()))
      .map((word) => word.charAt(0))
      .join('')
      .slice(0, 2)
      .toUpperCase()
    return initials
  } else {
    // Check if the name is a single word but contains mixed casing
    if (name.match(/[A-Z][a-z]+|[a-z]+[A-Z]/)) {
      // Always include the first letter of the name, then find the next uppercase letter if present
      const firstLetter = name.charAt(0).toUpperCase() // Ensure the first letter is uppercase
      const secondLetter = name.slice(1).match(/[A-Z]/)
        ? name.slice(1).match(/[A-Z]/)?.[0]
        : ''
      return (firstLetter + secondLetter).slice(0, 2).toUpperCase()
    } else {
      // For single-word names with all uppercase letters
      const casingMatches = name.match(/[A-Z]+/g)
      if (casingMatches) {
        return casingMatches.join('').slice(0, 2).toUpperCase()
      } else {
        return null // No initials found
      }
    }
  }
}

function isValidBarcode(value: string | undefined): boolean | string {
  if (!value) return false
  const message = `Check digit invalid, did you mean ${
    calculateCheckDigit(value.slice(0, -1)).result
  }?`
  switch (value.length) {
    case 8: {
      if (calculateCheckDigit(value.slice(0, -1)).result === value) return false
      // check EAN-8
      if (
        calculateCheckDigit(value.slice(0, -1)).result === value &&
        getEANCountryPrefix(value.slice(-8)) !== null
      )
        return false

      // check UPC-E
      if (
        calculateCheckDigit(expandUPCE(value).slice(0, -1)).result ===
          expandUPCE(value) &&
        calculateCheckDigit(expandUPCE(value).slice(0, -1)).checkDigit ===
          value[7]
      ) {
        return false
      }

      return `expanded: ${expandUPCE(value)} or invalid EAN-8 - ${message}`
    }
    case 9:
    case 10:
      return 'Invalid barcode'
    case 11:
      return `UPC missing check digit (${
        calculateCheckDigit(value).checkDigit
      })`
    case 12:
      return calculateCheckDigit(value.slice(0, -1)).result !== value
        ? message
        : false
    case 13:
      if (value.startsWith('0') && !value.startsWith('02'))
        return 'EAN-13 cannot start with 0'
      return calculateCheckDigit(value.slice(0, -1)).result !== value &&
        getEANCountryPrefix(value.slice(-13)) !== null
        ? message
        : false
    default:
      return false
  }
}

function isValidUPC(value?: string): boolean {
  if (value) {
    return /^\d{12}$/.test(value) && gtin.validate(value)
  }
  return true
}

function archiveProduct(product: StoreProduct): Promise<unknown> {
  return patch<{ deleted_at: string }>(`/api/products/${product.id}`, [
    {
      ...generateJSONPatchEnvelope(product, {
        deleted_at: new Date().toISOString(),
      }),
      type: PatchType.ROW,
      status: ChangesetRowStatus.UNSTAGED,
    },
  ])
}

async function updateProduct<TChanges extends Partial<StoreProduct>>(
  product: StoreProduct,
  changes: TChanges,
  mutate: KeyedMutator<DataOnlyResponse<StoreProduct>>,
  clientKey: string,
): Promise<unknown> {
  mutate({ data: { ...product, ...changes } })

  await patch<TChanges>(
    `/api/products/${product.id}`,
    [
      {
        ...generateJSONPatchEnvelope(product, changes),
        type: PatchType.ROW,
        status: ChangesetRowStatus.STAGED,
      },
    ],
    {
      headers: {
        'client-key': clientKey,
      },
    },
  )

  return put(`/api/changesets/${clientKey}`, {
    action: 'save',
    entity_type: 'store_products_view',
  })
}

function getUnitOfMeasureAmount(value?: string | null): string {
  if (!value) {
    return ''
  }

  const { amount } = parseUOM(value)
  return amount ?? ''
}

function getUnitOfMeasure(value?: string | null): string {
  if (!value) {
    return ''
  }
  const { unit } = parseUOM(value)

  const unitOfMeasure = Object.values(UnitOfMeasure)
    .map(toReadableEnum)
    .find((uom) => uom === toReadableEnum(value.split(' ').slice(1).join(' ')))

  return unitOfMeasure ? toReadableEnum(unitOfMeasure) : unit || ''
}

function getCommonValueByKey<T>(
  arrayOfObjects: Array<T> | undefined,
  key: keyof T,
): T[keyof T] | null {
  if (!arrayOfObjects || arrayOfObjects.length === 0) return null

  const firstObject = arrayOfObjects[0]
  const value = firstObject[key]

  for (const object of arrayOfObjects) {
    if (
      Array.isArray(value) &&
      JSON.stringify(value) === JSON.stringify(object[key])
    ) {
      return value
    }
    if (object[key] !== value) {
      return null
    }
  }

  return value
}

const getBulkInitialValues = (data: StoreProduct[] | undefined): BulkFields => {
  if (!data) return {} as BulkFields
  const bulkFields: BulkFields = {
    brand_string: null,
    pack_size: null,
    department_id: '',
    ebt_enabled: null,
    ecommerce_enabled: null,
    min_customer_age: null,
    price: '',
    prompt_for_quantity: null,
    sold_by_weight: null,
    target_margin: '',
    tax_rate_ids: null,
    unit_volume_string: null,
    variable_sale_price: null,
    wic_enabled: null,
    print_physical_tag: null,
    retail_price_uom_id: null,
    is_tippable: null,
  }

  for (const field of Object.keys(bulkFields)) {
    const typedField = field as keyof BulkFields
    const value = getCommonValueByKey<StoreProductFormValues>(
      data as unknown as StoreProductFormValues[],
      typedField,
    )
    if (value && typedField) {
      Object.assign(bulkFields, { [typedField]: value })
    } else {
      Object.assign(bulkFields, { [typedField]: undefined })
    }
  }

  return bulkFields
}

const getInitialValues = (
  data:
    | (Partial<StoreProduct> & {
        storeDepartmentIDs?: string[]
        requiredStores?: string[]
        vendors?: VendorCosts
        defaultVendorCosts?: VendorCosts['costs']
        readOnlyCosts?: boolean
      })
    | undefined,
): StoreProductFormValues => {
  let packSize =
    typeof data?.pack_size === 'string'
      ? Number.parseFloat(data?.pack_size)
      : data?.pack_size
  if ((packSize as unknown as string) === '1' || packSize === 1 || !packSize) {
    packSize = null
  }

  return {
    active: data?.active || null,
    readOnlyCosts: data?.readOnlyCosts || false,
    brand_string: data?.brand_string || '',
    pack_size: packSize,
    case_upc: data?.case_upc || null,
    category: data?.category || null,
    department_id: data?.department_id || '',
    ebt_enabled: data?.ebt_enabled || false,
    ecommerce_enabled: data?.ecommerce_enabled || false,
    id: data?.id || '',
    min_customer_age: data?.min_customer_age || 'None',
    name: data?.name || '',
    pack_upc: data?.pack_upc || null,
    price: data?.price || undefined,
    primary_case_cost: String(data?.primary_case_cost || ''),
    primary_case_size: data?.primary_case_size || null,
    primary_case_upc: data?.primary_case_upc || null,
    primary_description: data?.primary_description || null,
    primary_pack_upc: data?.primary_pack_upc || null,
    primary_unit_cost: String(data?.primary_unit_cost || ''),
    primary_unit_upc: data?.primary_unit_upc || null,
    primary_unit_volume: data?.primary_unit_volume || null,
    prompt_for_quantity: data?.prompt_for_quantity || false,
    requiredStores: data?.requiredStores || [],
    item_modifier_ids: data?.item_modifier_ids || [],
    variable_weight_ids: data?.variable_weight_ids || [],
    store_id: data?.store_id || '',
    storeDepartmentIDs: data?.storeDepartmentIDs || [],
    target_margin: data?.target_margin || undefined,
    tax_rate_ids: data?.tax_rate_ids || null,
    unit_upc: data?.unit_upc || null,
    unit_volume_string: data?.unit_volume_string || null,
    unitOfMeasure: data?.unit_volume_uom_name,
    unitOfMeasureAmount: data?.unit_volume_quantity
      ? `${Number(data.unit_volume_quantity)}`
      : null,
    barcode: data?.barcode || data?.upc || '',
    upc: data?.barcode || data?.upc || '',
    variable_sale_price: data?.variable_sale_price || false,
    wic_enabled: data?.wic_enabled || false,
    print_physical_tag: data?.print_physical_tag !== false,
    esl_enabled: data?.esl_enabled || false,
    is_tippable: data?.is_tippable || false,
    additional_barcodes: data?.additional_barcodes || null,
    last_pushed_to_pos_at: data?.last_pushed_to_pos_at || '',
    updated_at: data?.updated_at || '',
    vendors: {
      defaultCostConfiguration:
        data?.vendors?.costConfiguration || 'LATEST_COST',
      costConfiguration: data?.vendors?.costConfiguration || 'LATEST_COST',
      activeVendorIndex: data?.vendors?.activeVendorIndex,
      costs: data?.vendors?.costs || [],
      editingVendorIndex: undefined,
    },
    defaultVendorCosts: data?.defaultVendorCosts || [],
    manual_item: data?.manual_item || false,
    retail_price_uom_id: data?.retail_price_uom_id || null,
    retail_price_uom_name: data?.retail_price_uom_name || null,
    sold_by_weight:
      (data?.retail_price_uom_name && data.retail_price_uom_name !== 'EACH') ||
      data?.sold_by_weight ||
      false,
    inventory_total: data?.inventory_total || null,
    initial_inventory_total: data?.inventory_total || null,
    country_of_origin: data?.country_of_origin || null,
  }
}

const formatEditData = (
  values: StoreProductFormValues,
): EditableStoreProductFields => ({
  taxRateIDs: values?.tax_rate_ids?.length ? values.tax_rate_ids : [],
  brand: values.brand_string,
  caseSize: Number(values.primary_case_size),
  ebtEnabled: values.ebt_enabled,
  ecommerceEnabled: values.ecommerce_enabled,
  minCustomerAge:
    values.min_customer_age === 'None' ? 0 : Number(values.min_customer_age),
  name: String(values.name),
  price:
    typeof values.price === 'string'
      ? Number.parseFloat(values.price)
      : values.price,
  priceSource: StoreProductPriceSource.ProductDetails,
  priceSourcePlatform: StoreProductPriceSourcePlatform.Web,
  printPhysicalTag: values.print_physical_tag,
  isTippable: values.is_tippable,
  promptForQuantity: values.prompt_for_quantity,
  receiptDescription: values.name,
  soldByWeight: values.sold_by_weight,
  storeDepartmentID: values.department_id,
  targetMargin: Number(values.target_margin),
  unitOfMeasure: values.unitOfMeasure,
  unitOfMeasureAmount: values.unitOfMeasureAmount,
  variableWeightIDs: values.variable_weight_ids,
  itemModifierIDs: values.item_modifier_ids,
  barcode: values.barcode,
  variableSalePrice: values.variable_sale_price,
  wicEnabled: values.wic_enabled,
  packSize:
    typeof values.pack_size === 'string'
      ? Number.parseFloat(values.pack_size)
      : values.pack_size,
  manualItem: Boolean(values.manual_item),
  retailPriceUnitOfMeasureID: values.retail_price_uom_id,
  countryOfOrigin: values.country_of_origin || null,
})

const notNullish = (value: unknown): value is null | undefined => {
  return value !== null && value !== undefined
}

const formatBulkEditData = (
  values: StoreProductFormValues,
): EditableStoreProductFields => {
  return {
    ...(notNullish(values.brand_string) && { brand: values.brand_string }),
    ...(notNullish(values.ebt_enabled) && { ebtEnabled: values.ebt_enabled }),
    ...(notNullish(values.ecommerce_enabled) && {
      ecommerceEnabled: values.ecommerce_enabled,
    }),
    ...(notNullish(values.min_customer_age) &&
      (!Number.isNaN(values.min_customer_age) ||
        values.min_customer_age === 'None') && {
        minCustomerAge:
          values.min_customer_age === 'None'
            ? 0
            : Number(values.min_customer_age),
      }),
    ...(notNullish(values.price) && {
      price:
        typeof values.price === 'string'
          ? Number.parseFloat(values.price)
          : values.price,
      priceSource: StoreProductPriceSource.BulkUpdate,
      priceSourcePlatform: StoreProductPriceSourcePlatform.Web,
      priceInputMethod: StoreProductPriceInputMethod.ManuallyEnteredPrice,
    }),
    ...(notNullish(values.retail_price_uom_id) && {
      retail_price_uom_id: values.retail_price_uom_id,
    }),
    ...(notNullish(values.item_modifier_ids) && {
      itemModifierIDs: values.item_modifier_ids,
    }),
    ...(notNullish(values.variable_weight_ids) && {
      variableWeightIDs: values.variable_weight_ids,
    }),
    ...(notNullish(values.prompt_for_quantity) && {
      promptForQuantity: values.prompt_for_quantity,
    }),
    ...(notNullish(values.department_id) && {
      storeDepartmentID: values.department_id,
    }),
    ...(notNullish(values.target_margin) &&
      !Number.isNaN(values.target_margin) && {
        targetMargin: Number(values.target_margin),
      }),
    ...(values?.tax_rate_ids?.length && {
      taxRateIDs: values.tax_rate_ids,
    }),
    ...(notNullish(values.unitOfMeasure) && {
      unitOfMeasure: values.unitOfMeasure,
    }),
    ...(notNullish(values.unitOfMeasureAmount) && {
      unitOfMeasureAmount: values.unitOfMeasureAmount,
    }),
    ...(notNullish(values.variable_sale_price) && {
      variableSalePrice: values.variable_sale_price,
    }),
    ...(notNullish(values.wic_enabled) && {
      wicEnabled: values.wic_enabled,
    }),
    ...(notNullish(values.print_physical_tag) && {
      printPhysicalTag: values.print_physical_tag,
    }),
    ...(notNullish(values.is_tippable) && {
      isTippable: values.is_tippable,
    }),
    ...(notNullish(values.manual_item) && {
      manualItem: values.manual_item,
    }),
  }
}

const getPricePerUnit = ({
  unitOfMeasure,
  unitOfMeasureAmount,
  price,
}: Pick<
  StoreProductFormValues,
  'unitOfMeasure' | 'unitOfMeasureAmount' | 'price'
>): string => {
  if (!unitOfMeasure || !unitOfMeasureAmount || !price) return ''
  const pricePerUnitAmount = Number(price) / Number(unitOfMeasureAmount)
  const isDollar = pricePerUnitAmount >= 1
  const amount = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    maximumFractionDigits: isDollar ? 2 : 1,
    minimumFractionDigits: isDollar ? 2 : 1,
  }).format(pricePerUnitAmount * (isDollar ? 1 : 100))
  if (amount === 'NaN') return ''
  return `${isDollar ? '$' : ''}${amount}${
    !isDollar ? '¢' : ''
  } per ${unitOfMeasure}`
}

const formatCreateData = (
  values: StoreProductFormValues,
): CreateStoreProductFields => ({
  taxRateIDs: values.tax_rate_ids ?? undefined,
  itemModifierIDs: values.item_modifier_ids ?? undefined,
  variableWeightIDs: values.variable_weight_ids ?? undefined,
  brand: values.brand_string,
  caseSize: Number(values.primary_case_size),
  ebtEnabled: values.ebt_enabled,
  ecommerceEnabled: values.ecommerce_enabled,
  minCustomerAge:
    values.min_customer_age === 'None' ? 0 : Number(values.min_customer_age),
  name: String(values.name),
  price:
    typeof values.price === 'string'
      ? Number.parseFloat(values.price)
      : values.price,
  priceSource: StoreProductPriceSource.ProductDetails,
  priceSourcePlatform: StoreProductPriceSourcePlatform.Web,
  promptForQuantity: values.prompt_for_quantity,
  receiptDescription: values.name || '',
  soldByWeight: values.sold_by_weight,
  targetMargin: Number(values.target_margin),
  unitOfMeasure: values.unitOfMeasure,
  unitOfMeasureAmount: values.unitOfMeasureAmount,
  upc: String(values.barcode),
  barcode: String(values.barcode),
  variableSalePrice: values.variable_sale_price,
  wicEnabled: values.wic_enabled,
  printPhysicalTag: values.print_physical_tag,
  isTippable: values.is_tippable,
  packSize:
    typeof values.pack_size === 'string'
      ? Number.parseFloat(values.pack_size)
      : values.pack_size,
  retailPriceUnitOfMeasureID: values.retail_price_uom_id,
  manualItem: values.manual_item || false,
  countryOfOrigin: values.country_of_origin || null,
})

const UOMValues = [
  {
    name: 'Grams',
    value: 'g',
  },
  {
    name: 'Kilograms',
    value: 'kg',
  },
  {
    name: 'Liters',
    value: 'L',
  },
  {
    name: 'Milligrams',
    value: 'mg',
  },
  {
    name: 'Milliliters',
    value: 'mL',
  },
  {
    name: 'Millimeters',
    value: 'mm',
  },
  {
    name: 'Meters',
    value: 'm',
  },
  {
    name: 'Centimeters',
    value: 'cm',
  },
  {
    name: 'Count',
    value: 'ct',
  },
  {
    name: 'Each',
    value: 'ea',
  },
  {
    name: 'Feet',
    value: 'ft',
  },
  {
    name: 'Fluid Ounces',
    value: 'fl oz',
  },
  {
    name: 'Gallons',
    value: 'gal',
  },
  {
    name: 'Inches',
    value: 'in',
  },
  {
    name: 'Ounces',
    value: 'oz',
  },
  {
    name: 'Pieces',
    value: 'pc',
  },
  {
    name: 'Pints',
    value: 'pt',
  },
  {
    name: 'Pounds',
    value: 'lb',
  },
  {
    name: 'Quarts',
    value: 'qt',
  },
  {
    name: 'Dozen',
    value: 'dz',
  },
  {
    name: 'Yard',
    value: 'yd',
  },
  {
    name: 'Square Feet',
    value: 'sq ft',
  },
]

const getPosSyncStatus = (
  productData: Partial<StoreProduct> & {
    storeDepartmentIDs?: string[] | undefined
    requiredStores?: string[] | undefined
  },
): {
  status: StoreProductPointOfSaleStatus
  colorVariant: ColorVariant
} => {
  const {
    product_fields_last_updated_at,
    last_pushed_to_pos_at,
    disable_pos_sync,
    price,
  } = productData

  if (disable_pos_sync) {
    return {
      status: StoreProductPointOfSaleStatus.SYNC_DISABLED,
      colorVariant: 'default',
    }
  } else if (
    product_fields_last_updated_at &&
    last_pushed_to_pos_at &&
    last_pushed_to_pos_at > product_fields_last_updated_at
  ) {
    return {
      status: StoreProductPointOfSaleStatus.READY_FOR_SALE,
      colorVariant: 'secondary',
    }
  } else if (!price || new Decimal(price).lessThan(0.01)) {
    return {
      status: StoreProductPointOfSaleStatus.DATA_INVALID,
      colorVariant: 'progress',
    }
  }
  return {
    status: StoreProductPointOfSaleStatus.OUTDATED,
    colorVariant: 'negative',
  }
}

const getSuggestedPrices = ({
  stores,
  targetMargin,
  unitCost,
}: {
  stores: Array<StoreEntity>
  targetMargin: string | number | undefined
  unitCost: string | number | undefined
}): Array<{
  label: string
  value: number | string
}> => {
  if (!unitCost) {
    return []
  }

  const cost = new Decimal(unitCost)
  const canApplyRounding = !stores.find(({ serialID }) => serialID === '4435')

  const suggestedRetailOptions = [
    { name: 'High', value: 0.4 },
    { name: 'Medium', value: 0.35 },
    { name: 'Low', value: 0.3 },
  ].map(({ name, value }) => ({
    label: `${name} (${Number(value * 100).toFixed(2)}%)`,
    value: canApplyRounding
      ? applyRoundingRule(
          cost.div(1 - new Decimal(value).toNumber()),
        ).toNumber()
      : cost
          .div(1 - new Decimal(value).toNumber())
          .times(100)
          .toNumber(),
  }))

  if (targetMargin && cost && new Decimal(targetMargin).greaterThan(0)) {
    suggestedRetailOptions.unshift({
      label: `Target Margin (${targetMargin}%)`,
      value: canApplyRounding
        ? applyRoundingRule(
            cost.div(1 - new Decimal(targetMargin).div(100).toNumber()),
          )?.toNumber?.()
        : cost
            .div(1 - new Decimal(targetMargin).div(100).toNumber())
            .times(100)
            .toNumber(),
    })
  }

  return suggestedRetailOptions
}

export {
  archiveProduct,
  calculateCheckDigit,
  formatBulkEditData,
  formatCreateData,
  formatEditData,
  getBulkInitialValues,
  getCommonValueByKey,
  getInitialValues,
  getPosSyncStatus,
  getPricePerUnit,
  getSuggestedPrices,
  getUnitOfMeasure,
  getUnitOfMeasureAmount,
  getVendorInitials,
  isValidBarcode,
  isValidUPC,
  parseUOM,
  UOMValues,
  updateProduct,
}
