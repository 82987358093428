/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Vori API
 * OpenAPI spec version: 1.0.0
 */
import useSwr from 'swr'
import type { Arguments, Key, SWRConfiguration } from 'swr'
import useSWRMutation from 'swr/mutation'
import type { SWRMutationConfiguration } from 'swr/mutation'
import type {
  CashSummaryGetDto,
  CashierPaymentsSummariesGetDto,
  CheckSigmaExportReadyDTO,
  CreateCustomQuickActionDto,
  CreateCustomQuickActionTemplate400,
  CreateCustomQuickActionTemplateDto,
  CreateCustomQuickActions400,
  CreateDiscountDto,
  CreatePosReceiptConfigurationDto,
  CreateReportingBookmark400,
  CreateReportingBookmarkDto,
  CreateTillReviewDto,
  CreateUserScheduledReportDto,
  CustomQuickActionDto,
  CustomQuickActionTemplateDto,
  DepartmentSalesSummariesGetDto,
  DiscountDto,
  DomainDataMetadataDto,
  GetCashSummaryParams,
  GetCashierPaymentsSummariesParams,
  GetDepartmentSalesSummariesParams,
  GetHourlySalesParams,
  GetLanePaymentsSummariesParams,
  GetPosOrderParams,
  GetProductSalesParams,
  GetSalesSummaryParams,
  GetSigmaEmbedUrl400,
  GetSigmaEmbedUrlParams,
  HourlySalesGetDto,
  InitiateSigmaExportDTO,
  LaneCredentialsDto,
  LaneDto,
  LanePaymentsSummariesGetDto,
  LaneProvisioningTokenMetadataDto,
  LaneProvisioningTokenSubmissionBodyDto,
  ListPosOrdersParams,
  ListReportingBookmark400,
  ListReportingBookmarkParams,
  ListTillsParams,
  ListUserScheduledReportParams,
  PosBannerConfigurationDto,
  PosBannerConfigurationListDto,
  PosOrderDto,
  PosOrdersListDto,
  PosReceiptConfigurationDto,
  ProductSalesGetDto,
  ReportingBookmarkDto,
  SalesSummaryGetDto,
  SigmaEmbedUrl,
  TillDto,
  TillNotClosedError,
  TillReviewListDto,
  TillSummaryListDto,
  UpdateCustomQuickActionDto,
  UpdateCustomQuickActionTemplate400,
  UpdateCustomQuickActionTemplateDto,
  UpdateCustomQuickActions400,
  UpdateDiscountDto,
  UpdatePosBannerConfigurationDto,
  UpdatePosReceiptConfigurationDto,
  UpdateUserScheduledReportDto,
  UserScheduledReportDto,
} from '.././schemas'
import { customAxiosInstance } from '../../CustomAxiosInstance'
import type { ErrorType } from '../../CustomAxiosInstance'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

export const createCustomQuickActionTemplate = (
  createCustomQuickActionTemplateDto: CreateCustomQuickActionTemplateDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<CustomQuickActionTemplateDto>(
    {
      url: `/v1/custom-quick-action-templates`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createCustomQuickActionTemplateDto,
    },
    options,
  )
}

export const getCreateCustomQuickActionTemplateMutationFetcher = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: CreateCustomQuickActionTemplateDto },
  ): Promise<CustomQuickActionTemplateDto> => {
    return createCustomQuickActionTemplate(arg, options)
  }
}
export const getCreateCustomQuickActionTemplateMutationKey = () =>
  [`/v1/custom-quick-action-templates`] as const

export type CreateCustomQuickActionTemplateMutationResult = NonNullable<
  Awaited<ReturnType<typeof createCustomQuickActionTemplate>>
>
export type CreateCustomQuickActionTemplateMutationError =
  ErrorType<CreateCustomQuickActionTemplate400>

export const useCreateCustomQuickActionTemplate = <
  TError = ErrorType<CreateCustomQuickActionTemplate400>,
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof createCustomQuickActionTemplate>>,
    TError,
    Key,
    CreateCustomQuickActionTemplateDto,
    Awaited<ReturnType<typeof createCustomQuickActionTemplate>>
  > & { swrKey?: string }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey =
    swrOptions?.swrKey ?? getCreateCustomQuickActionTemplateMutationKey()
  const swrFn =
    getCreateCustomQuickActionTemplateMutationFetcher(requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const listCustomQuickActionTemplate = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<CustomQuickActionTemplateDto[]>(
    { url: `/v1/custom-quick-action-templates`, method: 'GET' },
    options,
  )
}

export const getListCustomQuickActionTemplateKey = () =>
  [`/v1/custom-quick-action-templates`] as const

export type ListCustomQuickActionTemplateQueryResult = NonNullable<
  Awaited<ReturnType<typeof listCustomQuickActionTemplate>>
>
export type ListCustomQuickActionTemplateQueryError = ErrorType<unknown>

export const useListCustomQuickActionTemplate = <
  TError = ErrorType<unknown>,
>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof listCustomQuickActionTemplate>>,
    TError
  > & { swrKey?: Key; enabled?: boolean }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListCustomQuickActionTemplateKey() : null))
  const swrFn = () => listCustomQuickActionTemplate(requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const getCustomQuickActionTemplate = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<CustomQuickActionTemplateDto>(
    { url: `/v1/custom-quick-action-templates/${id}`, method: 'GET' },
    options,
  )
}

export const getGetCustomQuickActionTemplateKey = (id: string) =>
  [`/v1/custom-quick-action-templates/${id}`] as const

export type GetCustomQuickActionTemplateQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomQuickActionTemplate>>
>
export type GetCustomQuickActionTemplateQueryError = ErrorType<void>

export const useGetCustomQuickActionTemplate = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getCustomQuickActionTemplate>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!id
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetCustomQuickActionTemplateKey(id) : null))
  const swrFn = () => getCustomQuickActionTemplate(id, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const updateCustomQuickActionTemplate = (
  id: string,
  updateCustomQuickActionTemplateDto: UpdateCustomQuickActionTemplateDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<CustomQuickActionTemplateDto>(
    {
      url: `/v1/custom-quick-action-templates/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: updateCustomQuickActionTemplateDto,
    },
    options,
  )
}

export const getUpdateCustomQuickActionTemplateMutationFetcher = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: UpdateCustomQuickActionTemplateDto },
  ): Promise<CustomQuickActionTemplateDto> => {
    return updateCustomQuickActionTemplate(id, arg, options)
  }
}
export const getUpdateCustomQuickActionTemplateMutationKey = (id: string) =>
  [`/v1/custom-quick-action-templates/${id}`] as const

export type UpdateCustomQuickActionTemplateMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateCustomQuickActionTemplate>>
>
export type UpdateCustomQuickActionTemplateMutationError =
  ErrorType<UpdateCustomQuickActionTemplate400 | void>

export const useUpdateCustomQuickActionTemplate = <
  TError = ErrorType<UpdateCustomQuickActionTemplate400 | void>,
>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof updateCustomQuickActionTemplate>>,
      TError,
      Key,
      UpdateCustomQuickActionTemplateDto,
      Awaited<ReturnType<typeof updateCustomQuickActionTemplate>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey =
    swrOptions?.swrKey ?? getUpdateCustomQuickActionTemplateMutationKey(id)
  const swrFn = getUpdateCustomQuickActionTemplateMutationFetcher(
    id,
    requestOptions,
  )

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const deleteCustomQuickActionTemplate = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<void>(
    { url: `/v1/custom-quick-action-templates/${id}`, method: 'DELETE' },
    options,
  )
}

export const getDeleteCustomQuickActionTemplateMutationFetcher = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (_: Key, __: { arg: Arguments }): Promise<void> => {
    return deleteCustomQuickActionTemplate(id, options)
  }
}
export const getDeleteCustomQuickActionTemplateMutationKey = (id: string) =>
  [`/v1/custom-quick-action-templates/${id}`] as const

export type DeleteCustomQuickActionTemplateMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteCustomQuickActionTemplate>>
>
export type DeleteCustomQuickActionTemplateMutationError = ErrorType<void>

export const useDeleteCustomQuickActionTemplate = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof deleteCustomQuickActionTemplate>>,
      TError,
      Key,
      Arguments,
      Awaited<ReturnType<typeof deleteCustomQuickActionTemplate>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey =
    swrOptions?.swrKey ?? getDeleteCustomQuickActionTemplateMutationKey(id)
  const swrFn = getDeleteCustomQuickActionTemplateMutationFetcher(
    id,
    requestOptions,
  )

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const createCustomQuickActions = (
  createCustomQuickActionDto: CreateCustomQuickActionDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<CustomQuickActionDto>(
    {
      url: `/v1/custom-quick-actions`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createCustomQuickActionDto,
    },
    options,
  )
}

export const getCreateCustomQuickActionsMutationFetcher = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: CreateCustomQuickActionDto },
  ): Promise<CustomQuickActionDto> => {
    return createCustomQuickActions(arg, options)
  }
}
export const getCreateCustomQuickActionsMutationKey = () =>
  [`/v1/custom-quick-actions`] as const

export type CreateCustomQuickActionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof createCustomQuickActions>>
>
export type CreateCustomQuickActionsMutationError =
  ErrorType<CreateCustomQuickActions400>

export const useCreateCustomQuickActions = <
  TError = ErrorType<CreateCustomQuickActions400>,
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof createCustomQuickActions>>,
    TError,
    Key,
    CreateCustomQuickActionDto,
    Awaited<ReturnType<typeof createCustomQuickActions>>
  > & { swrKey?: string }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getCreateCustomQuickActionsMutationKey()
  const swrFn = getCreateCustomQuickActionsMutationFetcher(requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const listCustomQuickActions = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<CustomQuickActionDto[]>(
    { url: `/v1/custom-quick-actions`, method: 'GET' },
    options,
  )
}

export const getListCustomQuickActionsKey = () =>
  [`/v1/custom-quick-actions`] as const

export type ListCustomQuickActionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listCustomQuickActions>>
>
export type ListCustomQuickActionsQueryError = ErrorType<unknown>

export const useListCustomQuickActions = <
  TError = ErrorType<unknown>,
>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof listCustomQuickActions>>,
    TError
  > & { swrKey?: Key; enabled?: boolean }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListCustomQuickActionsKey() : null))
  const swrFn = () => listCustomQuickActions(requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const getCustomQuickActions = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<CustomQuickActionDto>(
    { url: `/v1/custom-quick-actions/${id}`, method: 'GET' },
    options,
  )
}

export const getGetCustomQuickActionsKey = (id: string) =>
  [`/v1/custom-quick-actions/${id}`] as const

export type GetCustomQuickActionsQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCustomQuickActions>>
>
export type GetCustomQuickActionsQueryError = ErrorType<void>

export const useGetCustomQuickActions = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getCustomQuickActions>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!id
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetCustomQuickActionsKey(id) : null))
  const swrFn = () => getCustomQuickActions(id, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const updateCustomQuickActions = (
  id: string,
  updateCustomQuickActionDto: UpdateCustomQuickActionDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<CustomQuickActionDto>(
    {
      url: `/v1/custom-quick-actions/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: updateCustomQuickActionDto,
    },
    options,
  )
}

export const getUpdateCustomQuickActionsMutationFetcher = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: UpdateCustomQuickActionDto },
  ): Promise<CustomQuickActionDto> => {
    return updateCustomQuickActions(id, arg, options)
  }
}
export const getUpdateCustomQuickActionsMutationKey = (id: string) =>
  [`/v1/custom-quick-actions/${id}`] as const

export type UpdateCustomQuickActionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateCustomQuickActions>>
>
export type UpdateCustomQuickActionsMutationError =
  ErrorType<UpdateCustomQuickActions400 | void>

export const useUpdateCustomQuickActions = <
  TError = ErrorType<UpdateCustomQuickActions400 | void>,
>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof updateCustomQuickActions>>,
      TError,
      Key,
      UpdateCustomQuickActionDto,
      Awaited<ReturnType<typeof updateCustomQuickActions>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey =
    swrOptions?.swrKey ?? getUpdateCustomQuickActionsMutationKey(id)
  const swrFn = getUpdateCustomQuickActionsMutationFetcher(id, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const deleteCustomQuickActions = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<void>(
    { url: `/v1/custom-quick-actions/${id}`, method: 'DELETE' },
    options,
  )
}

export const getDeleteCustomQuickActionsMutationFetcher = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (_: Key, __: { arg: Arguments }): Promise<void> => {
    return deleteCustomQuickActions(id, options)
  }
}
export const getDeleteCustomQuickActionsMutationKey = (id: string) =>
  [`/v1/custom-quick-actions/${id}`] as const

export type DeleteCustomQuickActionsMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteCustomQuickActions>>
>
export type DeleteCustomQuickActionsMutationError = ErrorType<void>

export const useDeleteCustomQuickActions = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof deleteCustomQuickActions>>,
      TError,
      Key,
      Arguments,
      Awaited<ReturnType<typeof deleteCustomQuickActions>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey =
    swrOptions?.swrKey ?? getDeleteCustomQuickActionsMutationKey(id)
  const swrFn = getDeleteCustomQuickActionsMutationFetcher(id, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const createDiscount = (
  createDiscountDto: CreateDiscountDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<DiscountDto>(
    {
      url: `/v1/discounts`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createDiscountDto,
    },
    options,
  )
}

export const getCreateDiscountMutationFetcher = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: CreateDiscountDto },
  ): Promise<DiscountDto> => {
    return createDiscount(arg, options)
  }
}
export const getCreateDiscountMutationKey = () => [`/v1/discounts`] as const

export type CreateDiscountMutationResult = NonNullable<
  Awaited<ReturnType<typeof createDiscount>>
>
export type CreateDiscountMutationError = ErrorType<void>

export const useCreateDiscount = <TError = ErrorType<void>>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof createDiscount>>,
    TError,
    Key,
    CreateDiscountDto,
    Awaited<ReturnType<typeof createDiscount>>
  > & { swrKey?: string }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getCreateDiscountMutationKey()
  const swrFn = getCreateDiscountMutationFetcher(requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const listDiscount = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<DiscountDto[]>(
    { url: `/v1/discounts`, method: 'GET' },
    options,
  )
}

export const getListDiscountKey = () => [`/v1/discounts`] as const

export type ListDiscountQueryResult = NonNullable<
  Awaited<ReturnType<typeof listDiscount>>
>
export type ListDiscountQueryError = ErrorType<unknown>

export const useListDiscount = <TError = ErrorType<unknown>>(options?: {
  swr?: SWRConfiguration<Awaited<ReturnType<typeof listDiscount>>, TError> & {
    swrKey?: Key
    enabled?: boolean
  }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getListDiscountKey() : null))
  const swrFn = () => listDiscount(requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const getDiscount = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<DiscountDto>(
    { url: `/v1/discounts/${id}`, method: 'GET' },
    options,
  )
}

export const getGetDiscountKey = (id: string) =>
  [`/v1/discounts/${id}`] as const

export type GetDiscountQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDiscount>>
>
export type GetDiscountQueryError = ErrorType<void>

export const useGetDiscount = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof getDiscount>>, TError> & {
      swrKey?: Key
      enabled?: boolean
    }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!id
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getGetDiscountKey(id) : null))
  const swrFn = () => getDiscount(id, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const updateDiscount = (
  id: string,
  updateDiscountDto: UpdateDiscountDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<DiscountDto>(
    {
      url: `/v1/discounts/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: updateDiscountDto,
    },
    options,
  )
}

export const getUpdateDiscountMutationFetcher = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: UpdateDiscountDto },
  ): Promise<DiscountDto> => {
    return updateDiscount(id, arg, options)
  }
}
export const getUpdateDiscountMutationKey = (id: string) =>
  [`/v1/discounts/${id}`] as const

export type UpdateDiscountMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateDiscount>>
>
export type UpdateDiscountMutationError = ErrorType<void>

export const useUpdateDiscount = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof updateDiscount>>,
      TError,
      Key,
      UpdateDiscountDto,
      Awaited<ReturnType<typeof updateDiscount>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getUpdateDiscountMutationKey(id)
  const swrFn = getUpdateDiscountMutationFetcher(id, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const deleteDiscount = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<void>(
    { url: `/v1/discounts/${id}`, method: 'DELETE' },
    options,
  )
}

export const getDeleteDiscountMutationFetcher = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (_: Key, __: { arg: Arguments }): Promise<void> => {
    return deleteDiscount(id, options)
  }
}
export const getDeleteDiscountMutationKey = (id: string) =>
  [`/v1/discounts/${id}`] as const

export type DeleteDiscountMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteDiscount>>
>
export type DeleteDiscountMutationError = ErrorType<void>

export const useDeleteDiscount = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof deleteDiscount>>,
      TError,
      Key,
      Arguments,
      Awaited<ReturnType<typeof deleteDiscount>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getDeleteDiscountMutationKey(id)
  const swrFn = getDeleteDiscountMutationFetcher(id, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
/**
 * This **WILL** consume the token, making it unusable for future usage.
 * @summary Exchanges the token for data needed to provision the lane.
 */
export const exchangeLaneProvisioningToken = (
  laneProvisioningTokenSubmissionBodyDto: LaneProvisioningTokenSubmissionBodyDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<LaneCredentialsDto>(
    {
      url: `/v1/lane-provisioning-tokens/exchange`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: laneProvisioningTokenSubmissionBodyDto,
    },
    options,
  )
}

export const getExchangeLaneProvisioningTokenMutationFetcher = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: LaneProvisioningTokenSubmissionBodyDto },
  ): Promise<LaneCredentialsDto> => {
    return exchangeLaneProvisioningToken(arg, options)
  }
}
export const getExchangeLaneProvisioningTokenMutationKey = () =>
  [`/v1/lane-provisioning-tokens/exchange`] as const

export type ExchangeLaneProvisioningTokenMutationResult = NonNullable<
  Awaited<ReturnType<typeof exchangeLaneProvisioningToken>>
>
export type ExchangeLaneProvisioningTokenMutationError = ErrorType<void>

/**
 * @summary Exchanges the token for data needed to provision the lane.
 */
export const useExchangeLaneProvisioningToken = <
  TError = ErrorType<void>,
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof exchangeLaneProvisioningToken>>,
    TError,
    Key,
    LaneProvisioningTokenSubmissionBodyDto,
    Awaited<ReturnType<typeof exchangeLaneProvisioningToken>>
  > & { swrKey?: string }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey =
    swrOptions?.swrKey ?? getExchangeLaneProvisioningTokenMutationKey()
  const swrFn = getExchangeLaneProvisioningTokenMutationFetcher(requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
/**
 * This can be used to determine the banner, store, and lane that are associated with a token. This endpoint will **NOT** consume the token. An error response will be returned if the token is not valid.
 * @summary Determines if the token passed in the body is valid.
 */
export const getLaneProvisioningTokenMetadata = (
  laneProvisioningTokenSubmissionBodyDto: LaneProvisioningTokenSubmissionBodyDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<LaneProvisioningTokenMetadataDto>(
    {
      url: `/v1/lane-provisioning-tokens/metadata`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: laneProvisioningTokenSubmissionBodyDto,
    },
    options,
  )
}

export const getGetLaneProvisioningTokenMetadataMutationFetcher = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: LaneProvisioningTokenSubmissionBodyDto },
  ): Promise<LaneProvisioningTokenMetadataDto> => {
    return getLaneProvisioningTokenMetadata(arg, options)
  }
}
export const getGetLaneProvisioningTokenMetadataMutationKey = () =>
  [`/v1/lane-provisioning-tokens/metadata`] as const

export type GetLaneProvisioningTokenMetadataMutationResult = NonNullable<
  Awaited<ReturnType<typeof getLaneProvisioningTokenMetadata>>
>
export type GetLaneProvisioningTokenMetadataMutationError = ErrorType<void>

/**
 * @summary Determines if the token passed in the body is valid.
 */
export const useGetLaneProvisioningTokenMetadata = <
  TError = ErrorType<void>,
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof getLaneProvisioningTokenMetadata>>,
    TError,
    Key,
    LaneProvisioningTokenSubmissionBodyDto,
    Awaited<ReturnType<typeof getLaneProvisioningTokenMetadata>>
  > & { swrKey?: string }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey =
    swrOptions?.swrKey ?? getGetLaneProvisioningTokenMetadataMutationKey()
  const swrFn =
    getGetLaneProvisioningTokenMetadataMutationFetcher(requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const listLanes = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<LaneDto[]>(
    { url: `/v1/lanes`, method: 'GET' },
    options,
  )
}

export const getListLanesKey = () => [`/v1/lanes`] as const

export type ListLanesQueryResult = NonNullable<
  Awaited<ReturnType<typeof listLanes>>
>
export type ListLanesQueryError = ErrorType<unknown>

export const useListLanes = <TError = ErrorType<unknown>>(options?: {
  swr?: SWRConfiguration<Awaited<ReturnType<typeof listLanes>>, TError> & {
    swrKey?: Key
    enabled?: boolean
  }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getListLanesKey() : null))
  const swrFn = () => listLanes(requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const getLane = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<LaneDto>(
    { url: `/v1/lanes/${id}`, method: 'GET' },
    options,
  )
}

export const getGetLaneKey = (id: string) => [`/v1/lanes/${id}`] as const

export type GetLaneQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLane>>
>
export type GetLaneQueryError = ErrorType<void>

export const useGetLane = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof getLane>>, TError> & {
      swrKey?: Key
      enabled?: boolean
    }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!id
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getGetLaneKey(id) : null))
  const swrFn = () => getLane(id, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const listPosBannerConfiguration = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<PosBannerConfigurationListDto>(
    { url: `/v1/pos-banner-configurations`, method: 'GET' },
    options,
  )
}

export const getListPosBannerConfigurationKey = () =>
  [`/v1/pos-banner-configurations`] as const

export type ListPosBannerConfigurationQueryResult = NonNullable<
  Awaited<ReturnType<typeof listPosBannerConfiguration>>
>
export type ListPosBannerConfigurationQueryError = ErrorType<unknown>

export const useListPosBannerConfiguration = <
  TError = ErrorType<unknown>,
>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof listPosBannerConfiguration>>,
    TError
  > & { swrKey?: Key; enabled?: boolean }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListPosBannerConfigurationKey() : null))
  const swrFn = () => listPosBannerConfiguration(requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const getPosBannerConfiguration = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<PosBannerConfigurationDto>(
    { url: `/v1/pos-banner-configurations/${id}`, method: 'GET' },
    options,
  )
}

export const getGetPosBannerConfigurationKey = (id: string) =>
  [`/v1/pos-banner-configurations/${id}`] as const

export type GetPosBannerConfigurationQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPosBannerConfiguration>>
>
export type GetPosBannerConfigurationQueryError = ErrorType<void>

export const useGetPosBannerConfiguration = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getPosBannerConfiguration>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!id
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetPosBannerConfigurationKey(id) : null))
  const swrFn = () => getPosBannerConfiguration(id, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const updatePosBannerConfiguration = (
  id: string,
  updatePosBannerConfigurationDto: UpdatePosBannerConfigurationDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<PosBannerConfigurationDto>(
    {
      url: `/v1/pos-banner-configurations/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: updatePosBannerConfigurationDto,
    },
    options,
  )
}

export const getUpdatePosBannerConfigurationMutationFetcher = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: UpdatePosBannerConfigurationDto },
  ): Promise<PosBannerConfigurationDto> => {
    return updatePosBannerConfiguration(id, arg, options)
  }
}
export const getUpdatePosBannerConfigurationMutationKey = (id: string) =>
  [`/v1/pos-banner-configurations/${id}`] as const

export type UpdatePosBannerConfigurationMutationResult = NonNullable<
  Awaited<ReturnType<typeof updatePosBannerConfiguration>>
>
export type UpdatePosBannerConfigurationMutationError = ErrorType<void>

export const useUpdatePosBannerConfiguration = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof updatePosBannerConfiguration>>,
      TError,
      Key,
      UpdatePosBannerConfigurationDto,
      Awaited<ReturnType<typeof updatePosBannerConfiguration>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey =
    swrOptions?.swrKey ?? getUpdatePosBannerConfigurationMutationKey(id)
  const swrFn = getUpdatePosBannerConfigurationMutationFetcher(
    id,
    requestOptions,
  )

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const createPosReceiptConfiguration = (
  createPosReceiptConfigurationDto: CreatePosReceiptConfigurationDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<PosReceiptConfigurationDto>(
    {
      url: `/v1/pos-receipt-configurations`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createPosReceiptConfigurationDto,
    },
    options,
  )
}

export const getCreatePosReceiptConfigurationMutationFetcher = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: CreatePosReceiptConfigurationDto },
  ): Promise<PosReceiptConfigurationDto> => {
    return createPosReceiptConfiguration(arg, options)
  }
}
export const getCreatePosReceiptConfigurationMutationKey = () =>
  [`/v1/pos-receipt-configurations`] as const

export type CreatePosReceiptConfigurationMutationResult = NonNullable<
  Awaited<ReturnType<typeof createPosReceiptConfiguration>>
>
export type CreatePosReceiptConfigurationMutationError = ErrorType<void>

export const useCreatePosReceiptConfiguration = <
  TError = ErrorType<void>,
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof createPosReceiptConfiguration>>,
    TError,
    Key,
    CreatePosReceiptConfigurationDto,
    Awaited<ReturnType<typeof createPosReceiptConfiguration>>
  > & { swrKey?: string }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey =
    swrOptions?.swrKey ?? getCreatePosReceiptConfigurationMutationKey()
  const swrFn = getCreatePosReceiptConfigurationMutationFetcher(requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const getPosReceiptConfigurationByBanner = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<PosReceiptConfigurationDto>(
    { url: `/v1/pos-receipt-configurations`, method: 'GET' },
    options,
  )
}

export const getGetPosReceiptConfigurationByBannerKey = () =>
  [`/v1/pos-receipt-configurations`] as const

export type GetPosReceiptConfigurationByBannerQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPosReceiptConfigurationByBanner>>
>
export type GetPosReceiptConfigurationByBannerQueryError = ErrorType<unknown>

export const useGetPosReceiptConfigurationByBanner = <
  TError = ErrorType<unknown>,
>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof getPosReceiptConfigurationByBanner>>,
    TError
  > & { swrKey?: Key; enabled?: boolean }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetPosReceiptConfigurationByBannerKey() : null))
  const swrFn = () => getPosReceiptConfigurationByBanner(requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const getPosReceiptConfiguration = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<PosReceiptConfigurationDto>(
    { url: `/v1/pos-receipt-configurations/${id}`, method: 'GET' },
    options,
  )
}

export const getGetPosReceiptConfigurationKey = (id: string) =>
  [`/v1/pos-receipt-configurations/${id}`] as const

export type GetPosReceiptConfigurationQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPosReceiptConfiguration>>
>
export type GetPosReceiptConfigurationQueryError = ErrorType<void>

export const useGetPosReceiptConfiguration = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getPosReceiptConfiguration>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!id
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetPosReceiptConfigurationKey(id) : null))
  const swrFn = () => getPosReceiptConfiguration(id, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const updatePosReceiptConfiguration = (
  id: string,
  updatePosReceiptConfigurationDto: UpdatePosReceiptConfigurationDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<PosReceiptConfigurationDto>(
    {
      url: `/v1/pos-receipt-configurations/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: updatePosReceiptConfigurationDto,
    },
    options,
  )
}

export const getUpdatePosReceiptConfigurationMutationFetcher = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: UpdatePosReceiptConfigurationDto },
  ): Promise<PosReceiptConfigurationDto> => {
    return updatePosReceiptConfiguration(id, arg, options)
  }
}
export const getUpdatePosReceiptConfigurationMutationKey = (id: string) =>
  [`/v1/pos-receipt-configurations/${id}`] as const

export type UpdatePosReceiptConfigurationMutationResult = NonNullable<
  Awaited<ReturnType<typeof updatePosReceiptConfiguration>>
>
export type UpdatePosReceiptConfigurationMutationError = ErrorType<void>

export const useUpdatePosReceiptConfiguration = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof updatePosReceiptConfiguration>>,
      TError,
      Key,
      UpdatePosReceiptConfigurationDto,
      Awaited<ReturnType<typeof updatePosReceiptConfiguration>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey =
    swrOptions?.swrKey ?? getUpdatePosReceiptConfigurationMutationKey(id)
  const swrFn = getUpdatePosReceiptConfigurationMutationFetcher(
    id,
    requestOptions,
  )

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const deletePosReceiptConfiguration = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<void>(
    { url: `/v1/pos-receipt-configurations/${id}`, method: 'DELETE' },
    options,
  )
}

export const getDeletePosReceiptConfigurationMutationFetcher = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (_: Key, __: { arg: Arguments }): Promise<void> => {
    return deletePosReceiptConfiguration(id, options)
  }
}
export const getDeletePosReceiptConfigurationMutationKey = (id: string) =>
  [`/v1/pos-receipt-configurations/${id}`] as const

export type DeletePosReceiptConfigurationMutationResult = NonNullable<
  Awaited<ReturnType<typeof deletePosReceiptConfiguration>>
>
export type DeletePosReceiptConfigurationMutationError = ErrorType<void>

export const useDeletePosReceiptConfiguration = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof deletePosReceiptConfiguration>>,
      TError,
      Key,
      Arguments,
      Awaited<ReturnType<typeof deletePosReceiptConfiguration>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey =
    swrOptions?.swrKey ?? getDeletePosReceiptConfigurationMutationKey(id)
  const swrFn = getDeletePosReceiptConfigurationMutationFetcher(
    id,
    requestOptions,
  )

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const getCashSummary = (
  params: GetCashSummaryParams,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<CashSummaryGetDto>(
    { url: `/v1/pos-reporting/cash-summary`, method: 'GET', params },
    options,
  )
}

export const getGetCashSummaryKey = (params: GetCashSummaryParams) =>
  [`/v1/pos-reporting/cash-summary`, ...(params ? [params] : [])] as const

export type GetCashSummaryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCashSummary>>
>
export type GetCashSummaryQueryError = ErrorType<unknown>

export const useGetCashSummary = <TError = ErrorType<unknown>>(
  params: GetCashSummaryParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getCashSummary>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetCashSummaryKey(params) : null))
  const swrFn = () => getCashSummary(params, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const getCashierPaymentsSummaries = (
  params: GetCashierPaymentsSummariesParams,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<CashierPaymentsSummariesGetDto>(
    {
      url: `/v1/pos-reporting/cashier-payments-summary`,
      method: 'GET',
      params,
    },
    options,
  )
}

export const getGetCashierPaymentsSummariesKey = (
  params: GetCashierPaymentsSummariesParams,
) =>
  [
    `/v1/pos-reporting/cashier-payments-summary`,
    ...(params ? [params] : []),
  ] as const

export type GetCashierPaymentsSummariesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getCashierPaymentsSummaries>>
>
export type GetCashierPaymentsSummariesQueryError = ErrorType<unknown>

export const useGetCashierPaymentsSummaries = <TError = ErrorType<unknown>>(
  params: GetCashierPaymentsSummariesParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getCashierPaymentsSummaries>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetCashierPaymentsSummariesKey(params) : null))
  const swrFn = () => getCashierPaymentsSummaries(params, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const checkSigmaExportReady = (
  checkSigmaExportReadyDTO: CheckSigmaExportReadyDTO,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<void>(
    {
      url: `/v1/pos-reporting/check-sigma-export-ready`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: checkSigmaExportReadyDTO,
    },
    options,
  )
}

export const getCheckSigmaExportReadyMutationFetcher = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: CheckSigmaExportReadyDTO },
  ): Promise<void> => {
    return checkSigmaExportReady(arg, options)
  }
}
export const getCheckSigmaExportReadyMutationKey = () =>
  [`/v1/pos-reporting/check-sigma-export-ready`] as const

export type CheckSigmaExportReadyMutationResult = NonNullable<
  Awaited<ReturnType<typeof checkSigmaExportReady>>
>
export type CheckSigmaExportReadyMutationError = ErrorType<unknown>

export const useCheckSigmaExportReady = <
  TError = ErrorType<unknown>,
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof checkSigmaExportReady>>,
    TError,
    Key,
    CheckSigmaExportReadyDTO,
    Awaited<ReturnType<typeof checkSigmaExportReady>>
  > & { swrKey?: string }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getCheckSigmaExportReadyMutationKey()
  const swrFn = getCheckSigmaExportReadyMutationFetcher(requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const getDepartmentSalesSummaries = (
  params: GetDepartmentSalesSummariesParams,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<DepartmentSalesSummariesGetDto>(
    {
      url: `/v1/pos-reporting/department-sales-summary`,
      method: 'GET',
      params,
    },
    options,
  )
}

export const getGetDepartmentSalesSummariesKey = (
  params: GetDepartmentSalesSummariesParams,
) =>
  [
    `/v1/pos-reporting/department-sales-summary`,
    ...(params ? [params] : []),
  ] as const

export type GetDepartmentSalesSummariesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDepartmentSalesSummaries>>
>
export type GetDepartmentSalesSummariesQueryError = ErrorType<unknown>

export const useGetDepartmentSalesSummaries = <TError = ErrorType<unknown>>(
  params: GetDepartmentSalesSummariesParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getDepartmentSalesSummaries>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetDepartmentSalesSummariesKey(params) : null))
  const swrFn = () => getDepartmentSalesSummaries(params, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const getHourlySales = (
  params: GetHourlySalesParams,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<HourlySalesGetDto>(
    { url: `/v1/pos-reporting/hourly-sales`, method: 'GET', params },
    options,
  )
}

export const getGetHourlySalesKey = (params: GetHourlySalesParams) =>
  [`/v1/pos-reporting/hourly-sales`, ...(params ? [params] : [])] as const

export type GetHourlySalesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getHourlySales>>
>
export type GetHourlySalesQueryError = ErrorType<unknown>

export const useGetHourlySales = <TError = ErrorType<unknown>>(
  params: GetHourlySalesParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getHourlySales>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetHourlySalesKey(params) : null))
  const swrFn = () => getHourlySales(params, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const initiateSigmaExport = (
  initiateSigmaExportDTO: InitiateSigmaExportDTO,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<void>(
    {
      url: `/v1/pos-reporting/initiate-sigma-export`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: initiateSigmaExportDTO,
    },
    options,
  )
}

export const getInitiateSigmaExportMutationFetcher = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (_: Key, { arg }: { arg: InitiateSigmaExportDTO }): Promise<void> => {
    return initiateSigmaExport(arg, options)
  }
}
export const getInitiateSigmaExportMutationKey = () =>
  [`/v1/pos-reporting/initiate-sigma-export`] as const

export type InitiateSigmaExportMutationResult = NonNullable<
  Awaited<ReturnType<typeof initiateSigmaExport>>
>
export type InitiateSigmaExportMutationError = ErrorType<unknown>

export const useInitiateSigmaExport = <TError = ErrorType<unknown>>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof initiateSigmaExport>>,
    TError,
    Key,
    InitiateSigmaExportDTO,
    Awaited<ReturnType<typeof initiateSigmaExport>>
  > & { swrKey?: string }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getInitiateSigmaExportMutationKey()
  const swrFn = getInitiateSigmaExportMutationFetcher(requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const getLanePaymentsSummaries = (
  params: GetLanePaymentsSummariesParams,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<LanePaymentsSummariesGetDto>(
    { url: `/v1/pos-reporting/lane-payments-summary`, method: 'GET', params },
    options,
  )
}

export const getGetLanePaymentsSummariesKey = (
  params: GetLanePaymentsSummariesParams,
) =>
  [
    `/v1/pos-reporting/lane-payments-summary`,
    ...(params ? [params] : []),
  ] as const

export type GetLanePaymentsSummariesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getLanePaymentsSummaries>>
>
export type GetLanePaymentsSummariesQueryError = ErrorType<unknown>

export const useGetLanePaymentsSummaries = <TError = ErrorType<unknown>>(
  params: GetLanePaymentsSummariesParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getLanePaymentsSummaries>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetLanePaymentsSummariesKey(params) : null))
  const swrFn = () => getLanePaymentsSummaries(params, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const getProductSales = (
  params: GetProductSalesParams,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<ProductSalesGetDto>(
    { url: `/v1/pos-reporting/product-sales`, method: 'GET', params },
    options,
  )
}

export const getGetProductSalesKey = (params: GetProductSalesParams) =>
  [`/v1/pos-reporting/product-sales`, ...(params ? [params] : [])] as const

export type GetProductSalesQueryResult = NonNullable<
  Awaited<ReturnType<typeof getProductSales>>
>
export type GetProductSalesQueryError = ErrorType<unknown>

export const useGetProductSales = <TError = ErrorType<unknown>>(
  params: GetProductSalesParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getProductSales>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetProductSalesKey(params) : null))
  const swrFn = () => getProductSales(params, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const createReportingBookmark = (
  createReportingBookmarkDto: CreateReportingBookmarkDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<ReportingBookmarkDto>(
    {
      url: `/v1/pos-reporting/reporting-bookmarks`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createReportingBookmarkDto,
    },
    options,
  )
}

export const getCreateReportingBookmarkMutationFetcher = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: CreateReportingBookmarkDto },
  ): Promise<ReportingBookmarkDto> => {
    return createReportingBookmark(arg, options)
  }
}
export const getCreateReportingBookmarkMutationKey = () =>
  [`/v1/pos-reporting/reporting-bookmarks`] as const

export type CreateReportingBookmarkMutationResult = NonNullable<
  Awaited<ReturnType<typeof createReportingBookmark>>
>
export type CreateReportingBookmarkMutationError =
  ErrorType<CreateReportingBookmark400>

export const useCreateReportingBookmark = <
  TError = ErrorType<CreateReportingBookmark400>,
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof createReportingBookmark>>,
    TError,
    Key,
    CreateReportingBookmarkDto,
    Awaited<ReturnType<typeof createReportingBookmark>>
  > & { swrKey?: string }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getCreateReportingBookmarkMutationKey()
  const swrFn = getCreateReportingBookmarkMutationFetcher(requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const listReportingBookmark = (
  params: ListReportingBookmarkParams,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<ReportingBookmarkDto[]>(
    { url: `/v1/pos-reporting/reporting-bookmarks`, method: 'GET', params },
    options,
  )
}

export const getListReportingBookmarkKey = (
  params: ListReportingBookmarkParams,
) =>
  [
    `/v1/pos-reporting/reporting-bookmarks`,
    ...(params ? [params] : []),
  ] as const

export type ListReportingBookmarkQueryResult = NonNullable<
  Awaited<ReturnType<typeof listReportingBookmark>>
>
export type ListReportingBookmarkQueryError =
  ErrorType<ListReportingBookmark400>

export const useListReportingBookmark = <
  TError = ErrorType<ListReportingBookmark400>,
>(
  params: ListReportingBookmarkParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listReportingBookmark>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListReportingBookmarkKey(params) : null))
  const swrFn = () => listReportingBookmark(params, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const deleteReportingBookmark = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<void>(
    { url: `/v1/pos-reporting/reporting-bookmarks/${id}`, method: 'DELETE' },
    options,
  )
}

export const getDeleteReportingBookmarkMutationFetcher = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (_: Key, __: { arg: Arguments }): Promise<void> => {
    return deleteReportingBookmark(id, options)
  }
}
export const getDeleteReportingBookmarkMutationKey = (id: string) =>
  [`/v1/pos-reporting/reporting-bookmarks/${id}`] as const

export type DeleteReportingBookmarkMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteReportingBookmark>>
>
export type DeleteReportingBookmarkMutationError = ErrorType<void>

export const useDeleteReportingBookmark = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof deleteReportingBookmark>>,
      TError,
      Key,
      Arguments,
      Awaited<ReturnType<typeof deleteReportingBookmark>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getDeleteReportingBookmarkMutationKey(id)
  const swrFn = getDeleteReportingBookmarkMutationFetcher(id, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const getSalesSummary = (
  params: GetSalesSummaryParams,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<SalesSummaryGetDto>(
    { url: `/v1/pos-reporting/sales-summary`, method: 'GET', params },
    options,
  )
}

export const getGetSalesSummaryKey = (params: GetSalesSummaryParams) =>
  [`/v1/pos-reporting/sales-summary`, ...(params ? [params] : [])] as const

export type GetSalesSummaryQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSalesSummary>>
>
export type GetSalesSummaryQueryError = ErrorType<unknown>

export const useGetSalesSummary = <TError = ErrorType<unknown>>(
  params: GetSalesSummaryParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getSalesSummary>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetSalesSummaryKey(params) : null))
  const swrFn = () => getSalesSummary(params, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const getSigmaEmbedUrl = (
  params: GetSigmaEmbedUrlParams,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<SigmaEmbedUrl>(
    { url: `/v1/pos-reporting/sigma-embed-url`, method: 'GET', params },
    options,
  )
}

export const getGetSigmaEmbedUrlKey = (params: GetSigmaEmbedUrlParams) =>
  [`/v1/pos-reporting/sigma-embed-url`, ...(params ? [params] : [])] as const

export type GetSigmaEmbedUrlQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSigmaEmbedUrl>>
>
export type GetSigmaEmbedUrlQueryError = ErrorType<GetSigmaEmbedUrl400 | void>

export const useGetSigmaEmbedUrl = <
  TError = ErrorType<GetSigmaEmbedUrl400 | void>,
>(
  params: GetSigmaEmbedUrlParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getSigmaEmbedUrl>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetSigmaEmbedUrlKey(params) : null))
  const swrFn = () => getSigmaEmbedUrl(params, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
/**
 * This endpoint can be used to download the domain database to a lane.
 * @summary Get the domain database metadata and download URL
 */
export const getPosDomainDataMetadata = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<DomainDataMetadataDto>(
    { url: `/v1/pos/domain-data`, method: 'GET' },
    options,
  )
}

export const getGetPosDomainDataMetadataKey = () =>
  [`/v1/pos/domain-data`] as const

export type GetPosDomainDataMetadataQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPosDomainDataMetadata>>
>
export type GetPosDomainDataMetadataQueryError = ErrorType<unknown>

/**
 * @summary Get the domain database metadata and download URL
 */
export const useGetPosDomainDataMetadata = <
  TError = ErrorType<unknown>,
>(options?: {
  swr?: SWRConfiguration<
    Awaited<ReturnType<typeof getPosDomainDataMetadata>>,
    TError
  > & { swrKey?: Key; enabled?: boolean }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetPosDomainDataMetadataKey() : null))
  const swrFn = () => getPosDomainDataMetadata(requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const listPosOrders = (
  params?: ListPosOrdersParams,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<PosOrdersListDto>(
    { url: `/v1/pos/orders`, method: 'GET', params },
    options,
  )
}

export const getListPosOrdersKey = (params?: ListPosOrdersParams) =>
  [`/v1/pos/orders`, ...(params ? [params] : [])] as const

export type ListPosOrdersQueryResult = NonNullable<
  Awaited<ReturnType<typeof listPosOrders>>
>
export type ListPosOrdersQueryError = ErrorType<unknown>

export const useListPosOrders = <TError = ErrorType<unknown>>(
  params?: ListPosOrdersParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listPosOrders>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListPosOrdersKey(params) : null))
  const swrFn = () => listPosOrders(params, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const getPosOrder = (
  id: string,
  params?: GetPosOrderParams,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<PosOrderDto>(
    { url: `/v1/pos/orders/${id}`, method: 'GET', params },
    options,
  )
}

export const getGetPosOrderKey = (id: string, params?: GetPosOrderParams) =>
  [`/v1/pos/orders/${id}`, ...(params ? [params] : [])] as const

export type GetPosOrderQueryResult = NonNullable<
  Awaited<ReturnType<typeof getPosOrder>>
>
export type GetPosOrderQueryError = ErrorType<void>

export const useGetPosOrder = <TError = ErrorType<void>>(
  id: string,
  params?: GetPosOrderParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof getPosOrder>>, TError> & {
      swrKey?: Key
      enabled?: boolean
    }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!id
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetPosOrderKey(id, params) : null))
  const swrFn = () => getPosOrder(id, params, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const listTills = (
  params?: ListTillsParams,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<TillSummaryListDto>(
    { url: `/v1/pos/tills`, method: 'GET', params },
    options,
  )
}

export const getListTillsKey = (params?: ListTillsParams) =>
  [`/v1/pos/tills`, ...(params ? [params] : [])] as const

export type ListTillsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listTills>>
>
export type ListTillsQueryError = ErrorType<unknown>

export const useListTills = <TError = ErrorType<unknown>>(
  params?: ListTillsParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof listTills>>, TError> & {
      swrKey?: Key
      enabled?: boolean
    }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getListTillsKey(params) : null))
  const swrFn = () => listTills(params, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const getTill = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<TillDto>(
    { url: `/v1/pos/tills/${id}`, method: 'GET' },
    options,
  )
}

export const getGetTillKey = (id: string) => [`/v1/pos/tills/${id}`] as const

export type GetTillQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTill>>
>
export type GetTillQueryError = ErrorType<void>

export const useGetTill = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof getTill>>, TError> & {
      swrKey?: Key
      enabled?: boolean
    }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!id
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getGetTillKey(id) : null))
  const swrFn = () => getTill(id, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const listTillReviews = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<TillReviewListDto>(
    { url: `/v1/pos/tills/${id}/reviews`, method: 'GET' },
    options,
  )
}

export const getListTillReviewsKey = (id: string) =>
  [`/v1/pos/tills/${id}/reviews`] as const

export type ListTillReviewsQueryResult = NonNullable<
  Awaited<ReturnType<typeof listTillReviews>>
>
export type ListTillReviewsQueryError = ErrorType<unknown>

export const useListTillReviews = <TError = ErrorType<unknown>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listTillReviews>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!id
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getListTillReviewsKey(id) : null))
  const swrFn = () => listTillReviews(id, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const createTillReview = (
  id: string,
  createTillReviewDto: CreateTillReviewDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<TillReviewListDto>(
    {
      url: `/v1/pos/tills/${id}/reviews`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createTillReviewDto,
    },
    options,
  )
}

export const getCreateTillReviewMutationFetcher = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: CreateTillReviewDto },
  ): Promise<TillReviewListDto> => {
    return createTillReview(id, arg, options)
  }
}
export const getCreateTillReviewMutationKey = (id: string) =>
  [`/v1/pos/tills/${id}/reviews`] as const

export type CreateTillReviewMutationResult = NonNullable<
  Awaited<ReturnType<typeof createTillReview>>
>
export type CreateTillReviewMutationError = ErrorType<TillNotClosedError>

export const useCreateTillReview = <TError = ErrorType<TillNotClosedError>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof createTillReview>>,
      TError,
      Key,
      CreateTillReviewDto,
      Awaited<ReturnType<typeof createTillReview>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getCreateTillReviewMutationKey(id)
  const swrFn = getCreateTillReviewMutationFetcher(id, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const createUserScheduledReport = (
  createUserScheduledReportDto: CreateUserScheduledReportDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<UserScheduledReportDto>(
    {
      url: `/v1/user-scheduled-reports`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createUserScheduledReportDto,
    },
    options,
  )
}

export const getCreateUserScheduledReportMutationFetcher = (
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: CreateUserScheduledReportDto },
  ): Promise<UserScheduledReportDto> => {
    return createUserScheduledReport(arg, options)
  }
}
export const getCreateUserScheduledReportMutationKey = () =>
  [`/v1/user-scheduled-reports`] as const

export type CreateUserScheduledReportMutationResult = NonNullable<
  Awaited<ReturnType<typeof createUserScheduledReport>>
>
export type CreateUserScheduledReportMutationError = ErrorType<void>

export const useCreateUserScheduledReport = <
  TError = ErrorType<void>,
>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof createUserScheduledReport>>,
    TError,
    Key,
    CreateUserScheduledReportDto,
    Awaited<ReturnType<typeof createUserScheduledReport>>
  > & { swrKey?: string }
  request?: SecondParameter<typeof customAxiosInstance>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getCreateUserScheduledReportMutationKey()
  const swrFn = getCreateUserScheduledReportMutationFetcher(requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const listUserScheduledReport = (
  params: ListUserScheduledReportParams,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<UserScheduledReportDto[]>(
    { url: `/v1/user-scheduled-reports`, method: 'GET', params },
    options,
  )
}

export const getListUserScheduledReportKey = (
  params: ListUserScheduledReportParams,
) => [`/v1/user-scheduled-reports`, ...(params ? [params] : [])] as const

export type ListUserScheduledReportQueryResult = NonNullable<
  Awaited<ReturnType<typeof listUserScheduledReport>>
>
export type ListUserScheduledReportQueryError = ErrorType<unknown>

export const useListUserScheduledReport = <TError = ErrorType<unknown>>(
  params: ListUserScheduledReportParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof listUserScheduledReport>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getListUserScheduledReportKey(params) : null))
  const swrFn = () => listUserScheduledReport(params, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const getUserScheduledReport = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<UserScheduledReportDto>(
    { url: `/v1/user-scheduled-reports/${id}`, method: 'GET' },
    options,
  )
}

export const getGetUserScheduledReportKey = (id: string) =>
  [`/v1/user-scheduled-reports/${id}`] as const

export type GetUserScheduledReportQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserScheduledReport>>
>
export type GetUserScheduledReportQueryError = ErrorType<void>

export const useGetUserScheduledReport = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof getUserScheduledReport>>,
      TError
    > & { swrKey?: Key; enabled?: boolean }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!id
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getGetUserScheduledReportKey(id) : null))
  const swrFn = () => getUserScheduledReport(id, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions,
  )

  return {
    swrKey,
    ...query,
  }
}
export const updateUserScheduledReport = (
  id: string,
  updateUserScheduledReportDto: UpdateUserScheduledReportDto,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<UserScheduledReportDto>(
    {
      url: `/v1/user-scheduled-reports/${id}`,
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      data: updateUserScheduledReportDto,
    },
    options,
  )
}

export const getUpdateUserScheduledReportMutationFetcher = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (
    _: Key,
    { arg }: { arg: UpdateUserScheduledReportDto },
  ): Promise<UserScheduledReportDto> => {
    return updateUserScheduledReport(id, arg, options)
  }
}
export const getUpdateUserScheduledReportMutationKey = (id: string) =>
  [`/v1/user-scheduled-reports/${id}`] as const

export type UpdateUserScheduledReportMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateUserScheduledReport>>
>
export type UpdateUserScheduledReportMutationError = ErrorType<void>

export const useUpdateUserScheduledReport = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof updateUserScheduledReport>>,
      TError,
      Key,
      UpdateUserScheduledReportDto,
      Awaited<ReturnType<typeof updateUserScheduledReport>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey =
    swrOptions?.swrKey ?? getUpdateUserScheduledReportMutationKey(id)
  const swrFn = getUpdateUserScheduledReportMutationFetcher(id, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
export const deleteUserScheduledReport = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return customAxiosInstance<void>(
    { url: `/v1/user-scheduled-reports/${id}`, method: 'DELETE' },
    options,
  )
}

export const getDeleteUserScheduledReportMutationFetcher = (
  id: string,
  options?: SecondParameter<typeof customAxiosInstance>,
) => {
  return (_: Key, __: { arg: Arguments }): Promise<void> => {
    return deleteUserScheduledReport(id, options)
  }
}
export const getDeleteUserScheduledReportMutationKey = (id: string) =>
  [`/v1/user-scheduled-reports/${id}`] as const

export type DeleteUserScheduledReportMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteUserScheduledReport>>
>
export type DeleteUserScheduledReportMutationError = ErrorType<void>

export const useDeleteUserScheduledReport = <TError = ErrorType<void>>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof deleteUserScheduledReport>>,
      TError,
      Key,
      Arguments,
      Awaited<ReturnType<typeof deleteUserScheduledReport>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof customAxiosInstance>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey =
    swrOptions?.swrKey ?? getDeleteUserScheduledReportMutationKey(id)
  const swrFn = getDeleteUserScheduledReportMutationFetcher(id, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, swrOptions)

  return {
    swrKey,
    ...query,
  }
}
