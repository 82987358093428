export enum InvoiceStatus {
  PROCESSING = 'PROCESSING',
  READY_FOR_RECEIVING = 'READY_FOR_RECEIVING',
  RECEIVED = 'RECEIVED',
  APPROVED = 'APPROVED',
  DONE = 'DONE',
}

export type OCRWorkflowStatus =
  | 'REJECTED'
  | 'IN_PROGRESS'
  | 'UNDER_HUMAN_REVIEW'
  | 'SUCCESS'

export enum InvoiceAccountingStatus {
  PENDING = 'PENDING',
  SYNCED = 'SYNCED',
  OPEN = 'OPEN',
  PAID = 'PAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  VOID = 'VOID',
}

export type Invoice = {
  accounting_status: InvoiceAccountingStatus
  due_date: string
  file_urls?: Array<string>
  id: string
  invoice_number: string | null
  invoice_total: number
  issue_date: string
  issues_total: number
  processing_status: OCRWorkflowStatus
  received_at: string
  status: InvoiceStatus
  store_id: number
  store_name: string
  vendor_id: number
  vendor_name: string
  departments: Array<{
    status: 'PENDING' | 'APPROVED'
    id: number
    name: string
  }>
}

export enum InvoiceProcessEventType {
  OCR_APPROVED = 'OCR_APPROVED',
  OCR_PREDICTED = 'OCR_PREDICTED',
  AP_WORKFLOW_V1_STARTED = 'AP_WORKFLOW_V1_STARTED',
  DEPARTMENT_APPROVED = 'DEPARTMENT_APPROVED',
  SKIP_RECEIVING = 'SKIP_RECEIVING',
  READY_FOR_RECEIVING = 'READY_FOR_RECEIVING',
  RECEIVING_REPORT_SUBMITTED = 'RECEIVING_REPORT_SUBMITTED',
  INVOICE_CREATED = 'INVOICE_CREATED',
  CODAT_INVOICE_SYNC_START = 'CODAT_INVOICE_SYNC_START',
  CODAT_INVOICE_SYNC_COMPLETE = 'CODAT_INVOICE_SYNC_COMPLETE',
  AP_INVOICE_STATUS_OPEN = 'AP_INVOICE_STATUS_OPEN',
  AP_INVOICE_STATUS_PAID = 'AP_INVOICE_STATUS_PAID',
  AP_INVOICE_STATUS_PARTIALLY_PAID = 'AP_INVOICE_STATUS_PARTIALLY_PAID',
  AP_INVOICE_STATUS_VOID = 'AP_INVOICE_STATUS_VOID',
  DETECTED_COSTS_CREATED = 'DETECTED_COSTS_CREATED',
  OCR_FILE_UPLOAD_REJECTED = 'OCR_FILE_UPLOAD_REJECTED',
  OCR_FILE_UPLOAD_CREATED = 'OCR_FILE_UPLOAD_CREATED',
  HEADER_MAPPING_FAILED = 'HEADER_MAPPING_FAILED',
  HEADER_MAPPING_SUCCESS = 'HEADER_MAPPING_SUCCESS',
  LINE_ITEM_MAPPING_FAILED = 'LINE_ITEM_MAPPING_FAILED',
  LINE_ITEM_MAPPING_SUCCESS = 'LINE_ITEM_MAPPING_SUCCESS',
  LINE_ITEM_INITIALIZATION_SUCCESS = 'LINE_ITEM_INITIALIZATION_SUCCESS',
}

export type InvoiceProcessEvent = {
  created_at: string
  event_type: InvoiceProcessEventType
  invoice_id: string
  user: { display_name: string; id: number }
}
