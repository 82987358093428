import { PosBannerConfigurationFlags } from '@vori/dashboard-constants'
import { PosBannerConfigurationDto } from '@vori/dashboard-rest-next/schemas'
import { AppGetCurrentUserQuery } from '@vori/gql-dashboard'

export type UserDataFromGQL = NonNullable<AppGetCurrentUserQuery['me']>['user']

export type CurrentUserState = {
  isAuthenticating: boolean
  isLoggedIn: boolean
}

export type CurrentUserData = UserDataFromGQL & {
  companyID: string
  companyName: string
  enabledPosBannerConfigurationFlags: PosBannerConfigurationFlags[]
  isBuyer: boolean
  isDepartmentManager: boolean
  isRetailUser: boolean
  isSalesRep: boolean
  isStoreAdmin: boolean
  isStoreAP: boolean
  isVendorAdmin: boolean
  isVendorUser: boolean
  isVoriAdmin: boolean
}

export type CurrentUserMetadataStore = {
  address: string
  id: string
  imageURL: string | null
  name: string
  timeZone: string
  supportsWic: boolean
}

export type CurrentUserMetadataBanner = {
  id: string
  imageURL: string | null
  name: string
}
interface CurrentUserMetadataBase {
  banner: CurrentUserMetadataBanner | null
  selectedStoreName: string | null
  stores: Array<CurrentUserMetadataStore>
  timeZone: string
  selectedStoreSupportsWic: boolean
}
export interface CurrentVendorMetadata extends CurrentUserMetadataBase {
  selectedStoreID: string | null
}

export interface CurrentGrocerMetadata extends CurrentUserMetadataBase {
  selectedStoreID: string
}

export type CurrentUserMetadata = CurrentVendorMetadata | CurrentGrocerMetadata

export type CurrentUser = {
  authToken: string | null
  data: CurrentUserData
  metadata: CurrentUserMetadata
  state: CurrentUserState
}

export type CurrentUserReducerState = {
  authenticationError: Error | null
  user: CurrentUser
}

export enum AuthErrorSource {
  AUTH_STATE_CHANGED = 'auth_state_changed',
  LOGIN = 'login',
  LOGOUT = 'logout',
}

export type CurrentUserReducerAction =
  | { type: 'userV2/authenticationStarted' }
  | {
      type: 'userV2/authenticated'
      payload: {
        authToken: string
        data: CurrentUserData | UserDataFromGQL
      }
    }
  | { type: 'userV2/unauthenticated' }
  | { type: 'userV2/authenticationFailed'; payload: { error: Error } }
  | {
      type: 'userV2/storeSelected'
      payload: {
        selectedStoreID: string
        selectedStoreName: string
        selectedStoreTimeZone: string
        selectedStoreSupportsWic: boolean
      }
    }
  | {
      type: 'userV2/featureFlagsUpdated'
      payload: {
        featureFlags: Array<string>
      }
    }
  | {
      type: 'userV2/posBannerConfigurationsUpdated'
      payload: {
        posBannerConfigurations: PosBannerConfigurationDto[]
      }
    }

export type CurrentUserStateContextValue = CurrentUserReducerState

export type CurrentUserDispatchContextValue =
  React.Dispatch<CurrentUserReducerAction>
