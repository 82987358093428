import compact from 'lodash/compact'
import React from 'react'

import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubItem,
} from '@vori/dashboard-components/shadcn/ui/sidebar'

import {
  Collapsible,
  CollapsibleTrigger,
  CollapsibleContent,
} from '@vori/dashboard-components/shadcn/ui/collapsible'

import {
  ChevronRightIcon,
  FileBarChartIcon,
  GiftIcon,
  PackageIcon,
  ReceiptTextIcon,
  ShoppingBasketIcon,
  TruckIcon,
} from 'lucide-react'

import { SidebarMenuLink } from '@vori/dashboard-components/SidebarMenuLink'

import { FeatureFlags } from '@vori/dashboard-constants'
import { routes } from '@vori/dashboard-routes/retail'
import { useFeatureConfig } from '@vori/dashboard-hooks/useFeatureConfig'

function RetailNavigation(): JSX.Element | null {
  const hasLoyaltyAccess = useFeatureConfig(
    FeatureFlags.WEB_FEATURE_RETAIL_LOYALTY,
  )

  const hasCampaignsAccess = useFeatureConfig(
    FeatureFlags.WEB_FEATURE_RETAIL_CAMPAIGNS,
  )

  const hasPromotionsAccess = useFeatureConfig(
    FeatureFlags.WEB_FEATURE_RETAIL_PROMOTIONS,
  )

  const hasBackOfficeProductsAccess = useFeatureConfig(
    FeatureFlags.WEB_NAVIGATION_BACK_OFFICE_PRODUCTS,
  )

  const hasReportingAccess = useFeatureConfig(
    FeatureFlags.WEB_NAVIGATION_REPORTING,
  )

  const hasBackOfficeAdminAccess = useFeatureConfig(
    FeatureFlags.WEB_NAVIGATION_BACK_OFFICE_ADMIN,
  )

  const hasTransactionsAccess = useFeatureConfig(
    FeatureFlags.WEB_FEATURE_TRANSACTION_VIEWER,
  )

  const hasLegacyAccess = useFeatureConfig(FeatureFlags.WEB_NAVIGATION_LEGACY)
  const hasTillsAccess = useFeatureConfig(FeatureFlags.WEB_FEATURE_RETAIL_TILLS)
  const hasUsersAccess = useFeatureConfig(FeatureFlags.WEB_FEATURE_USERS)

  const groupedNavigationLinks = [
    {
      title: 'Reports',
      icon: FileBarChartIcon,
      items: hasReportingAccess
        ? [
            { title: 'Overview', url: routes.reporting.root.path, exact: true },
            { title: 'End of Day', url: routes.reporting.endOfDay.path },
            {
              title: 'Sales Overview',
              url: routes.reporting.sales.generate({
                section: 'sales',
                subsection: 'overview',
              }),
            },
            {
              title: 'End of Month',
              url: routes.reporting.sales.generate({
                section: 'sales',
                subsection: 'end-of-month',
              }),
            },
            {
              title: 'Item Sales',
              url: routes.reporting.sales.generate({
                section: 'sales',
                subsection: 'items-sales',
              }),
            },
          ]
        : [],
    },
    {
      title: 'Point of Sale',
      icon: ShoppingBasketIcon,
      items: [
        ...(hasTransactionsAccess
          ? [{ title: 'Orders', url: routes.transactions.root.path }]
          : []),
        ...(hasTillsAccess
          ? [{ title: 'Tills', url: routes.tills.root.path }]
          : []),
        ...(hasUsersAccess
          ? [{ title: 'Cashiers', url: routes.users.root.path }]
          : []),
      ],
    },
    {
      title: 'Products',
      icon: PackageIcon,
      items: compact([
        ...(hasBackOfficeProductsAccess
          ? [{ title: 'Product Catalog', url: routes.products.root.path }]
          : []),
        ...(hasPromotionsAccess
          ? [{ title: 'Promotions', url: routes.promotions.root.path }]
          : []),
        ...(hasBackOfficeAdminAccess
          ? [
              { title: 'Departments', url: routes.departments.list.path },
              { title: 'Taxes', url: routes.taxes.root.path },
              { title: 'Item Modifiers', url: routes.itemModifiers.root.path },
            ]
          : []),
      ]),
    },
    {
      title: 'Vendors & Ordering',
      icon: TruckIcon,
      items: compact([
        ...(hasBackOfficeAdminAccess
          ? [{ title: 'Vendors', url: routes.vendors.root.path }]
          : []),
        ...(hasLegacyAccess
          ? [{ title: 'Order Settings', url: routes.stores.root.path }]
          : []),
        ...(hasLegacyAccess
          ? [{ title: 'Order Guides', url: routes.search.root.path }]
          : []),
        ...(hasLegacyAccess
          ? [{ title: 'Vendor Catalogs', url: routes.catalogs.root.path }]
          : []),
      ]),
    },
    {
      title: 'Loyalty & Marketing',
      icon: GiftIcon,
      items: [
        ...(hasLoyaltyAccess
          ? [
              {
                title: 'Loyalty',
                url: routes.loyalty.root.path,
              },
            ]
          : []),
        ...(hasCampaignsAccess
          ? [{ title: 'Campaigns', url: routes.campaigns.root.path }]
          : []),
      ],
    },
  ]

  return (
    <SidebarGroup>
      <SidebarGroupLabel>Main</SidebarGroupLabel>
      <SidebarMenu>
        {groupedNavigationLinks
          .filter(({ items }) => Boolean(items.length))
          .map((group) => (
            <React.Fragment key={group.title}>
              <Collapsible asChild className="group/collapsible">
                <SidebarMenuItem>
                  <CollapsibleTrigger asChild>
                    <SidebarMenuButton tooltip={group.title}>
                      {group.icon && <group.icon />}
                      <span>{group.title}</span>
                      <ChevronRightIcon className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                    </SidebarMenuButton>
                  </CollapsibleTrigger>
                  <CollapsibleContent>
                    <SidebarMenuSub>
                      {group.items.map((subItem) => (
                        <SidebarMenuSubItem key={subItem.title}>
                          <SidebarMenuLink
                            to={subItem.url}
                            exact={subItem.exact}
                          >
                            <span>{subItem.title}</span>
                          </SidebarMenuLink>
                        </SidebarMenuSubItem>
                      ))}
                    </SidebarMenuSub>
                  </CollapsibleContent>
                </SidebarMenuItem>
              </Collapsible>
              {group.title === 'Products' && (
                <SidebarMenuLink to={routes.invoices.root.path}>
                  <ReceiptTextIcon /> <span>Invoices</span>
                </SidebarMenuLink>
              )}
            </React.Fragment>
          ))}
      </SidebarMenu>
    </SidebarGroup>
  )
}

export { RetailNavigation }
