import {
  FeatureFlags,
  GATED_FEATURE_IDs,
  PosBannerConfigurationFlags,
} from '@vori/dashboard-constants'
import { ENV_PROD } from '@vori/dashboard-env'

import { useCurrentUserState } from '@vori/dashboard-hooks/useCurrentUser'

const POS_BANNER_CONFIGURATION_GATED_FEATURE_IDs = Object.values(
  PosBannerConfigurationFlags,
)

export function useFeatureConfig(
  featureID: PosBannerConfigurationFlags | FeatureFlags,
): boolean {
  const { user } = useCurrentUserState()

  if (
    !GATED_FEATURE_IDs.includes(featureID as FeatureFlags) &&
    !POS_BANNER_CONFIGURATION_GATED_FEATURE_IDs.includes(
      featureID as PosBannerConfigurationFlags,
    )
  ) {
    if (ENV_PROD) {
      return false
    }

    // Throw errors in dev only
    throw new Error(
      `Unable to fetch status for Feature "${featureID}". Make sure to add the ID to the GATED_FEATURES list.`,
    )
  }

  const enabledFeatures = (
    user.data.canSeeFeatures?.enabledFeatures || []
  ).concat(user.data.enabledPosBannerConfigurationFlags)

  return Boolean(enabledFeatures?.includes(featureID))
}
