export enum LoyaltyTab {
  MEMBERS = 'members',
  REWARDS = 'rewards',
  INSIGHTS = 'insights',
  GIFT_CARDS = 'gift-cards',
  HOUSE_ACCOUNTS = 'house-accounts',
}

export enum ItemSavingsRewardType {
  FREE_ITEM = 'FREE_ITEM',
  ITEM_DISCOUNT = 'ITEM_DISCOUNT',
  ITEM_PERCENT_OFF = 'ITEM_PERCENT_OFF',
}
