import dayjs from 'dayjs'
import React from 'react'

import {
  ArrowRightLeftIcon,
  CheckIcon,
  ChevronsUpDownIcon,
  StoreIcon,
} from 'lucide-react'

import {
  SidebarGroup,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from '@vori/dashboard-components/shadcn/ui/sidebar'

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from '@vori/dashboard-components/shadcn/ui/dropdown-menu'

import {
  cacheUserStoreID,
  CurrentUserMetadataStore,
  useCurrentUserDispatch,
  useCurrentUserState,
} from '@vori/dashboard-hooks/useCurrentUser'

import { AppSidebarUser } from '@vori/dashboard-components/AppSidebarUser'
import { FeatureFlags } from '@vori/dashboard-constants'
import { useFeatureConfig } from '@vori/dashboard-hooks/useFeatureConfig'
import { toTimeZoneAbbreviation } from '@vori/dashboard-utils/format'
import { getPlatformSpecificControlKeyString } from '@vori/dashboard-utils/getPlatformSpecificControlKeyString'

import { ENV_NEW_APP_URL } from '@vori/dashboard-env'

export function StoreSelector(): JSX.Element | null {
  const { user } = useCurrentUserState()
  const { isMobile } = useSidebar()
  const dispatchUserEvent = useCurrentUserDispatch()

  const selectedStore = React.useMemo<CurrentUserMetadataStore | null>(
    () =>
      user.metadata.stores.find(
        ({ id }) => user.metadata.selectedStoreID === id,
      ) || null,
    [user.metadata.selectedStoreID, user.metadata.stores],
  )

  const canUseStoreSelector = useFeatureConfig(
    FeatureFlags.WEB_FEATURE_STORE_SELECTOR,
  )

  if (!selectedStore) {
    return null
  }

  const storeAddress = [
    selectedStore.address,
    `(${toTimeZoneAbbreviation(selectedStore.timeZone)})`,
  ]

  if (!canUseStoreSelector) {
    return (
      <SidebarGroup>
        <div className="peer/menu-button flex w-full items-center gap-2 overflow-hidden rounded-md p-2 text-left outline-none ring-sidebar-ring transition-[width,height,padding] focus-visible:ring-2 group-has-[[data-sidebar=menu-action]]/menu-item:pr-8 aria-disabled:pointer-events-none aria-disabled:opacity-50 data-[active=true]:bg-sidebar-accent data-[active=true]:font-medium data-[active=true]:text-sidebar-accent-foreground group-data-[collapsible=icon]:!size-8 [&>span:last-child]:truncate [&>svg]:size-4 [&>svg]:shrink-0 h-12 text-sm group-data-[collapsible=icon]:!p-0 data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground justify-center">
          <AppSidebarUser
            displayName={selectedStore.name}
            icon={StoreIcon}
            subTitle={storeAddress}
            value={selectedStore.id}
          />
        </div>
      </SidebarGroup>
    )
  }

  return (
    <SidebarGroup>
      <SidebarMenu>
        <SidebarMenuItem>
          <DropdownMenu>
            <DropdownMenuTrigger disabled={!canUseStoreSelector} asChild>
              <SidebarMenuButton
                size="lg"
                className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
              >
                <AppSidebarUser
                  displayName={selectedStore.name}
                  icon={StoreIcon}
                  subTitle={storeAddress}
                  value={selectedStore.id}
                />
                <ChevronsUpDownIcon className="ml-auto" />
              </SidebarMenuButton>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
              align="start"
              side={isMobile ? 'bottom' : 'right'}
              sideOffset={4}
            >
              <DropdownMenuLabel className="text-sm text-foreground">
                Select a view
              </DropdownMenuLabel>
              <DropdownMenuSeparator />
              {user.metadata.stores.map((store, index) => (
                <React.Fragment key={store.id}>
                  <DropdownMenuItem
                    onClick={() => {
                      dayjs.tz.setDefault(store.timeZone)
                      cacheUserStoreID(store.id)

                      dispatchUserEvent({
                        type: 'userV2/storeSelected',
                        payload: {
                          selectedStoreID: store.id,
                          selectedStoreName: store.name,
                          selectedStoreTimeZone: store.timeZone,
                          selectedStoreSupportsWic: store.supportsWic,
                        },
                      })
                    }}
                    className="gap-2 p-2"
                  >
                    <div className="flex size-6 items-center justify-center rounded-sm">
                      <StoreIcon className="size-4 shrink-0" />
                    </div>
                    {store.name}
                    {store.id === selectedStore.id ? (
                      <CheckIcon className="size-4 shrink-0 opacity-60" />
                    ) : (
                      <DropdownMenuShortcut>
                        {getPlatformSpecificControlKeyString()}
                        {index + 1}
                      </DropdownMenuShortcut>
                    )}
                  </DropdownMenuItem>
                  <DropdownMenuSeparator />
                </React.Fragment>
              ))}
              <DropdownMenuItem
                onClick={() => {
                  window.open(ENV_NEW_APP_URL, '_blank')
                }}
                className="gap-2 p-2 text-accent-foreground"
              >
                <div className="flex size-6 items-center justify-center rounded-sm">
                  <ArrowRightLeftIcon className="size-4 shrink-0" />
                </div>
                Switch to the New App
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </SidebarMenuItem>
      </SidebarMenu>
    </SidebarGroup>
  )
}
