import {
  Auth,
  browserLocalPersistence,
  indexedDBLocalPersistence,
  initializeAuth,
} from 'firebase/auth'

import {
  FirebaseApp,
  FirebaseOptions,
  getApps,
  initializeApp,
} from 'firebase/app'

import {
  ENV_FIREBASE_API_KEY,
  ENV_FIREBASE_APP_ID,
  ENV_FIREBASE_AUTH_DOMAIN,
  ENV_FIREBASE_DATABASE_URL,
  ENV_FIREBASE_MEASUREMENT_ID,
  ENV_FIREBASE_MESSAGING_SENDER_ID,
  ENV_FIREBASE_PROJECT_ID,
  ENV_FIREBASE_STORAGE_BUCKET,
} from '@vori/dashboard-env'

export let firebaseApp: FirebaseApp | null
export let firebaseAuth: Auth | null

export const firebaseConfig: FirebaseOptions = {
  apiKey: ENV_FIREBASE_API_KEY,
  appId: ENV_FIREBASE_APP_ID,
  authDomain: ENV_FIREBASE_AUTH_DOMAIN,
  databaseURL: ENV_FIREBASE_DATABASE_URL,
  measurementId: ENV_FIREBASE_MEASUREMENT_ID,
  messagingSenderId: ENV_FIREBASE_MESSAGING_SENDER_ID,
  projectId: ENV_FIREBASE_PROJECT_ID,
  storageBucket: ENV_FIREBASE_STORAGE_BUCKET,
}

function initializeFirebaseApp() {
  if (!getApps().length) {
    firebaseApp = initializeApp(firebaseConfig)
  } else {
    firebaseApp =
      getApps()?.find(
        ({ options }) => options?.appId === firebaseConfig.appId,
      ) || null
  }
}

export function getFirebaseApp(): FirebaseApp | null {
  if (firebaseApp) {
    return firebaseApp
  }

  initializeFirebaseApp()

  return firebaseApp
}

export function getFirebaseAuth(): Auth | null {
  if (firebaseApp && firebaseAuth) {
    return firebaseAuth
  }

  if (!firebaseApp) {
    initializeFirebaseApp()
  }

  if (firebaseApp && !firebaseAuth) {
    firebaseAuth = initializeAuth(firebaseApp, {
      persistence: location.host.startsWith('vori-dev')
        ? indexedDBLocalPersistence
        : browserLocalPersistence,
    })
  }

  return firebaseAuth
}
