import {
  ExtractRouteParams,
  generatePath,
  match,
  matchPath,
  RouteProps,
  useParams,
  useRouteMatch,
} from 'react-router'

/**
 * @todo Add automatic params typings for `match` and `useRouteMatch`
 */
type RouteUtil<T extends string> = {
  generate: (params?: ExtractRouteParams<T>) => string
  generateWithURLSearchParams: (
    search: string | URLSearchParams | Record<string, string> | string[][],
    params?: ExtractRouteParams<T>,
  ) => string
  match: (
    props?: Omit<RouteProps, 'path'>,
  ) => match<Record<string, string>> | null
  parentTitle?: string
  path: T
  title: string
  useRouteMatch:
    | (() => match<Record<string, string>> | null)
    | ((
        props?: Omit<RouteProps, 'path'>,
      ) => match<Record<string, string>> | null)
  useParams<U = string>(): ExtractRouteParams<T, U>
}

function createRouteUtil<T extends string>(
  path: T,
  title: string,
  parentTitle?: string,
): RouteUtil<T> {
  return {
    parentTitle,
    path,
    title,
    match: (props) => matchPath(window.location.pathname, { ...props, path }),
    generate: (params) => generatePath(path, params),
    generateWithURLSearchParams: (search, params) =>
      `${generatePath(path, params)}?${new URLSearchParams(search)}`,
    useRouteMatch: (props) => useRouteMatch({ ...(props || {}), path }),
    useParams<U = string>() {
      return useParams() as ExtractRouteParams<T, U>
    },
  }
}

export { createRouteUtil }
export type { RouteUtil }
