import { LoyaltyBonusType, PromotionStatus } from '@vori/dashboard-rest'
import { RouteUtil, createRouteUtil } from '@vori/dashboard-utils'

import { HouseAccountStateChangeType } from '@vori/dashboard-rest-next/schemas'
import { IncentiveType } from './pages/campaigns/types/incentive'
import { LoyaltyTab } from './pages/loyalty/types/types'

import {
  OldReportingTab,
  REPORTING_SECTIONS,
  ReportingTab,
} from './pages/reporting/types'

import { InvoiceCreationViewMode, InvoiceStatus } from './types'

const reportingRoutes: Record<
  ReportingTab,
  RouteUtil<`/retail/reporting/:section(${string})/:subsection(${string})`>
> = {} as Record<
  ReportingTab,
  RouteUtil<`/retail/reporting/:section(${string})/:subsection(${string})`>
>

Object.entries(REPORTING_SECTIONS).forEach(([sectionID, subsection]) => {
  reportingRoutes[sectionID as ReportingTab] = createRouteUtil(
    `/retail/reporting/:section(${sectionID})/:subsection(${Object.keys(subsection).join('|')})`,
    'Reporting',
    'Reports',
  )
})

const routes = {
  catalogs: {
    /**
     * Lists all vendor catalogs.
     */
    root: createRouteUtil(
      '/retail/catalogs',
      'Vendor Catalogs',
      'Vendors & Ordering',
    ),
    /**
     * Displays a specific vendor catalog
     */
    detail: createRouteUtil(
      '/retail/catalogs/:vendorID',
      'Vendor Catalog',
      'Vendors & Ordering',
    ),
    /**
     * Displays a specific vendor product
     */
    productDetail: createRouteUtil(
      '/retail/catalogs/:vendorID/product/:productID',
      'Product Details',
      'Vendors & Ordering',
    ),
    /**
     * Displays a specific vendor product's order guides
     */
    productDetailOrderGuides: createRouteUtil(
      '/retail/catalogs/:vendorID/product/:productID/order-guides',
      'Product Order Guides',
      'Vendors & Ordering',
    ),
    /**
     * Displays a specific vendor product's pricing
     */
    productDetailPricing: createRouteUtil(
      '/retail/catalogs/:vendorID/product/:productID/pricing',
      'Product Pricing',
      'Vendors & Ordering',
    ),
    /**
     * Displays a specific vendor product's status
     */
    productDetailStatus: createRouteUtil(
      '/retail/catalogs/:vendorID/product/:productID/status',
      'Product Status',
      'Vendors & Ordering',
    ),
  },

  stores: {
    /**
     * Lists all stores.
     */
    root: createRouteUtil(
      '/retail/stores',
      'Order Settings',
      'Vendors & Ordering',
    ),
    /**
     * Lists all buyers from a specific store
     */
    buyers: createRouteUtil(
      '/retail/stores/:storeID/buyers',
      'Store Buyers',
      'Vendors & Ordering',
    ),
    /**
     * Displays form to create a new department for a specific store.
     */
    createBuyer: createRouteUtil(
      '/retail/stores/:storeID/buyers/new',
      'Create Buyer',
      'Vendors & Ordering',
    ),
    /**
     * Displays a specific store
     */
    detail: createRouteUtil(
      '/retail/stores/:storeID',
      'Store',
      'Vendors & Ordering',
    ),
    /**
     * Lists all orders from a specific store
     */
    orders: createRouteUtil(
      '/retail/stores/:id/orders',
      'Store Orders',
      'Vendors & Ordering',
    ),
    /**
     * Lists all vendors from a specific store
     */
    vendors: createRouteUtil(
      '/retail/stores/:storeID/vendors',
      'Store Vendors',
      'Vendors & Ordering',
    ),
    /**
     * Lists all departments for a specific store.
     */
    departments: createRouteUtil(
      '/retail/stores/:storeID/departments',
      'Store Departments',
      'Vendors & Ordering',
    ),
    /**
     * Displays form to create a new department for a specific store.
     */
    createDepartment: createRouteUtil(
      '/retail/stores/:storeID/departments/new',
      'Create Store Department',
      'Vendors & Ordering',
    ),
  },

  buyers: {
    /**
     * Displays a specific buyer
     */
    detail: createRouteUtil(
      '/retail/stores/:storeID/buyer/:buyerID',
      'Buyer',
      'Vendors & Ordering',
    ),
    /**
     * Displays a specific buyer's order guides
     */
    orderGuides: createRouteUtil(
      '/retail/stores/:storeID/buyer/:buyerID/order-guides',
      'Buyer Order Guides',
      'Vendors & Ordering',
    ),
    /**
     * Root orders route (catch-all); Will defer to
     * router for redirection.
     */
    ordersRoot: createRouteUtil(
      '/retail/stores/:storeID/buyer/:buyerID/orders',
      'Buyer Orders',
      'Vendors & Ordering',
    ),
    /**
     * Lists all orders from a specific buyer.
     */
    orders: createRouteUtil(
      '/retail/stores/:storeID/buyer/:buyerID/orders/:filter',
      'Buyer Orders',
      'Vendors & Ordering',
    ),
    /**
     * Lists all sent orders from a specific buyer.
     */
    ordersSent: createRouteUtil(
      '/retail/stores/:storeID/buyer/:buyerID/orders/sent',
      'Buyer Orders',
      'Vendors & Ordering',
    ),
    /**
     * Lists all confirmed orders from a specific buyer.
     */
    ordersConfirmed: createRouteUtil(
      '/retail/stores/:storeID/buyer/:buyerID/orders/confirmed',
      'Buyer Orders',
      'Vendors & Ordering',
    ),
  },

  profile: {
    /**
     * Lists all store-related account settings.
     */
    root: createRouteUtil('/retail/profile', 'Profile'),
  },

  home: {
    /**
     * Renders home page which lists numerous banner stats.
     */
    root: createRouteUtil('/retail/home', 'Home'),
  },

  itemModifiers: {
    root: createRouteUtil(
      '/retail/item-modifiers',
      'Item Modifiers',
      'Products',
    ),
    create: createRouteUtil(
      '/retail/item-modifiers/new',
      'Create Item Modifier',
      'Products',
    ),
    update: createRouteUtil(
      '/retail/item-modifiers/:itemID/update',
      'Item Modifier Details',
      'Products',
    ),
  },

  departments: {
    /**
     * Displays a specific department.
     */
    root: createRouteUtil(
      '/retail/stores/:storeID/departments/:departmentID',
      'Store Department',
      'Products',
    ),

    /**
     * Lists all vendors for a specific department.
     */
    vendors: createRouteUtil(
      '/retail/stores/:storeID/departments/:departmentID/vendors',
      'Store Department Vendors',
      'Products',
    ),
    /**
     * Lists all departments.
     */
    list: createRouteUtil('/departments', 'Store Departments', 'Products'),
    /**
     * Displays the add department modal
     */
    create: createRouteUtil(
      '/departments/new',
      'New Store Departments',
      'Products',
    ),
    /**
     * Displays the add sub-department modal
     */
    createSubDepartment: createRouteUtil(
      '/departments/sub/new',
      'New Store Sub-Departments',
      'Products',
    ),
    /**
     * Displays the update department modal
     */
    update: createRouteUtil(
      '/departments/:departmentID/update',
      'Update Store Departments',
      'Products',
    ),
    /**
     * Lists a specific VSDA for a specific department.
     */
    vendorDetail: createRouteUtil(
      '/retail/stores/:storeID/departments/:departmentID/vendors/:vendorID',
      'Store Department Vendor',
      'Products',
    ),
    /**
     * Lists a specific VSDA's update form for a specific department.
     */
    vendorDetailUpdate: createRouteUtil(
      '/retail/stores/:storeID/departments/:departmentID/vendors/:vendorID/update',
      'Update Store Department Vendor',
      'Products',
    ),
    /**
     * Lists vendor products on a specific VSDA for a specific department.
     */
    vendorDetailProducts: createRouteUtil(
      '/retail/stores/:storeID/departments/:departmentID/vendors/:vendorID/products',
      'Store Department Products',
      'Products',
    ),
    /**
     * Lists all orders for a specific department.
     */
    orders: createRouteUtil(
      '/retail/stores/:storeID/departments/:departmentID/orders',
      'Store Department Orders',
      'Products',
    ),
    /**
     * Lists a specific order for a specific department.
     */
    orderDetail: createRouteUtil(
      '/retail/stores/:storeID/departments/:departmentID/orders/:orderID',
      'Store Department Order',
      'Products',
    ),
  },

  search: {
    /**
     * Search for specific products across all vendors.
     */
    root: createRouteUtil('/retail/search', 'Vendors & Ordering'),
    /**
     * Displays a specific vendor product
     */
    productDetail: createRouteUtil(
      '/retail/search/:vendorID/product/:productID',
      'Vendors & Ordering',
    ),
    /**
     * Displays a specific vendor product's order guides
     */
    productDetailOrderGuides: createRouteUtil(
      '/retail/search/:vendorID/product/:productID/order-guides',
      'Vendors & Ordering',
    ),
    /**
     * Displays a specific vendor product's pricing
     */
    productDetailPricing: createRouteUtil(
      '/retail/search/:vendorID/product/:productID/pricing',
      'Vendors & Ordering',
    ),
    /**
     * Displays a specific vendor product's status
     */
    productDetailStatus: createRouteUtil(
      '/retail/search/:vendorID/product/:productID/status',
      'Vendors & Ordering',
    ),
  },

  invoices: {
    root: createRouteUtil('/retail/invoices', 'Invoices', 'Invoices'),

    status: createRouteUtil(
      `/retail/invoices/status/:status(${Object.values(InvoiceStatus).join(
        '|',
      )})`,
      'Invoices',
      'Invoices',
    ),

    newInvoice: createRouteUtil(
      `/retail/invoices/new/:mode(${Object.values(InvoiceCreationViewMode).join(
        '|',
      )})`,
      'New Invoice',
      'Invoices',
    ),

    newInvoiceAddVendor: createRouteUtil(
      `/retail/invoices/new/:mode(${Object.values(InvoiceCreationViewMode).join(
        '|',
      )})/vendor/add`,
      'Add Vendor',
      'Invoices',
    ),

    invoiceDetail: createRouteUtil(
      '/retail/invoices/:invoiceID',
      'Invoice Detail',
      'Invoices',
    ),

    invoiceOriginalFile: createRouteUtil(
      '/retail/invoices/:invoiceID/file',
      'Invoice File',
      'Invoices',
    ),

    invoiceProductDetail: createRouteUtil(
      '/retail/invoices/:invoiceID/product/:productID',
      'Product Detail',
      'Invoices',
    ),

    invoiceHistory: createRouteUtil(
      '/retail/invoices/:invoiceID/history',
      'Invoice History',
      'Invoices',
    ),

    editInvoice: createRouteUtil(
      '/retail/invoices/:invoiceID/edit',
      'Edit Invoice',
      'Invoices',
    ),

    editInvoiceAddVendor: createRouteUtil(
      '/retail/invoices/:invoiceID/edit/vendor/add',
      'Add Vendor',
      'Invoices',
    ),
  },

  integrations: {
    root: createRouteUtil('/retail/integrations', 'Integrations'),
    integrationType: createRouteUtil(
      '/retail/integrations/:integrationType',
      'Integrations',
    ),
  },

  products: {
    root: createRouteUtil('/retail/products', 'Product Catalog', 'Products'),
    detail: createRouteUtil(
      '/retail/products/:productID',
      'Product Details',
      'Products',
    ),
    update: createRouteUtil(
      '/retail/products/:productID/update',
      'Update Product',
      'Products',
    ),
    bulkUpdate: createRouteUtil(
      '/retail/products/bulk-update',
      'Bulk Update Products',
      'Products',
    ),
    create: createRouteUtil(
      '/retail/products/new',
      'Create Product',
      'Products',
    ),
    upload: createRouteUtil(
      '/retail/products/upload',
      'Upload Products',
      'Products',
    ),
  },

  unauthorizedLineItems: {
    root: createRouteUtil('/retail/unauthorized-items', 'Unauthorized Items'),
  },

  taxes: {
    root: createRouteUtil('/taxes', 'Taxes', 'Products'),
    create: createRouteUtil('/taxes/new', 'Create Tax', 'Products'),
    update: createRouteUtil('/taxes/:taxId/update', 'Update Tax', 'Products'),
  },

  eslUpload: {
    root: createRouteUtil('/retail/esl-upload', 'ESL Upload'),
  },

  vendors: {
    root: createRouteUtil('/vendors', 'Vendors', 'Vendors & Ordering'),
    create: createRouteUtil(
      '/vendors/new',
      'Create Vendor',
      'Vendors & Ordering',
    ),
    update: createRouteUtil(
      '/vendors/:vendorID/update',
      'Update Vendor',
      'Vendors & Ordering',
    ),
    products: createRouteUtil(
      '/vendors/:vendorID/products',
      'Vendor Products',
      'Vendors & Ordering',
    ),
    settings: createRouteUtil(
      '/vendors/:vendorID/settings',
      'Vendor Settings',
      'Vendors & Ordering',
    ),
    create_product: createRouteUtil(
      '/vendors/:vendorID/products/create',
      'New Vendor Product',
      'Vendors & Ordering',
    ),
    update_product: createRouteUtil(
      '/vendors/:vendorID/products/update/:productID',
      'Edit Vendor Product',
      'Vendors & Ordering',
    ),
    update_retail_product: createRouteUtil(
      '/vendors/:vendorID/retail_product/:productID',
      'Edit Retail Product',
      'Vendors & Ordering',
    ),
    merge_products: createRouteUtil(
      '/vendors/:vendorID/products/merge/:productID/:productID2',
      'Merge Vendor Products',
      'Vendors & Ordering',
    ),
  },

  frontOffice: {
    root: createRouteUtil('/retail/front-office', 'Front Office'),
  },

  promotions: {
    root: createRouteUtil('/retail/promotions', 'Promotions', 'Products'),

    status: createRouteUtil(
      `/retail/promotions/status/:status(All|${Object.values(
        PromotionStatus,
      ).join('|')})`,
      'Promotions',
      'Products',
    ),

    import: createRouteUtil(
      '/retail/promotions/import',
      'Import Promotion',
      'Products',
    ),

    create: createRouteUtil(
      '/retail/promotions/create',
      'New Promotion',
      'Products',
    ),

    detail: createRouteUtil(
      '/retail/promotions/detail/:promotionID',
      'Promotion Details',
      'Products',
    ),

    view: createRouteUtil(
      '/retail/promotions/view/:promotionID',
      'Promotion Details',
      'Products',
    ),

    update: createRouteUtil(
      '/retail/promotions/update/:promotionID',
      'Update Promotion',
      'Products',
    ),

    review: createRouteUtil(
      '/retail/promotions/review/:promotionID',
      'Review Promotion',
      'Products',
    ),

    notFound: createRouteUtil(
      '/retail/promotions/not-found',
      'Promotion Not Found',
      'Products',
    ),
  },

  loyalty: {
    root: createRouteUtil(
      '/retail/loyalty',
      'Loyalty Program',
      'Loyalty & Marketing',
    ),

    tab: createRouteUtil(
      `/retail/loyalty/:loyaltyTab(${Object.values(LoyaltyTab).join('|')})`,
      `Loyalty Program`,
      'Loyalty & Marketing',
    ),

    rootInTab: createRouteUtil(
      '/retail/loyalty/rewards',
      'Rewards',
      'Loyalty & Marketing',
    ),

    detail: createRouteUtil(
      '/retail/loyalty/rewards/:loyaltyProgramID',
      'Reward Details',
      'Loyalty & Marketing',
    ),

    createReward: createRouteUtil(
      '/retail/loyalty/rewards/:loyaltyProgramID/rewards/new',
      'Create Reward',
      'Loyalty & Marketing',
    ),

    updateReward: createRouteUtil(
      '/retail/loyalty/rewards/:loyaltyProgramID/rewards/:rewardID',
      'Update Reward',
      'Loyalty & Marketing',
    ),
  },

  members: {
    root: createRouteUtil(
      '/retail/loyalty/members',
      'Members',
      'Loyalty & Marketing',
    ),

    list: createRouteUtil(
      '/retail/loyalty/members',
      'Members',
      'Loyalty & Marketing',
    ),

    detail: createRouteUtil(
      '/retail/loyalty/members/:shopperID',
      'Member Overview',
      'Loyalty & Marketing',
    ),

    update: createRouteUtil(
      '/retail/loyalty/members/:shopperID/update',
      'Update Member',
      'Loyalty & Marketing',
    ),

    updatePointBalance: createRouteUtil(
      '/retail/loyalty/members/:shopperID/update-point-balance',
      'Update Member Point Balance',
      'Loyalty & Marketing',
    ),

    notFound: createRouteUtil(
      '/retail/loyalty/members/not-found',
      'Member Not Found',
      'Loyalty & Marketing',
    ),
  },

  giftCards: {
    root: createRouteUtil(
      '/retail/loyalty/gift-cards',
      'Gift Cards',
      'Loyalty & Marketing',
    ),
    create: createRouteUtil(
      '/retail/loyalty/gift-cards/create',
      'New Gift Card',
      'Loyalty & Marketing',
    ),
    detail: createRouteUtil(
      '/retail/loyalty/gift-cards/:giftCardID',
      'Gift Card',
      'Loyalty & Marketing',
    ),
    deactivate: createRouteUtil(
      '/retail/loyalty/gift-cards/:giftCardID/deactivate',
      'Deactivate Gift Card',
      'Loyalty & Marketing',
    ),
    update: createRouteUtil(
      '/retail/loyalty/gift-cards/:giftCardID/update',
      'Update Gift Card',
      'Loyalty & Marketing',
    ),
    updateDetails: createRouteUtil(
      '/retail/loyalty/gift-cards/:giftCardID/update-details',
      'Update Gift Card Details',
      'Loyalty & Marketing',
    ),
    notFound: createRouteUtil(
      '/retail/loyalty/gift-cards/not-found',
      'Gift Card Not Found',
      'Loyalty & Marketing',
    ),
  },

  houseAccounts: {
    root: createRouteUtil(
      '/retail/loyalty/house-accounts',
      'House Accounts',
      'Loyalty & Marketing',
    ),
    changeStatus: createRouteUtil(
      `/retail/loyalty/house-accounts/:houseAccountID/change-status/:stateChangeType(${Object.values(HouseAccountStateChangeType).join('|')})`,
      'Loyalty | Change House Account Status',
    ),
    create: createRouteUtil(
      '/retail/loyalty/house-accounts/create',
      'New House Account',
      'Loyalty & Marketing',
    ),
    detail: createRouteUtil(
      '/retail/loyalty/house-accounts/:houseAccountID',
      'House Account',
      'Loyalty & Marketing',
    ),
    notFound: createRouteUtil(
      '/retail/loyalty/house-accounts/not-found',
      'House Account Not Found',
      'Loyalty & Marketing',
    ),
    updateBalance: createRouteUtil(
      '/retail/loyalty/house-accounts/:houseAccountID/update-balance',
      'Update House Account Balance',
      'Loyalty & Marketing',
    ),
    updateDetails: createRouteUtil(
      '/retail/loyalty/house-accounts/:houseAccountID/update-details',
      'Update House Account Details',
      'Loyalty & Marketing',
    ),
  },

  campaigns: {
    root: createRouteUtil(
      '/retail/campaigns',
      'Campaigns',
      'Loyalty & Marketing',
    ),

    list: createRouteUtil(
      '/retail/campaigns/:loyaltyProgramID',
      'Campaigns',
      'Loyalty & Marketing',
    ),

    create: createRouteUtil(
      '/retail/campaigns/:loyaltyProgramID/create',
      'Create Campaign Message',
      'Loyalty & Marketing',
    ),

    update: createRouteUtil(
      '/retail/campaigns/:loyaltyProgramID/update/:campaignID',
      'Update Campaign Message',
      'Loyalty & Marketing',
    ),

    detail: createRouteUtil(
      '/retail/campaigns/:loyaltyProgramID/detail/:campaignID',
      'Custom Campaign Message',
      'Loyalty & Marketing',
    ),

    updateBasketLiftIncentive: createRouteUtil(
      `/retail/campaigns/:loyaltyProgramID/incentives/${IncentiveType.BasketLift}`,
      'Update Campaign Incentive',
      'Loyalty & Marketing',
    ),

    updateVisitNudgeIncentive: createRouteUtil(
      `/retail/campaigns/:loyaltyProgramID/incentives/${IncentiveType.StoreVisitNudge}`,
      'Update Campaign Incentive',
      'Loyalty & Marketing',
    ),

    createBirthdayBonus: createRouteUtil(
      `/retail/campaigns/:loyaltyProgramID/bonuses/${LoyaltyBonusType.Birthday}/create`,
      'Birthday Bonus Campaign',
      'Loyalty & Marketing',
    ),

    createAnniversaryBonus: createRouteUtil(
      `/retail/campaigns/:loyaltyProgramID/bonuses/${LoyaltyBonusType.Anniversary}/create`,
      'Anniversary Bonus Campaign',
      'Loyalty & Marketing',
    ),

    createSignupBonus: createRouteUtil(
      `/retail/campaigns/:loyaltyProgramID/bonuses/${LoyaltyBonusType.Signup}/create`,
      'Signup Bonus Campaign',
      'Loyalty & Marketing',
    ),

    updateBonus: createRouteUtil(
      `/retail/campaigns/:loyaltyProgramID/bonuses/:bonusID`,
      'Update Campaign Bonus',
      'Loyalty & Marketing',
    ),
  },

  files: {
    root: createRouteUtil('/retail/files', 'Files'),
  },

  revisions: {
    root: createRouteUtil('/retail/revisions', 'Revisions'),
  },

  reporting: {
    root: createRouteUtil('/retail/reporting', 'Overview', 'Reports'),
    ...reportingRoutes,
    endOfDay: createRouteUtil(
      '/retail/reporting/end-of-day',
      'End of Day',
      'Reports',
    ),
    tab: createRouteUtil(
      `/retail/reporting/:reportingTab(${Object.values(OldReportingTab).join(
        '|',
      )})`,
      'Reporting',
      'Reports',
    ),
  },

  transactions: {
    root: createRouteUtil(
      '/retail/reporting/transactions',
      'Transactions',
      'Point of Sale',
    ),
    list: createRouteUtil(
      '/retail/reporting/transactions',
      'Transactions',
      'Point of Sale',
    ),
    detail: createRouteUtil(
      '/retail/reporting/transactions/:transactionID',
      'Transaction Details',
      'Point of Sale',
    ),
    lineItemDetail: createRouteUtil(
      '/retail/reporting/transactions/:transactionID/line-item/:lineItemID',
      'Transaction Item Details',
      'Point of Sale',
    ),
    notFound: createRouteUtil(
      '/retail/reporting/transactions/not-found',
      'Transaction Not Found',
      'Point of Sale',
    ),
  },

  tills: {
    root: createRouteUtil('/retail/reporting/tills', 'Tills', 'Point of Sale'),
    settings: createRouteUtil(
      '/retail/reporting/tills/settings',
      'Tills',
      'Point of Sale',
    ),
    detailOverview: createRouteUtil(
      '/retail/reporting/tills/:tillID/overview',
      'Till Overview',
      'Point of Sale',
    ),
    detailHistory: createRouteUtil(
      '/retail/reporting/tills/:tillID/history',
      'Till History',
      'Point of Sale',
    ),
    review: createRouteUtil(
      '/retail/reporting/tills/:tillID/review',
      'Till Review',
      'Point of Sale',
    ),
    notFound: createRouteUtil(
      '/retail/reporting/tills/not-found',
      'Till Not Found',
      'Point of Sale',
    ),
  },

  users: {
    root: createRouteUtil(
      '/retail/users',
      'Cashier Management',
      'Point of Sale',
    ),
    settings: createRouteUtil(
      '/retail/users/settings',
      'Settings',
      'Point of Sale',
    ),
    edit: createRouteUtil(
      '/retail/users/:userID/update',
      'Update User',
      'Point of Sale',
    ),
    create: createRouteUtil(
      '/retail/users/new',
      'Add New User',
      'Point of Sale',
    ),
    notFound: createRouteUtil(
      '/retail/users/not-found',
      'User Not Found',
      'Point of Sale',
    ),
  },
}

export { routes }
