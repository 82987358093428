import React from 'react'

import {
  BookOpenIcon,
  Building2Icon,
  HomeIcon,
  ReceiptIcon,
  TagIcon,
  UserCircleIcon,
} from 'lucide-react'

import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuItem,
} from '@vori/dashboard-components/shadcn/ui/sidebar'

import { routes } from '@vori/dashboard-routes/vendor'

import { SidebarMenuLink } from '@vori/dashboard-components/SidebarMenuLink'

const links = [
  {
    href: routes.home.root.path,
    icon: <HomeIcon />,
    label: 'Home',
  },
  {
    href: routes.promotions.root.path,
    icon: <TagIcon />,
    label: 'Promotions',
  },
  {
    href: `${routes.crm.root.path}?status=all`,
    icon: <Building2Icon />,
    label: 'Customers',
  },
  {
    href: routes.products.root.path,
    icon: <BookOpenIcon />,
    label: 'Catalog',
  },
  {
    href: routes.profile.root.path,
    icon: <UserCircleIcon />,
    label: 'Profile',
  },
  {
    href: routes.orders.needsAction.path,
    icon: <ReceiptIcon />,
    label: 'Orders',
  },
]

function VendorNavigation(): JSX.Element | null {
  return (
    <SidebarGroup>
      <SidebarGroupLabel>Main</SidebarGroupLabel>
      <SidebarMenu>
        {links.map((link) => (
          <SidebarMenuItem key={link.href}>
            <SidebarMenuLink to={link.href}>
              {link.icon}
              <span>{link.label}</span>
            </SidebarMenuLink>
          </SidebarMenuItem>
        ))}
      </SidebarMenu>
    </SidebarGroup>
  )
}

export { VendorNavigation }
