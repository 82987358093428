import React from 'react'
import { SidebarMenuButton } from '@vori/dashboard-components/shadcn/ui/sidebar'

import {
  NavLink,
  NavLinkProps,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'

function toPropAsStringOrUndefined(to: NavLinkProps['to']): string | undefined {
  return typeof to === 'object' ? to.pathname : to.toString()
}

export function SidebarMenuLink(
  props: React.PropsWithChildren<NavLinkProps>,
): JSX.Element {
  const location = useLocation()

  const match = useRouteMatch({
    exact: props.exact,
    path:
      typeof props.to === 'function'
        ? toPropAsStringOrUndefined(props.to(location))
        : toPropAsStringOrUndefined(props.to),
  })

  return (
    <SidebarMenuButton asChild isActive={Boolean(match)}>
      <NavLink {...props}>{props.children}</NavLink>
    </SidebarMenuButton>
  )
}
