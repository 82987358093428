import {
  createStoreVendorProduct,
  deleteStoreVendorProduct,
  deleteStoreVendorProducts,
  getStoreProduct,
  getStoreVendorProduct,
  listStoreVendorProducts,
  updateStoreVendorProduct,
} from '@vori/dashboard-rest-next/products/products'
import camelCase from 'lodash/camelCase'
import { StateCreator } from 'zustand'
import { StoreVendorProductDto } from '@vori/dashboard-rest-next/schemas/storeVendorProductDto'
import {
  CreateStoreVendorProductDto,
  StoreVendorDto,
  StoreProductDto,
  UpdateStoreVendorProductDto,
  StoreVendorProductDuplicateType,
} from '@vori/dashboard-rest-next/schemas'
import { captureException } from '@vori/dashboard-integrations/Sentry/utils'
import { StateActionStatus } from './storeTypesAndUtils'
import { getStoreVendor } from '@vori/dashboard-rest-next/store-vendors/store-vendors'
import Decimal from 'decimal.js'
import { addMilliseconds, compareAsc } from 'date-fns'

/**
 * Every 10 minutes, prune the SVP duplicate overrides
 */
const PRUNE_SVP_DUPLICATES_INTERVAL_LENGTH_IN_MS = 10 * 60 * 1000 // 10 minutes
/**
 * An SVP override is deemed stale if it was created at least an hour prior to pruning
 */
const STALE_SVP_OVERRIDE_LENGTH_IN_MS = 60 * 60 * 1000 // 1 hour

export type StoreVendorProductDuplicateOverrides = {
  /**
   * Keys: SVP ID; Values: { overrideTime: string representation of JS Date,  id: SVP ID }
   */
  ids: Map<string, { overrideTime: string; id: string }>

  /**
   * Keys: SVP Item Code; Values: { overrideTime: string representation of JS Date,  itemCode: SVP Item Code }
   */
  itemCodes: Map<string, { overrideTime: string; itemCode: string }>

  /**
   * Keys: SVP Description; Values: { overrideTime: string representation of JS Date,  description: SVP Description }
   */
  descriptions: Map<string, { overrideTime: string; description: string }>

  /**
   * Keys: SVP Store Product ID; Values: { overrideTime: string representation of JS Date,  storeProductID: SVP Store Product ID }
   */
  storeProductIDs: Map<string, { overrideTime: string; storeProductID: string }>
}

export type VendorProductPerCostOptions = 'case_cost' | 'lb_cost' | 'each_cost'
export type VendorProductUOM = 'CASE' | 'LB' | 'EACH'

export type UOM_OPTION = {
  id: VendorProductUOM
  label: string
  UOM: VendorProductPerCostOptions
}

export const UOM_OPTIONS: Record<string, UOM_OPTION> = {
  CASE: { id: 'CASE', label: 'per case', UOM: 'case_cost' },
  LB: { id: 'LB', label: 'per LB', UOM: 'lb_cost' },
  EACH: { id: 'EACH', label: 'per each', UOM: 'each_cost' },
}

export type ExpandedStoreVendorProduct = {
  unit_volume?: string | null
  unit_cost?: string | null
  cost?: string
  active_per_cost?: VendorProductUOM
  store_vendor_id?: string
  full_store_product?: null | StoreProductDto
} & StoreVendorProductDto

export type VendorsSlice = {
  loading: boolean
  success: boolean
  error: unknown | null
  deleteVendorProductsStatus: StateActionStatus
  bulkDeleteVendorProductsStatus: StateActionStatus
  getStoreProductStatus: StateActionStatus
  fetchStoreProductByIdStatus: StateActionStatus
  activeAbortController: null | AbortController
  activeStoreVendorId: string | null
  activeStoreVendor: StoreVendorDto | null
  activeStoreVendorProductId: string | null
  storeVendorProducts: Map<string, StoreVendorProductDto[]>
  pendingStoreVendorProduct: Partial<ExpandedStoreVendorProduct>
  currentStoreVendorsPageIndex: number
  currentStoreVendorProductsPageIndex: number
  storeVendorProductDuplicateOverridesV2: StoreVendorProductDuplicateOverrides
  pruneStoreVendorProductDuplicateOverrides: () => void
  addStoreVendorProductDuplicateOverrides: (overrides: {
    id?: string
    itemCode?: string
    description?: string
    storeProductID?: string
  }) => void
  resetSaveState: () => void
  updatePendingStoreVendorProduct: (
    updatedInfo: Partial<ExpandedStoreVendorProduct>,
    changedFields?: string[],
    perCostChange?: VendorProductUOM,
  ) => void
  fetchAndSetActiveStoreProductById: (
    productId: string,
    replacePendingSVP?: boolean,
  ) => void
  fetchActiveStoreVendor: () => void
  setActiveVendorProduct: (
    vendorProduct?: ExpandedStoreVendorProduct | null,
  ) => void
  setActiveVendorId: (incomingVendorId: string | null) => void
  saveModifiedStoreVendorProduct: (
    saveData: Partial<ExpandedStoreVendorProduct>,
    isEditingExistingProduct?: boolean,
    callback?: () => void,
  ) => void
  deleteStoreVendorProduct: (
    storeVendorProductId: string,
    callback?: () => void,
  ) => void
  bulkDeleteStoreVendorProducts: (
    storeVendorProductIds: string[],
    callback?: () => void,
  ) => void
}

export const createVendorsSlice: StateCreator<VendorsSlice> = (set, get) => {
  // Ensure optimistic SVP duplicate overrides are cleared every hour
  let clearDuplicateOverridesIntervalID: NodeJS.Timeout | null = null
  const startClearDuplicateOverridesInterval = () => {
    if (!clearDuplicateOverridesIntervalID) {
      clearDuplicateOverridesIntervalID = setInterval(() => {
        get().pruneStoreVendorProductDuplicateOverrides()
      }, PRUNE_SVP_DUPLICATES_INTERVAL_LENGTH_IN_MS)
    }
  }
  startClearDuplicateOverridesInterval()

  return {
    loading: false,
    success: false,
    error: null,
    deleteVendorProductsStatus: { error: null, success: false, loading: false },
    bulkDeleteVendorProductsStatus: {
      error: null,
      success: false,
      loading: false,
    },
    getStoreProductStatus: { error: null, success: false, loading: false },
    fetchStoreProductByIdStatus: {
      error: null,
      success: false,
      loading: false,
    },
    activeAbortController: null,
    activeStoreVendorId: null,
    activeStoreVendor: null,
    activeStoreVendorProductId: null,
    storeVendorProducts: new Map(),
    pendingStoreVendorProduct: {},
    currentStoreVendorsPageIndex: 1,
    currentStoreVendorProductsPageIndex: 1,
    storeVendorProductDuplicateOverridesV2: {
      ids: new Map(),
      itemCodes: new Map(),
      descriptions: new Map(),
      storeProductIDs: new Map(),
    },
    pruneStoreVendorProductDuplicateOverrides: () => {
      // Remove any overrides that were added more than an hour ago
      const now = new Date()
      const currentOverrides = get().storeVendorProductDuplicateOverridesV2
      const isOverrideActive = (override: { overrideTime: string }) =>
        compareAsc(
          addMilliseconds(
            new Date(override.overrideTime),
            STALE_SVP_OVERRIDE_LENGTH_IN_MS,
          ),
          now,
        ) >= 0

      set({
        storeVendorProductDuplicateOverridesV2: {
          ids: new Map(
            Array.from(currentOverrides.ids.values())
              .filter(isOverrideActive)
              .map((override) => [override.id, override]),
          ),
          itemCodes: new Map(
            Array.from(currentOverrides.itemCodes.values())
              .filter(isOverrideActive)
              .map((override) => [override.itemCode, override]),
          ),
          descriptions: new Map(
            Array.from(currentOverrides.descriptions.values())
              .filter(isOverrideActive)
              .map((override) => [override.description, override]),
          ),
          storeProductIDs: new Map(
            Array.from(currentOverrides.storeProductIDs.values())
              .filter(isOverrideActive)
              .map((override) => [override.storeProductID, override]),
          ),
        },
      })
    },
    addStoreVendorProductDuplicateOverrides: (overrides: {
      id?: string
      itemCode?: string
      description?: string
      storeProductID?: string
    }) => {
      const now = new Date()
      const currentOverrides = get().storeVendorProductDuplicateOverridesV2
      const currentOverridesCopy: StoreVendorProductDuplicateOverrides = {
        ids: new Map(currentOverrides.ids),
        itemCodes: new Map(currentOverrides.itemCodes),
        descriptions: new Map(currentOverrides.descriptions),
        storeProductIDs: new Map(currentOverrides.storeProductIDs),
      }
      if (overrides.id) {
        currentOverridesCopy.ids.set(overrides.id, {
          overrideTime: now.toString(),
          id: overrides.id,
        })
      }
      if (overrides.itemCode) {
        currentOverridesCopy.itemCodes.set(overrides.itemCode, {
          overrideTime: now.toString(),
          itemCode: overrides.itemCode,
        })
      }
      if (overrides.description) {
        currentOverridesCopy.descriptions.set(overrides.description, {
          overrideTime: now.toString(),
          description: overrides.description,
        })
      }
      if (overrides.storeProductID) {
        currentOverridesCopy.storeProductIDs.set(overrides.storeProductID, {
          overrideTime: now.toString(),
          storeProductID: overrides.storeProductID,
        })
      }

      set({
        storeVendorProductDuplicateOverridesV2: currentOverridesCopy,
      })
    },
    resetSaveState: () => {
      set({
        loading: false,
        success: false,
      })
    },
    setActiveVendorProduct: async (vendorProduct = null) => {
      if (!vendorProduct) {
        set({
          activeStoreVendorProductId: null,
          pendingStoreVendorProduct: {},
        })
      } else {
        set({
          activeStoreVendorProductId: vendorProduct.id,
        })

        // early optimistic update of the pending product
        // the fetch might take a while in some cases and we don't want to
        // completely block partial data update unecessarily
        get().updatePendingStoreVendorProduct(
          vendorProduct || {},
          [],
          getPopulatedUOMType(vendorProduct),
        )

        if (vendorProduct.store_product_id !== null) {
          try {
            set({
              getStoreProductStatus: {
                error: null,
                success: false,
                loading: true,
              },
            })
            const vendorProductData = await getStoreProduct(
              vendorProduct.store_product_id,
            )
            vendorProduct.full_store_product = vendorProductData
          } catch (error) {
            set({
              getStoreProductStatus: {
                error: error as Error,
                success: false,
                loading: false,
              },
            })
          } finally {
            set({
              getStoreProductStatus: {
                error: null,
                success: false,
                loading: false,
              },
            })
          }
        }
        get().updatePendingStoreVendorProduct(
          vendorProduct || {},
          [],
          getPopulatedUOMType(vendorProduct),
        )
      }
    },
    setActiveVendorId: (incomingStoreVendorId = null) => {
      set({
        activeStoreVendorId: incomingStoreVendorId,
      })
    },
    fetchActiveStoreVendor: async () => {
      const activeStoreVendorId = get().activeStoreVendorId
      if (!activeStoreVendorId) {
        set({ activeStoreVendor: null })
        return
      }

      try {
        set({ loading: true })

        const storeVendorDto = await getStoreVendor(activeStoreVendorId)
        set({
          loading: false,
          error: null,
          activeAbortController: null,
          activeStoreVendor: storeVendorDto,
        })
      } catch (error) {
        set({
          error,
          loading: false,
          activeAbortController: null,
        })
      }
    },
    fetchAndSetActiveStoreProductById: async (
      productId,
      replacePendingSVP = false,
    ) => {
      try {
        const product = await getStoreVendorProduct(productId)
        set({
          fetchStoreProductByIdStatus: {
            error: null,
            loading: false,
            success: false,
          },
        })
        if (replacePendingSVP) {
          get().setActiveVendorProduct(product)
        }
      } catch (error) {
        set({
          fetchStoreProductByIdStatus: {
            error: error as Error,
            loading: false,
            success: false,
          },
        })
      }
    },
    updatePendingStoreVendorProduct: (
      updatedInfo,
      changedFields,
      perCostChange,
    ) => {
      let currentPending = { ...get().pendingStoreVendorProduct }

      if (changedFields && changedFields.length) {
        changedFields.forEach((fieldName) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          currentPending[fieldName] = updatedInfo[fieldName]
        })
      } else {
        currentPending = { ...currentPending, ...updatedInfo }
      }

      currentPending.active_per_cost = perCostChange
        ? perCostChange
        : currentPending.active_per_cost || 'CASE'

      switch (currentPending.active_per_cost) {
        case 'CASE':
          currentPending.cost = currentPending.case_cost || '0'
          break
        case 'EACH':
          currentPending.cost = updatedInfo.each_cost || '0'
          break
        case 'LB':
          currentPending.cost = updatedInfo.lb_cost || '0'
          break
      }

      currentPending.cost = new Decimal(currentPending.cost).toFixed(2)

      set({
        pendingStoreVendorProduct: currentPending,
      })
    },
    saveModifiedStoreVendorProduct: async (
      saveData,
      isEditingExistingProduct = false,
      callback,
    ) => {
      const updateContent = saveData || get().pendingStoreVendorProduct
      if (!isEditingExistingProduct) {
        const newProductDefinition =
          createSaveDataFromPendingItem<CreateStoreVendorProductDto>(
            updateContent,
          )

        set({
          loading: true,
          activeAbortController: null,
        })

        try {
          await createStoreVendorProduct(newProductDefinition)
          set({
            success: true,
          })
          if (callback) {
            callback()
          }
        } catch (error) {
          set({
            error,
            success: false,
            loading: false,
            activeAbortController: null,
          })
          captureException(
            (scope) => {
              scope.setContext('createStoreVendorProduct failed', {
                errorInfo: error,
              })
              return error as Error
            },
            {
              sentryTag: 'stateManagementCreateStoreVendorProduct',
            },
          )
        } finally {
          set({
            success: false,
            loading: false,
            error: null,
          })
        }
      } else {
        const fetchExistingStoreVendorProduct = async (
          storeVendorProductID: string,
        ): Promise<StoreVendorProductDto | null> => {
          try {
            return await getStoreVendorProduct(storeVendorProductID)
          } catch {
            return null
          }
        }

        try {
          const originalStoreVendorProduct =
            await fetchExistingStoreVendorProduct(updateContent.id!)
          const updateDto: UpdateStoreVendorProductDto =
            createSaveDataFromPendingItem(updateContent)
          await updateStoreVendorProduct(updateContent.id!, updateDto)

          set({
            success: true,
          })

          // If the SVP being updated is marked as a duplicate, we optimistically override
          // the duplication designation for any fields the user modified. The backend doesn't
          // refresh its duplication classifications in real-time
          const updatedSVPDuplicateOverrides: {
            itemCode?: string
            description?: string
            storeProductID?: string
          } = {}
          const originalSVPDuplicateOverrides: {
            itemCode?: string
            description?: string
            storeProductID?: string
          } = {}
          originalStoreVendorProduct?.duplicates?.forEach((duplicate) => {
            if (
              duplicate.type ===
                StoreVendorProductDuplicateType.DUPLICATE_DESCRIPTION &&
              originalStoreVendorProduct.description &&
              updateDto.description !== undefined &&
              originalStoreVendorProduct.description !== updateDto.description
            ) {
              originalSVPDuplicateOverrides.description =
                originalStoreVendorProduct.description
              if (updateDto.description) {
                updatedSVPDuplicateOverrides.description = updateDto.description
              }
            }
            if (
              duplicate.type ===
                StoreVendorProductDuplicateType.DUPLICATE_ITEM_CODE &&
              originalStoreVendorProduct.item_code &&
              updateDto.itemCode !== undefined &&
              originalStoreVendorProduct.item_code !== updateDto.itemCode
            ) {
              originalSVPDuplicateOverrides.itemCode =
                originalStoreVendorProduct.item_code
              if (updateDto.itemCode) {
                updatedSVPDuplicateOverrides.itemCode = updateDto.itemCode
              }
            }
            if (
              duplicate.type ===
                StoreVendorProductDuplicateType.DUPLICATE_STORE_PRODUCT &&
              originalStoreVendorProduct.store_product_id &&
              updateDto.storeProductID !== undefined &&
              originalStoreVendorProduct.store_product_id !==
                updateDto.storeProductID
            ) {
              originalSVPDuplicateOverrides.storeProductID =
                originalStoreVendorProduct.store_product_id
              if (updateDto.storeProductID) {
                updatedSVPDuplicateOverrides.storeProductID =
                  updateDto.storeProductID
              }
            }
          })
          get().addStoreVendorProductDuplicateOverrides(
            updatedSVPDuplicateOverrides,
          )
          get().addStoreVendorProductDuplicateOverrides(
            originalSVPDuplicateOverrides,
          )

          if (callback) {
            callback()
          }
        } catch (error) {
          set({
            error,
            loading: false,
            success: false,
            activeAbortController: null,
          })
        } finally {
          set({
            success: false,
            loading: false,
            error: null,
          })
        }
      }
    },
    deleteStoreVendorProduct: async (storeVendorProductId, callback) => {
      const fetchExistingStoreVendorProduct = async (
        storeVendorProductID: string,
      ): Promise<StoreVendorProductDto | null> => {
        try {
          return await getStoreVendorProduct(storeVendorProductID)
        } catch {
          return null
        }
      }

      set({
        deleteVendorProductsStatus: {
          loading: true,
          success: false,
          error: null,
        },
      })

      try {
        const originalStoreVendorProduct =
          await fetchExistingStoreVendorProduct(storeVendorProductId)

        await deleteStoreVendorProduct(storeVendorProductId)
        set({
          deleteVendorProductsStatus: {
            loading: false,
            success: true,
            error: null,
          },
        })

        // If the SVP being removed was marked as a duplicate, we optimistically override
        // the duplication designation for any dupe fields. The backend doesn't refresh its
        // duplication classifications in real-time
        const svpDuplicateOverrides: {
          itemCode?: string
          description?: string
          storeProductID?: string
        } = {}
        originalStoreVendorProduct?.duplicates?.forEach((duplicate) => {
          if (
            duplicate.type ===
              StoreVendorProductDuplicateType.DUPLICATE_DESCRIPTION &&
            originalStoreVendorProduct.description
          ) {
            svpDuplicateOverrides.description =
              originalStoreVendorProduct.description
          }
          if (
            duplicate.type ===
              StoreVendorProductDuplicateType.DUPLICATE_ITEM_CODE &&
            originalStoreVendorProduct.item_code
          ) {
            svpDuplicateOverrides.itemCode =
              originalStoreVendorProduct.item_code
          }
          if (
            duplicate.type ===
              StoreVendorProductDuplicateType.DUPLICATE_STORE_PRODUCT &&
            originalStoreVendorProduct.store_product_id
          ) {
            svpDuplicateOverrides.storeProductID =
              originalStoreVendorProduct.store_product_id
          }
        })
        get().addStoreVendorProductDuplicateOverrides(svpDuplicateOverrides)

        if (callback) {
          callback()
        }
      } catch (error) {
        set({
          deleteVendorProductsStatus: {
            loading: false,
            success: false,
            error: error as Error,
          },
        })
        captureException(
          (scope) => {
            scope.setContext('deleteStoreVendorProduct failed', {
              errorInfo: error,
            })
            return error as Error
          },
          {
            sentryTag: 'stateManagementDeleteStoreVendorProduct',
          },
        )
      } finally {
        set({
          deleteVendorProductsStatus: {
            loading: false,
            success: false,
            error: null,
          },
        })
      }
    },
    bulkDeleteStoreVendorProducts: async (storeVendorProductIds, callback) => {
      const fetchExistingStoreVendorProducts = async (
        storeVendorProductIDs: string[],
      ): Promise<StoreVendorProductDto[]> => {
        try {
          const storeVendorID = get().activeStoreVendorId
          if (!storeVendorID) {
            return []
          }

          const { data: svps } = await listStoreVendorProducts({
            store_vendor_id: storeVendorID,
            state: JSON.stringify({
              rowGroupCols: [],
              valueCols: [],
              pivotCols: [],
              pivotMode: false,
              groupKeys: [],
              filterModel: {
                id: { filterType: 'set', values: storeVendorProductIDs },
              },
              sortModel: [],
            }),
          })
          return svps
        } catch {
          return []
        }
      }

      set({
        bulkDeleteVendorProductsStatus: {
          loading: true,
          success: false,
          error: null,
        },
      })

      try {
        const originalStoreVendorProducts =
          await fetchExistingStoreVendorProducts(storeVendorProductIds)

        await deleteStoreVendorProducts({ ids: storeVendorProductIds })
        set({
          bulkDeleteVendorProductsStatus: {
            loading: false,
            success: true,
            error: null,
          },
        })

        // If any SVP being removed was marked as a duplicate, we optimistically override
        // the duplication designation for any of its dupe fields. The backend doesn't refresh its
        // duplication classifications in real-time
        const svpDuplicateOverridesArray: Array<{
          itemCode?: string
          description?: string
          storeProductID?: string
        }> = []
        originalStoreVendorProducts.forEach((svp) => {
          const svpDuplicateOverrides: {
            itemCode?: string
            description?: string
            storeProductID?: string
          } = {}
          svp?.duplicates?.forEach((duplicate) => {
            if (
              duplicate.type ===
                StoreVendorProductDuplicateType.DUPLICATE_DESCRIPTION &&
              svp.description
            ) {
              svpDuplicateOverrides.description = svp.description
            }
            if (
              duplicate.type ===
                StoreVendorProductDuplicateType.DUPLICATE_ITEM_CODE &&
              svp.item_code
            ) {
              svpDuplicateOverrides.itemCode = svp.item_code
            }
            if (
              duplicate.type ===
                StoreVendorProductDuplicateType.DUPLICATE_STORE_PRODUCT &&
              svp.store_product_id
            ) {
              svpDuplicateOverrides.storeProductID = svp.store_product_id
            }
          })

          if (Object.keys(svpDuplicateOverrides).length > 0) {
            svpDuplicateOverridesArray.push(svpDuplicateOverrides)
          }
        })
        svpDuplicateOverridesArray.forEach((svpDuplicateOverrides) => {
          get().addStoreVendorProductDuplicateOverrides(svpDuplicateOverrides)
        })

        if (callback) {
          callback()
        }
      } catch (error) {
        set({
          bulkDeleteVendorProductsStatus: {
            loading: false,
            success: false,
            error: error as Error,
          },
        })
      } finally {
        set({
          bulkDeleteVendorProductsStatus: {
            loading: false,
            success: false,
            error: null,
          },
        })
      }
    },
  }
}

const createSaveDataFromPendingItem = <OutputType>(
  inputObj: Partial<ExpandedStoreVendorProduct>,
) => {
  const propList = [
    'barcode',
    'case_cost',
    'case_size',
    'description',
    'each_cost',
    'item_code',
    'lb_cost',
    'store_vendor_id',
    'store_product_id',
  ]

  const generatedObejct = propList.reduce((output, current) => {
    let currentConvertKey = camelCase(current)
    if (Object.prototype.hasOwnProperty.call(inputObj, current)) {
      if (current === 'store_product_id') {
        currentConvertKey = 'storeProductID'
      }
      if (current === 'store_vendor_id') {
        currentConvertKey = 'storeVendorID'
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      output[currentConvertKey] =
        inputObj[current as keyof Partial<ExpandedStoreVendorProduct>] === '' &&
        current !== 'item_code' &&
        current !== 'description'
          ? null
          : inputObj[current as keyof Partial<ExpandedStoreVendorProduct>]
    }

    /*
    NOTE: store_vendor_id is a derived property from thte store_vendor property
    so it makes sense to handle it as a special case here
    **/
    if (inputObj.store_vendor && current === 'store_vendor_id') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      output['storeVendorID'] = inputObj.store_vendor.id
    }
    return output
  }, {} as OutputType)
  return generatedObejct
}

export const getPopulatedUOMType = (
  product: ExpandedStoreVendorProduct,
): VendorProductUOM => {
  const mostRecentCostChange = findMostRecentCostChangeTimetamp(product)

  if (mostRecentCostChange) {
    let selectedUOMType: VendorProductUOM = 'CASE'

    switch (mostRecentCostChange) {
      case 'each_cost_start_time':
        selectedUOMType = 'EACH'
        break
      case 'lb_cost_start_time':
        selectedUOMType = 'LB'
        break
      case 'case_cost_start_time':
        selectedUOMType = 'CASE'
        break
    }

    return selectedUOMType
  }

  if (product.case_cost) {
    return 'CASE'
  }

  if (product.each_cost) {
    return 'EACH'
  }

  if (product.lb_cost) {
    return 'LB'
  }

  return 'CASE'
}

export const findMostRecentCostChangeTimetamp = (
  inputObject: Partial<ExpandedStoreVendorProduct>,
) => {
  const propertyKeys: (keyof ExpandedStoreVendorProduct)[] = [
    'case_cost_start_time',
    'each_cost_start_time',
    'lb_cost_start_time',
  ]
  let mostRecentTime: number | null = null
  let mostRecentKey: string | null = null

  propertyKeys.forEach((key) => {
    const timestamp = inputObject[key] as string | null

    if (
      timestamp &&
      timestamp !== null &&
      !isNaN(new Date(timestamp).getTime())
    ) {
      const date = new Date(timestamp)

      if (mostRecentTime === null || date.getTime() > mostRecentTime) {
        mostRecentTime = date.getTime()
        mostRecentKey = key
      }
    }
  })

  return mostRecentKey
}
