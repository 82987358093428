import React from 'react'

import { Separator } from '@vori/dashboard-components/shadcn/ui/separator'
import { SidebarTrigger } from '@vori/dashboard-components/shadcn/ui/sidebar'

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
} from '@vori/dashboard-components/shadcn/ui/breadcrumb'

import { useCurrentUserState } from '@vori/dashboard-hooks/useCurrentUser'
import { usePageTitle } from '@vori/dashboard-hooks/usePageTitle'

export function AppHeader(): JSX.Element | null {
  const { user } = useCurrentUserState()
  const { pageTitle, pageParentTitle } = usePageTitle()

  if (!user.state.isLoggedIn) {
    return null
  }

  return (
    <header className="flex h-16 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12">
      <div className="flex items-center gap-3 px-4">
        <SidebarTrigger />
        <Separator orientation="vertical" className="h-4" />
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem className="md:block">
              {pageParentTitle || pageTitle}
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>
    </header>
  )
}
