import { OfferBenefitType } from '@vori/dashboard-rest'

export enum UnitOfMeasure {
  GALLONS = 'GALLONS',
  GRAMS = 'GRAMS',
  KILOGRAMS = 'KILOGRAMS',
  LITERS = 'LITERS',
  OUNCES = 'OUNCES',
  PIECES = 'PIECES',
  PINTS = 'PINTS',
  POUNDS = 'POUNDS',
  QUARTS = 'QUARTS',
  INCHES = 'INCHES',
}

export enum StoreProductStatus {
  ACTIVE = 'ACTIVE',
  DISCONTINUED = 'DISCONTINUED',
  UNAVAILABLE = 'UNAVAILABLE',
}

export enum StoreProductBarcodeType {
  EAN_8 = 'EAN-8',
  EAN_8_NO_CHECK_DIGIT = 'EAN-8 Without Check Digit',
  EAN_13 = 'EAN-13',
  EAN_13_NO_CHECK_DIGIT = 'EAN-13 Without Check Digit',
  EAN_14 = 'EAN-14',
  EAN_14_NO_CHECK_DIGIT = 'EAN-14 Without Check Digit',
  PLU = 'PLU',
  UPC_A = 'UPC-A',
  UPC_A_NO_CHECK_DIGIT = 'UPC-A Without Check Digit',
  UPC_E = 'UPC-E',
  UPC_E_NO_CHECK_DIGIT = 'UPC-E Without Check Digit',
  UPC_SHIPPING_CONTAINER = 'UPC Shipping Container',
  TYPE_2_UPC = 'Type 2 UPC',
  TYPE_2_UPC_NO_CHECK_DIGIT = 'UPC-A Type 2 Without Check Digit',
}

export type StoreProduct = {
  search?: string | null
  active: boolean | null
  additional_barcodes: string[] | null
  barcode_type: string
  barcode: string | null
  print_physical_tag: boolean | null
  brand_string: string | null
  case_size: number | null
  case_upc: string | null
  category: string | null
  created_at: string
  deleted_at: string
  department_id: string
  department_name: string | null
  description: string | null
  disable_pos_sync: boolean | null
  ebt_enabled: boolean | null
  ecommerce_enabled: boolean | null
  esl_enabled: boolean | null
  id: string
  rawBarcode: string | null
  img_url: string | null
  is_tippable: boolean | null
  item_code: string | null
  label_last_printed_at: string
  last_pushed_to_pos_at: string
  long_description: string | null
  min_customer_age: string | null
  name: string | null
  pack_size: number | null
  pack_upc: string | null
  price_uom_id: string
  price_uom_mapping_id: string
  price_uom_mapping_name: string | null
  price_uom_name: string | null
  price: string | null
  primary_case_cost: number | null
  primary_case_size: string | null
  primary_case_upc: string | null
  primary_costs_cost: number | null
  primary_costs_id: string
  primary_costs_source: string | null
  primary_costs_start_time: string
  primary_costs_uom_id: string
  primary_costs_uom_mapping_id: string
  primary_costs_uom_mapping_name: string | null
  primary_costs_uom_name: string | null
  primary_description: string | null
  primary_pack_upc: string | null
  primary_unit_cost: number | null
  primary_unit_upc: string | null
  primary_unit_volume: string | null
  primary_vendor_id: string
  primary_vendor_name: string
  product_fields_last_updated_at: string
  prompt_for_quantity: boolean | null
  secondary_costs_cost: number | null
  secondary_costs_id: string
  secondary_costs_start_time: string
  secondary_costs_uom_id: string
  secondary_costs_uom_mapping_id: string
  secondary_costs_uom_mapping_name: string | null
  secondary_costs_uom_name: string | null
  secondary_costssource: string | null
  snap_enabled: boolean | null
  sold_by_weight: boolean | null
  start_time: string
  status: StoreProductStatus
  store_id: string
  store_name: string | null
  target_margin: number | null
  barcode_length: number | null
  tax_rate_ids: string[] | null
  variable_weight_ids: string[] | null
  item_modifier_ids: string[] | null
  tertiary_costs_cost: number | null
  tertiary_costs_id: string
  tertiary_costs_source: string | null
  tertiary_costs_start_time: string
  tertiary_costs_uom_id: string
  tertiary_costs_uom_mapping_id: string
  tertiary_costs_uom_mapping_name: string | null
  tertiary_costs_uom_name: string | null
  unit_cost: number | null
  unit_upc: string | null
  unit_volume_string: string | null
  upc: string | null
  updated_at: string
  variable_sale_price: boolean | null
  vendor_id: string | null
  unit_volume_uom_name: string | null
  unit_volume_quantity: string | null
  vendor_name: string | null
  wic_enabled: boolean | null
  promotion_benefit_type: OfferBenefitType | null
  promotion_benefit_value: string | null
  promotion_end_date: string | null
  promotion_id: string | null
  promotion_name: string | null
  promotion_start_date: string | null
  promotional_price: string | null
  offer_id: string | null
  cost?: number | null
  cost_uom: 'EACH' | 'CASE' | 'LB' | null
  cost_case_size: number | null
  cost_vendor: string | null
  active_vendor_id: string | null
  active_vendor_name: string | null
  current_margin: number | null
  manual_item: boolean | null
  retail_price_uom_id: string | null
  retail_price_uom_name: string | null
  inventory_total?: number | null
  country_of_origin: string | null
}
