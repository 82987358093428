import { getPlatform } from '@vori/web-utils'

export const getPlatformSpecificControlKeyString = () => {
  let controlKey = '\u2318'

  switch (getPlatform()) {
    case 'macos':
      controlKey = '\u2318'
      break
    case 'windows':
    default:
      controlKey = 'ctrl '
      break
  }
  return controlKey
}
