import {
  APLRevisionRowDTO,
  BackOfficeStoreProductView,
} from '@vori/dashboard-rest-next/schemas'
import { StoreEntity, StoreProduct } from '@vori/dashboard-types'
import { toFractional } from '@vori/dashboard-utils'
import Decimal from 'decimal.js'
import { v7 as uuid } from 'uuid'
import { ProductFormProps } from '../../product-detail/edit-product/types'
import { UOM } from '../../product-detail/utils'
import { calculateCostChangeRowMargin } from '../components/CostChanges/util/calculateCostChangeRowMargin'
import { STORE_DEFAULT_MARGIN } from '../constants'
import { CostChangeRow, CurrentRevisionRow, MarginChange } from '../types'
import { applyRoundingRule, toDecimal, toPriceAndCents } from './currency'
import { getDefaultUom } from './strings'

// A copy of this is used in `invoice.fixture.ts`.
// If you change this function, make sure to update the copy there as well
export const getCurrentRevisionRows = (
  lineItems: APLRevisionRowDTO[],
): CurrentRevisionRow[] => {
  return (
    (lineItems?.map((e) => ({
      id: e.id,
      state: e.state,
      associatedProduct: e.storeProduct,
      rowIndex: e.row_index,
      revisionId: e.revision_id,
      ...e.current,
    })) as CurrentRevisionRow[]) || []
  )
}

export const filterCostChanges = (
  costChanges: CurrentRevisionRow[],
): CurrentRevisionRow[] => {
  return (
    costChanges?.filter((e) => {
      if (e.svpDeleted) {
        return false
      }

      if (e.state?.acceptedMarginChange || e.state?.skipMarginChange)
        return true

      if (!e.hasCostChange) {
        return false
      }

      Decimal.set({ rounding: Decimal.ROUND_HALF_UP })

      const currEachCost = e.currEachCost ? toDecimal(e.currEachCost) : null
      const currLbCost = e.currLbCost ? toDecimal(e.currLbCost) : null
      const currCaseCost = e.currCaseCost ? toDecimal(e.currCaseCost) : null

      const isFirstTimeCost = !currLbCost && !currEachCost && !currCaseCost

      if (e.isUnauthorized && !e.state?.userDidAuthorize) {
        return false
      }

      if (!e.matchID) return false

      if (isFirstTimeCost) return true

      return true
    }) || []
  )
}

// A copy of this is used in `invoice.fixture.ts`.
// If you change this function, make sure to update the copy there as well
export const filterItemsToPrint = (
  lineItems: CurrentRevisionRow[],
): CurrentRevisionRow[] => {
  return (
    lineItems?.filter((e) => {
      if (e.state?.newProductID) {
        return true
      }

      return (
        e.matchID &&
        e.state?.newRetail &&
        e.state?.newRetail !== e.state?.prevRetail &&
        e?.state?.acceptedMarginChange
      )
    }) || []
  )
}

export const getAllLineItemValues = (lineItems: CurrentRevisionRow[]) => {
  const costChanges = filterCostChanges(lineItems)
  const itemsToPrint = filterItemsToPrint(lineItems)
  const newItems = lineItems.filter(
    (e) => e.isUnauthorized || e.state?.isUnauthorized,
  )
  return {
    costChanges,
    itemsToPrint,
    newItems,
    lineItems,
  }
}

export const isNumeric = (n: string): boolean => {
  return !isNaN(parseFloat(n)) && isFinite(Number(n))
}

export const getCalculatedData = (
  costChanges: CurrentRevisionRow[],
  stores: StoreEntity[],
  products?: BackOfficeStoreProductView[] | null,
): CostChangeRow[] => {
  return (
    (costChanges.map((costChange) => {
      const {
        eachCost,
        lbCost,
        currEachCost,
        soldByWeight,
        retailPrice,
        matchID,
      } = costChange

      const product = products?.find((p) => p.id === matchID)
      const targetMargin =
        costChange?.targetMargin ?? product?.target_margin ?? null
      const previousMargin = calculateCostChangeRowMargin(
        costChange as MarginChange,
        true,
      )
      const futureMargin = calculateCostChangeRowMargin(
        costChange as MarginChange,
      )

      const unitCostRaw = lbCost ?? eachCost ?? null
      if (unitCostRaw && !isNumeric(unitCostRaw)) {
        return {
          ...costChange,
          suggestedRetailOptions: [],
          newPrice: 0,
          eachCost,
          currEachCost,
          soldByWeight,
          retailPrice,
          previousMargin,
          futureMargin,
        }
      }
      const unitCost = unitCostRaw ? toDecimal(unitCostRaw) : null
      const retailPriceNumber =
        toDecimal(retailPrice)?.times(100)?.toDP(2).toNumber() || 0

      // ? GREENHILL - 4435
      // TODO - remove this exception when customer rounding rules can be set at the DB level
      const isRoundingDisabled = stores.find(
        (store) => store.serialID === '4435',
      )

      let suggestedRetail = unitCost
        ? unitCost.div(
            1 -
              (toDecimal(targetMargin || STORE_DEFAULT_MARGIN * 100 || 0)
                ?.div(100)
                .toNumber() as number),
          )
        : null

      if (!isRoundingDisabled && suggestedRetail) {
        suggestedRetail = applyRoundingRule(suggestedRetail)
      } else if (suggestedRetail) {
        suggestedRetail = suggestedRetail.times(100)
      }

      const suggestedRetailOptions = [
        { name: 'High', value: 0.4 },
        { name: 'Medium', value: 0.35 },
        { name: 'Low', value: 0.3 },
      ]
        .map(({ name, value }) =>
          unitCost && value
            ? {
                label: `${name} (${Number(value * 100).toFixed(2)}%)`,
                value: unitCost.div(
                  1 - (toDecimal(value)?.toNumber() as number),
                ),
              }
            : null,
        )
        .filter(Boolean)
        .filter((e) => e?.value.greaterThan(0))

      if (
        targetMargin &&
        unitCost &&
        unitCost.greaterThan(0) &&
        toDecimal(targetMargin)?.greaterThan(0)
      ) {
        suggestedRetailOptions.unshift({
          label: `Target Margin (${targetMargin}%)`,
          value: unitCost
            .div(1 - (toDecimal(targetMargin)?.div(100).toNumber() as number))
            .toDP(2),
        })
      }

      const suggestedOptionsWithRounding = suggestedRetailOptions.map((e) =>
        isRoundingDisabled
          ? {
              label: e?.label,
              value: Decimal.isDecimal(e?.value)
                ? toFractional(e?.value.toNumber())
                : null,
            }
          : {
              label: e?.label,
              value:
                Decimal.isDecimal(e?.value) && e?.value.greaterThan(0)
                  ? applyRoundingRule(e?.value).toNumber()
                  : null,
            },
      )

      const retailPriceIsHighest =
        suggestedRetail?.lessThan(retailPriceNumber) ||
        suggestedOptionsWithRounding.every((e) =>
          toDecimal(e?.value)?.lessThan(retailPriceNumber),
        )

      let newPrice = costChange?.state?.newRetail
        ? (toDecimal(costChange.state.newRetail as string)
            ?.times(100)
            .toNumber() as number)
        : (suggestedRetail && suggestedRetail.toNumber()) || ''

      if (retailPrice) {
        if (retailPriceIsHighest) {
          suggestedRetail = toDecimal(retailPrice)
          newPrice = suggestedRetail?.times(100).toNumber() as number

          suggestedOptionsWithRounding.unshift({
            label: 'Original Price',
            value: retailPriceNumber || 0,
          })
        } else {
          newPrice = suggestedRetail
            ? (suggestedRetail.toNumber() as number)
            : '0'
          suggestedOptionsWithRounding.push({
            label: 'Original Price',
            value: retailPriceNumber || 0,
          })
        }
      }

      return {
        ...costChange,
        suggestedRetailOptions: suggestedOptionsWithRounding,
        newPrice,
        eachCost,
        currEachCost,
        soldByWeight,
        retailPrice,
        previousMargin,
        futureMargin,
      }
    }) as CostChangeRow[]) || []
  )
}

export function getProductData(lineItem: CurrentRevisionRow | null) {
  if (lineItem) {
    const eachCost =
      lineItem.eachCost !== null && lineItem.eachCost !== undefined
        ? new Decimal(lineItem.eachCost).toNumber()
        : null
    const caseCost =
      lineItem.caseCost !== null && lineItem.caseCost !== undefined
        ? new Decimal(lineItem.caseCost).toNumber()
        : null
    const lbCost =
      lineItem.lbCost !== null && lineItem.lbCost !== undefined
        ? new Decimal(lineItem.lbCost).toNumber()
        : null
    return {
      department_id: lineItem.departmentID || undefined,
      department_name: lineItem.departmentName,
      item_code: lineItem.itemCode,
      barcode: lineItem.outputBarcode,
      name: lineItem.name || lineItem.svpDescription,
      storeDepartmentIDs: lineItem.departmentID ? [lineItem.departmentID] : [],
      readOnlyCosts: true,
      vendors: {
        costConfiguration: 'LATEST_COST',
        defaultCostConfiguration: 'LATEST_COST',
        costs: [
          {
            case_size: lineItem.caseSize
              ? new Decimal(lineItem.caseSize).toNumber()
              : null,
            cost:
              (lineItem.soldByWeight
                ? lbCost || caseCost || eachCost
                : caseCost || lbCost || eachCost) || 0,
            item_code: lineItem.itemCode || '',
            source_type: 'USER',
            start_time: lineItem.costStartTime || '',
            store_vendor_id: lineItem.vendorID || '',
            store_vendor_name: lineItem.vendorName || '',
            store_vendor_product_id: lineItem.svpID || '',
            uom_name: getDefaultUom(lineItem),
            vendor_product_cost_id: null,
          },
        ],
      },
    } as Partial<StoreProduct>
  }
  return ''
}

export const getNextProductData = (
  lineItem: CurrentRevisionRow | null,
): Omit<ProductFormProps, 'id'> | undefined => {
  const vendorCost =
    lineItem?.caseCost ?? lineItem?.eachCost ?? lineItem?.lbCost

  let vendorUomName: UOM = UOM.CASE
  if (lineItem?.caseCost) {
    vendorUomName = UOM.CASE
  } else if (lineItem?.eachCost) {
    vendorUomName = UOM.EACH
  } else if (lineItem?.lbCost) {
    vendorUomName = UOM.LB
  }
  const tempId = uuid()

  return {
    active_store_vendor_product_id: lineItem?.svpID ?? tempId,
    active: true,
    barcode: lineItem?.outputBarcode ?? '',
    brand_string: lineItem?.brandName ?? undefined,
    name: lineItem?.svpDescription ?? '',
    pack_size: lineItem?.packSize ? Number(lineItem?.packSize) : undefined,
    price: lineItem?.retailPrice ? lineItem?.retailPrice : '',
    department_id: lineItem?.departmentID ?? '',
    vendor_costs: [
      {
        cost: vendorCost ? toPriceAndCents(vendorCost) : undefined,
        description: lineItem?.svpDescription ?? '',
        item_code: lineItem?.itemCode ?? '',
        source: {
          type: 'USER',
        },
        start_time: lineItem?.costStartTime
          ? new Date(lineItem.costStartTime)
          : undefined,
        store_vendor_id: lineItem?.vendorID ?? '',
        store_vendor_product_id: lineItem?.svpID ?? tempId,
        store_vendor_name: lineItem?.vendorName ?? '',
        uom_name: vendorUomName,
        uom_conversion_parameters: {
          case_size: lineItem?.caseSize
            ? Number(lineItem?.caseSize)
            : undefined,
        },
      },
    ],
    read_only_costs: true,
    loyalty_enabled: true,
  }
}

export const revisionRowOptimisticUpdate = (
  data: Partial<CurrentRevisionRow>,
  currentData: APLRevisionRowDTO | undefined,
) => {
  return {
    state: {},
    revision_id: data.revisionId || '',
    current: currentData?.current || {},
    initial: currentData?.initial || {},
    operations: currentData?.operations || [],
    row_index: data.rowIndex || 0,
    row_key: '',
    validationFailures: {
      ...currentData?.validationFailures,
    },
    ...currentData,
    ...data,
    id: data.id || currentData?.id || '',
  }
}

export const getTagPrintedStatus = (
  product?: StoreProduct | BackOfficeStoreProductView,
) => {
  if (!product) {
    return false
  }

  // If product has ESL disabled and physical tag disabled, return false
  if (!product?.esl_enabled && !product?.print_physical_tag) {
    return false
  }

  // If product has ESL enabled and physical tag disabled, return true
  if (product?.esl_enabled && !product?.print_physical_tag) {
    return true
  }

  // If product has physical tag enabled
  if (product?.print_physical_tag) {
    // If product has never been printed, return false
    if (!product?.label_last_printed_at) {
      return false
    }

    // If product has been printed and printed after start time, return true
    if (
      product?.print_physical_tag &&
      product?.start_time &&
      new Date(product?.label_last_printed_at) >= new Date(product?.start_time)
    ) {
      return true
    }

    return false
  }

  return false
}
