import React from 'react'
import { Redirect } from 'react-router'

import { SentryTags } from '@vori/dashboard-constants'
import { AuthErrorSource } from '@vori/dashboard-hooks/useCurrentUser/types'
import { captureException } from '@vori/dashboard-integrations/Sentry/utils'
import { routes as authRoutes } from '@vori/dashboard-routes/auth'
import { getFirebaseAuth } from '@vori/dashboard-utils/getFirebaseApp'
import { FlexNext as Flex, Loader } from '@vori/gourmet-components'

function LogoutPage(): JSX.Element {
  const auth = getFirebaseAuth()

  if (!auth) {
    return <Redirect to={authRoutes.login.root.path} />
  }

  auth.signOut().catch((error) => {
    captureException(() => error, {
      sentryTag: `${SentryTags.AUTH_PREFIX}${AuthErrorSource.LOGOUT}`,
    })
  })

  return (
    <Flex center direction="column" flex={1}>
      <Loader size="large" />
    </Flex>
  )
}

export { LogoutPage }
