import * as Sentry from '@sentry/react'

import {
  ENV_LOCAL,
  ENV_PROD,
  ENV_SENTRY_DSN,
  ENV_SENTRY_ENABLE_LOCAL_REPORTING,
  ENV_VERSION,
  ENVIRONMENT,
} from '@vori/dashboard-env'

export function initializeSentry(): void {
  if (ENV_LOCAL && !ENV_SENTRY_ENABLE_LOCAL_REPORTING) {
    return
  }

  Sentry.init({
    dsn: ENV_SENTRY_DSN,
    environment: ENVIRONMENT,
    release: ENV_VERSION,
    tracesSampleRate: 0.15,
    // This sets the sample rate to be 10% in prod. We don't sample in dev
    // because that's for internal usage and there's cheaper ways to figure
    // out problems.
    replaysSessionSampleRate: ENV_PROD ? 0.1 : 0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        // NOTE: This will disable built-in masking. Only use this if your site has no sensitive data, or if you've already set up other options for masking or blocking relevant data, such as 'ignore', 'block', 'mask' and 'maskFn'.
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
  })
}
