// https://github.com/voriteam/backend/blob/13240738b951e19673beb2adf116939ca6d5a5d6/shared/types/products/ProductChangeset.entity.ts#L16
export enum ProductChangesetEntityType {
  StoreProductView = 'store_products_view',
  MarginChangeView = 'margin_changes_view',
}

// https://github.com/voriteam/backend/blob/13240738b951e19673beb2adf116939ca6d5a5d6/shared/types/products/ProductChangeset.entity.ts#L21
export enum ProductChangesetExecutionStatus {
  PENDING = 'PENDING',
  STARTED = 'STARTED',
  SUCCEEDED = 'SUCCEEDED',
  FAILURE = 'FAILURE',
}

// https://github.com/voriteam/backend/blob/b4fbbfd082e0d072b35ee8af5110448690d93564/shared/types/products/ProductChangeset.ts#L25
export enum ProductChangesetLabelType {
  DANAVATION = 'DANAVATION',
  PRINTABLE = 'PRINTABLE',
  SES = 'SES',
  TOTAL = 'TOTAL',
}

// https://github.com/voriteam/backend/blob/b4fbbfd082e0d072b35ee8af5110448690d93564/shared/types/products/ProductChangeset.ts#L32
export interface ProductChangesetLabel {
  label: string
  type: ProductChangesetLabelType
  value: number
}

// https://github.com/voriteam/backend/blob/b4fbbfd082e0d072b35ee8af5110448690d93564/shared/types/products/ProductChangeset.ts#L38
export interface ProductChangesetSummary {
  changes: Array<ProductChangesetLabel>
  changesetStatus: ProductChangesetExecutionStatus
  clientKey: string
  dbAcknowledgedAt?: Date | null
  eslAcknowledgedAt?: Date | null
  posAcknowledgedAt?: Date | null
  totalChanges: number
  userID: string
}
