import dayjs from 'dayjs'
import compact from 'lodash/compact'

import {
  getLocalStorageRecord,
  setLocalStorageRecord,
} from '@vori/dashboard-utils/localStorage'

import { PosBannerConfigurationFlags } from '@vori/dashboard-constants'
import { PosBannerConfigurationDto } from '@vori/dashboard-rest-next/schemas'
import { USER_DATA_CACHE_KEY, USER_STORE_ID_CACHE_KEY } from './constants'

import {
  CurrentUserData,
  CurrentUserMetadata,
  CurrentUserMetadataBanner,
  CurrentUserMetadataStore,
  UserDataFromGQL,
} from './types'

export const cacheUserData = (userData: CurrentUserData) => {
  setLocalStorageRecord<CurrentUserData>(USER_DATA_CACHE_KEY, userData)
}

export const cacheUserStoreID = (storeID: string) => {
  localStorage.setItem(USER_STORE_ID_CACHE_KEY, storeID)
}

export const getCachedUserData = () =>
  getLocalStorageRecord<CurrentUserData>(USER_DATA_CACHE_KEY)

export const removeCachedUserData = () => {
  localStorage.removeItem(USER_DATA_CACHE_KEY)
  localStorage.removeItem(USER_STORE_ID_CACHE_KEY)
}

export const getDefaultUserMetadata = (
  userData: CurrentUserData,
): CurrentUserMetadata => {
  const cachedStoreID = localStorage.getItem(USER_STORE_ID_CACHE_KEY)
  const defaultTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  if (!userData.id) {
    return {
      banner: null,
      stores: [],
      selectedStoreID: '',
      selectedStoreName: '',
      timeZone: defaultTimezone,
      selectedStoreSupportsWic: false,
    }
  }

  /**
   * @todo Remove this once we have a better way to determine the default store
   * or have a store selector in the UI.
   */
  if (userData.companyID === 'UwVEiy0LFS4cWAoRihCL') {
    return {
      banner: null,
      stores: [],
      selectedStoreID: '352',
      selectedStoreName: 'Berkeley Bowl West',
      timeZone: 'America/Los_Angeles',
      selectedStoreSupportsWic: false,
    }
  }

  const banner: CurrentUserMetadataBanner | null = userData.retailUser
    ?.retailCompany.banner
    ? {
        id: userData.retailUser.retailCompany.banner.id,
        imageURL: userData.retailUser.retailCompany.banner.imageURL || null,
        name: userData.retailUser?.retailCompany.banner.name || 'Banner',
      }
    : null

  const stores: Array<CurrentUserMetadataStore> = compact(
    userData.retailUser?.retailCompany.banner
      ? userData.retailUser.retailCompany.banner.stores.nodes || []
      : [userData.retailUser?.retailCompany.store],
  ).map((store) => {
    const address =
      `${store.addressComponents?.addressLine1 || ''} ${store.addressComponents?.addressLine2 || ''}`.trim()

    return {
      address: address || store.address || 'No address',
      id: store.serialID || store.id,
      imageURL: store.imageURL || null,
      name: store.name || 'Store',
      timeZone: store.local_iana_timezone || defaultTimezone,
      supportsWic: store.supports_wic || false,
    }
  })

  const defaultStore = stores.length
    ? cachedStoreID
      ? stores.find(({ id }) => id === cachedStoreID) || stores[0]
      : stores[0]
    : null // NOTE: Vendors don't have any stores

  const metadata = {
    selectedStoreID: defaultStore?.id ?? null,
    selectedStoreName: defaultStore?.name ?? null,
    timeZone: defaultStore?.timeZone ?? defaultTimezone,
    selectedStoreSupportsWic: defaultStore?.supportsWic ?? false,
  }

  dayjs.tz.setDefault(metadata.timeZone)

  if (!cachedStoreID && metadata.selectedStoreID) {
    cacheUserStoreID(metadata.selectedStoreID)
  }

  return { ...metadata, banner, stores }
}

export function userDataIsFromGQL(
  userData: UserDataFromGQL | CurrentUserData,
): userData is UserDataFromGQL {
  return Boolean(userData.__typename)
}

const POS_BANNER_CONFIGURATION_FIELD_TO_FLAG = {
  gift_cards_enabled:
    PosBannerConfigurationFlags.WEB_FEATURE_LOYALTY_GIFT_CARDS,
  house_accounts_enabled:
    PosBannerConfigurationFlags.WEB_FEATURE_RETAIL_HOUSE_ACCOUNTS,
  physical_gift_cards_enabled:
    PosBannerConfigurationFlags.WEB_FEATURE_PHYSICAL_GIFT_CARDS,
}

export function convertPosBannerConfigurationsToFeatureFlags(
  posBannerConfigurations: PosBannerConfigurationDto[],
): PosBannerConfigurationFlags[] {
  const results: PosBannerConfigurationFlags[] = []
  if (!posBannerConfigurations.length) {
    return results
  }

  Object.entries(POS_BANNER_CONFIGURATION_FIELD_TO_FLAG).forEach(
    ([field, flag]) => {
      if (
        posBannerConfigurations[0][field as keyof PosBannerConfigurationDto]
      ) {
        results.push(flag)
      }
    },
  )

  return results
}
