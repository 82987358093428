import { captureException } from '@vori/dashboard-integrations/Sentry/utils'
import { UnitOfMeasureDto } from '@vori/dashboard-rest-next/schemas'
import Decimal from 'decimal.js'

const DEFAULT_ROUNDING_RULES = ['09', 19, 29, 39, 49, 59, 69, 79, 89, 99]

export function applyRoundingRule(
  number: Decimal,
  increments?: (number | string)[],
): Decimal {
  const value = number.times(100).round()
  const { base, cents } = splitValue(value.toNumber())

  const rounded = getRoundedCentValue(
    cents,
    increments || DEFAULT_ROUNDING_RULES,
  )

  return toDecimal(`${base}${rounded}`) as Decimal
}

const getRoundedCentValue = (
  cents: number,
  increments: (number | string)[],
) => {
  let roundedValue = increments[0]
  for (let i = 0; i < increments.length; i++) {
    const prevInc = Number(increments[i - 1])
    const currInc = Number(increments[i])
    if (cents <= currInc && cents > prevInc) {
      roundedValue = currInc
    }
  }
  return roundedValue
}

const splitValue = (value: number) => {
  return {
    base: Number(value.toString().slice(0, -2)),
    cents: Number(value.toString().slice(-2)),
  }
}

export const toPriceAndCents = (value: number | string): string | undefined => {
  if (value === '' || value === null) return ''
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })
    .format(Number(value))
    .replace(/,/, '')
    .replace('$', '')
}

export const toDecimal = (
  value: Decimal | number | string | undefined | null,
): Decimal | null => {
  if (value === '' || value === null || value === undefined) return null
  try {
    return new Decimal(value)
  } catch (e) {
    console.warn('Error converting to Decimal', e)
    captureException(() => e as Error)

    return null
  }
}

interface ProductCostData {
  cost: number
  costUOM: UnitOfMeasureDto['name']
  price: number // For margin and profit calculations.
  priceUOM: UnitOfMeasureDto['name']
  caseSize: number | null
  targetMargin?: number | null // For suggested retail price calculation.
  roundingRules?: number[] // Optional rounding rules for suggested retail price.
}

export interface MetricsResult {
  data: {
    unitCost: number
    suggestedRetail: number | null
    margin: number | null
    profit: number | null
  } | null
  error?: string
}

/**
 * Calculates various product metrics using Decimal.js:
 *   - unitCost (the cost per unit, in cents)
 *   - suggestedRetail (the suggested retail price, in cents)
 *   - margin (the profit margin as a percentage)
 *   - profit (the profit per unit, in cents)
 *
 * If a valid unit cost cannot be calculated, returns { data: null, error: "..." }.
 */
export function calculateProductMetrics(data: ProductCostData): MetricsResult {
  const {
    cost,
    costUOM,
    price,
    priceUOM,
    caseSize,
    targetMargin,
    roundingRules = [],
  } = data

  if (!cost) {
    return {
      data: null,
    }
  }
  // Determine the conversion factor between the cost UOM and price UOM.
  let conversionFactor: number | null = null
  if (
    costUOM === 'CASE' &&
    caseSize != null &&
    caseSize > 0 &&
    ['EACH', 'LB'].includes(priceUOM)
  ) {
    conversionFactor = caseSize
  } else if (costUOM === 'LB' && priceUOM === 'OZ') {
    conversionFactor = 16
  } else if (
    (costUOM === priceUOM && ['EACH', 'CASE', 'LB'].includes(costUOM)) ||
    (costUOM === 'EACH' && priceUOM === 'LB')
  ) {
    conversionFactor = 1
  }

  // If we cannot determine a valid conversion factor, unit cost cannot be calculated.
  if (conversionFactor === null) {
    let error = ''

    if (costUOM === 'CASE' && caseSize == null && cost) {
      error = 'Calculation issue: Case size is required when cost UOM is CASE.'
    }
    return {
      data: null,
      error:
        error ||
        `Calculation issue: Unable to determine conversion factor from cost UOM (${costUOM}) to price UOM (${priceUOM}).`,
    }
  }

  // Calculate the unit cost using Decimal.js.
  const unitCostDecimal = new Decimal(cost).dividedBy(conversionFactor)

  // Compute the suggested retail price if a target margin is provided.
  let suggestedRetailDecimal: Decimal | null = null
  if (targetMargin != null) {
    // Compute the divisor: (1 - targetMargin/100)
    const marginDivisor = new Decimal(1).minus(
      new Decimal(targetMargin).dividedBy(100),
    )
    if (marginDivisor.equals(0)) {
      // Cannot divide by zero—set suggestedRetailDecimal to null.
      suggestedRetailDecimal = null
    } else {
      suggestedRetailDecimal = unitCostDecimal.dividedBy(marginDivisor).round()
      if (roundingRules.length >= 1) {
        // Apply additional rounding rules if provided.
        suggestedRetailDecimal = applyRoundingRule(
          suggestedRetailDecimal,
          roundingRules,
        )
      }
    }
  }

  // Compute margin and profit
  let marginDecimal: Decimal | null = null
  let profitDecimal: Decimal | null = null
  const priceDecimal = new Decimal(price)
  if (priceDecimal.equals(0)) {
    profitDecimal = new Decimal(0)
    marginDecimal = new Decimal(0)
  } else {
    profitDecimal = priceDecimal.minus(unitCostDecimal)
    marginDecimal = profitDecimal
      .times(100)
      .dividedBy(priceDecimal || 1)
      .toDecimalPlaces(2)
  }
  return {
    data: {
      unitCost: unitCostDecimal.toNumber(),
      suggestedRetail: suggestedRetailDecimal
        ? suggestedRetailDecimal.toNumber()
        : null,
      margin: marginDecimal ? marginDecimal.toNumber() : null,
      profit: profitDecimal ? profitDecimal.toNumber() : null,
    },
  }
}
