import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useGlobalStore } from '../../../state-management/store'
import { SearchSmIcon } from '@vori/gourmet-icons'
import { Command } from 'cmdk'
import ProductSearchDetailView from './ProductSearchDetailView'

import { Loader } from '@vori/gourmet-components'
import { useHistory } from 'react-router-dom'
import {
  ProductSearchContainer,
  ProductSearchFooter,
  ProductSearchFooterIcons,
  ProductSearchHeader,
  ProductSearchResult,
  ProductSearchResultSubHead,
} from './ProductSearchStyledComponents'

import RecentProductSearches from './RecentProductSearches'

import './product-search.css'
import { compact, throttle } from 'lodash'
import ErrorBoundary from '@vori/dashboard-components/ErrorBoundary'
import { SentryTags } from '@vori/dashboard-constants'
import { useCurrentUserState } from '@vori/dashboard-hooks/useCurrentUser'

const QUERY_DEBOUNCE_AND_THROTTLE_MS = 400 as const
const SPLIT_DELIMITER = '#-#' as const

let inputTimeoutId: NodeJS.Timeout | null = null
let scrollPositionKeeper = 0

const ProductSearch = () => {
  const scrollableRef = useRef<HTMLDivElement>(null)
  const history = useHistory()

  const { user } = useCurrentUserState()

  const {
    clearSearchResults,
    productSearchResults,
    canShowRecentSearches,
    searchProduct,
    loading,
  } = useGlobalStore((state) => state.products)

  const toggleDisplay = useGlobalStore(
    (state) => state.globalModal.toggleDisplay,
  )

  const isModalClosed = useGlobalStore((state) => state.globalModal.isClosed)

  const [value, setValue] = useState('')
  const [isChanging, setIsChanging] = useState(false)
  const [isPristine, setIsPristine] = useState(true)
  const [selectedProductIndex, setSelectedProductIndex] = useState(-1)
  const inputRef = useRef<HTMLInputElement>(null)

  const resetResultsView = useCallback(() => {
    setValue('')
    scrollPositionKeeper = 0
    clearSearchResults()
  }, [clearSearchResults])

  useEffect(() => {
    if (isModalClosed) {
      resetResultsView()
    }

    if (!isModalClosed && inputRef.current) {
      setTimeout(() => {
        inputRef.current?.focus()
      }, 0)
    }
  }, [inputRef, isModalClosed, resetResultsView])

  const handleScroll = throttle(() => {
    const scrollable = scrollableRef.current
    if (scrollable) {
      const { scrollTop, scrollHeight, clientHeight } = scrollable
      if (
        scrollTop + clientHeight >= scrollHeight * 0.95 &&
        user.metadata.selectedStoreID
      ) {
        scrollPositionKeeper = scrollTop
        searchProduct(user.metadata.selectedStoreID, value)
      }
    }
  }, QUERY_DEBOUNCE_AND_THROTTLE_MS) // Throttling the scroll handler to execute at most every 400ms

  useEffect(() => {
    const scrollable = scrollableRef.current

    if (scrollable) {
      scrollable.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (scrollable) {
        scrollable.removeEventListener('scroll', handleScroll)
      }
    }
  }, [handleScroll])

  useEffect(() => {
    const scrollable = scrollableRef.current
    if (scrollable && productSearchResults.length > 0) {
      scrollable.scrollTop = scrollPositionKeeper
    }
  }, [productSearchResults])

  const handleInputChange = (value: string) => {
    setIsPristine(false)
    setValue(value)
    setIsChanging(true)
    scrollPositionKeeper = 0
    if (inputTimeoutId) {
      clearTimeout(inputTimeoutId)
    }

    if (user.metadata.selectedStoreID) {
      inputTimeoutId = setTimeout(() => {
        searchProduct(user.metadata.selectedStoreID!, value)
        setIsChanging(false)
      }, QUERY_DEBOUNCE_AND_THROTTLE_MS)
    } else {
      throw new Error('Encountered a missing store id for the current user')
    }
  }

  const renderNoResults = () => {
    if (!isPristine && !loading && !isChanging) {
      return (
        <Command.Empty
          className="product-search-empty"
          style={{ placeSelf: 'center' }}
        >
          No results found
        </Command.Empty>
      )
    } else {
      return null
    }
  }

  const updateSelectedProductWithElement = (trueTarget: HTMLElement) => {
    const { itemIndex = null } = trueTarget.dataset
    if (itemIndex) {
      setSelectedProductIndex(+itemIndex)
    }
  }

  const handleItemDelegateClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const trueTarget = event.target as HTMLElement
    updateSelectedProductWithElement(trueTarget)
  }

  const handleViewProduct = () => {
    resetResultsView()
    toggleDisplay(false)
  }

  const handleItemSelected = (value: string) => {
    const productArrayPosition = +value.split(SPLIT_DELIMITER)[0]
    const { id = null } = productSearchResults[productArrayPosition]
    if (id) {
      history.push(`/retail/products/${id}/update`)
      handleViewProduct()
    } else {
      throw new Error(
        `could not find a product at index ${productArrayPosition} in product search results array`,
      )
    }
  }

  const handleMouseOver = (event: React.MouseEvent<HTMLDivElement>) => {
    const trueTarget = event.target as HTMLElement
    updateSelectedProductWithElement(trueTarget)
  }

  const handleArrowBasedNavigation = (
    event: React.KeyboardEvent<HTMLDivElement>,
  ) => {
    const { code } = event
    if (code === 'ArrowDown' || code === 'ArrowUp') {
      /**
       * NOTICE: this setTimeout is used to introduce a delay to ensure that a DOM update is completed
       * Thelibrary handles the selection via keyboard event, but does not make it easily accesible
       * it has many event hooks, but they don't quite fire as expected. This workaround solves that problem without
       * introducing new event listeners arbitrarily
       */
      setTimeout(() => {
        const trueTarget = document.querySelector(
          '[data-selected="true"]',
        ) as HTMLElement

        if (trueTarget) {
          updateSelectedProductWithElement(trueTarget)
        }
      }, 0)
    }
  }

  return (
    <ErrorBoundary sentryTag={SentryTags.PRODUCT_SEARCH}>
      <ProductSearchContainer data-product-search-container>
        <Command
          loop
          onMouseOver={handleMouseOver}
          onKeyDown={handleArrowBasedNavigation}
        >
          <ProductSearchHeader>
            {loading || isChanging ? <Loader /> : <SearchSmIcon />}
            <Command.Input
              placeholder="Search Products"
              onValueChange={handleInputChange}
              value={value}
              autoFocus={true}
              ref={inputRef}
              tabIndex={0}
            />
          </ProductSearchHeader>
          <ProductSearchResult>
            {!canShowRecentSearches ? (
              <>
                <Command.List>
                  <div
                    className="product-search-results-left"
                    ref={scrollableRef}
                  >
                    <ProductSearchResultSubHead>
                      Results
                    </ProductSearchResultSubHead>
                    <Command.Group forceMount onClick={handleItemDelegateClick}>
                      {isChanging === false && productSearchResults.length
                        ? productSearchResults.map((item, idx) => (
                            <Command.Item
                              className={
                                selectedProductIndex === idx
                                  ? 'product-search-result-item product-search-selected-item'
                                  : 'product-search-result-item'
                              }
                              key={item.id}
                              data-item-index={idx}
                              value={`${idx}${SPLIT_DELIMITER}${item.name}`}
                              onSelect={handleItemSelected}
                            >
                              <div className="product-search-result-item-content">
                                <h6>{item.name}</h6>
                                <div>
                                  {compact([
                                    item.brand,
                                    item.barcode,
                                    item.unit_volume,
                                  ]).join(' \u2022 ')}
                                </div>
                              </div>
                            </Command.Item>
                          ))
                        : renderNoResults()}
                    </Command.Group>
                  </div>
                </Command.List>
                <div className="product-search-results-right">
                  <ProductSearchDetailView
                    data={productSearchResults[selectedProductIndex] || null}
                    ctaHandler={handleViewProduct}
                  />
                </div>
              </>
            ) : (
              <RecentProductSearches redoPastSearch={handleInputChange} />
            )}
          </ProductSearchResult>
          {!canShowRecentSearches ? (
            <ProductSearchFooter>
              <div>
                <ProductSearchFooterIcons>&#8593;</ProductSearchFooterIcons>
                <ProductSearchFooterIcons>&#8595;</ProductSearchFooterIcons>
                <span> to navigate</span>
              </div>
              <div>
                <ProductSearchFooterIcons>enter</ProductSearchFooterIcons>
                <span> to select</span>
              </div>
              <div>
                <ProductSearchFooterIcons>esc</ProductSearchFooterIcons>
                <span> to close</span>
              </div>
            </ProductSearchFooter>
          ) : null}
        </Command>
      </ProductSearchContainer>
    </ErrorBoundary>
  )
}

export default ProductSearch
