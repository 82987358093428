import { useMemo } from 'react'
import { useLocation } from 'react-router'

import { routes as authRoutes } from '@vori/dashboard-routes/auth'
import { routes as devRoutes } from '@vori/dashboard-routes/dev'
import { routes as retailRoutes } from '@vori/dashboard-routes/retail'
import { routes as vendorRoutes } from '@vori/dashboard-routes/vendor'
import { RouteUtil } from '@vori/dashboard-utils'
import { sharedRoutes } from '@vori/dashboard-shared-routes'

import { OldReportingTab } from '../../pages/retail/pages/reporting/types'

const authRouteUtils = Object.values(authRoutes).map((pages) =>
  Object.values(pages),
)

const devRouteUtils = Object.values(devRoutes).map((pages) =>
  Object.values(pages),
)

const retailRouteUtils = Object.values(retailRoutes).map((pages) =>
  Object.values(pages),
)

const vendorRouteUtils = Object.values(vendorRoutes).map((pages) =>
  Object.values(pages),
)

const sharedRouteUtils = Object.values(
  Object.values({
    payments: sharedRoutes().payments,
  }).map((pages) => Object.values(pages)),
)

const routeUtils: Array<RouteUtil<string>> = [
  ...authRouteUtils,
  ...devRouteUtils,
  ...retailRouteUtils,
  ...sharedRouteUtils,
  ...vendorRouteUtils,
].flat()

function usePageTitle(): { pageTitle: string; pageParentTitle?: string } {
  const location = useLocation()

  const currentRoute = useMemo<RouteUtil<string> | null>(() => {
    return routeUtils.find((routeUtil) => routeUtil.match()?.isExact) || null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const routeMatch = currentRoute?.useRouteMatch()

  document.title = `Vori | ${currentRoute?.title || 'Dashboard'}`

  if (
    routeMatch &&
    (routeMatch.params.reportingTab === OldReportingTab.TRANSACTIONS ||
      routeMatch.params.reportingTab === OldReportingTab.TILLS)
  ) {
    return {
      pageParentTitle: 'Point of Sale',
      pageTitle: currentRoute?.title || 'Dashboard',
    }
  }

  return {
    pageParentTitle: currentRoute?.parentTitle,
    pageTitle: currentRoute?.title || 'Dashboard',
  }
}

export { usePageTitle }
