import React from 'react'

import { useHistory } from 'react-router'
import { ChevronsUpDown, LogOut, MessageCircleQuestionIcon } from 'lucide-react'

import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '@vori/dashboard-components/shadcn/ui/avatar'

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@vori/dashboard-components/shadcn/ui/dropdown-menu'

import {
  SidebarFooter,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar,
} from '@vori/dashboard-components/shadcn/ui/sidebar'

import { RetailUserGate } from '@vori/dashboard-components'

import { ENV_PROD } from '@vori/dashboard-env'
import { EventNames, trackEvent } from '@vori/dashboard-integrations/Segment'

import { routes } from '@vori/dashboard-routes/auth'
import { show as showIntercom } from '@intercom/messenger-js-sdk'

import { useCurrentUserState } from '@vori/dashboard-hooks/useCurrentUser'

export function AppSidebarFooter(): JSX.Element | null {
  const { isMobile } = useSidebar()
  const { user } = useCurrentUserState()

  const history = useHistory()

  return (
    <SidebarFooter>
      <RetailUserGate>
        <SidebarMenuButton
          onClick={(event) => {
            event.preventDefault()

            if (!ENV_PROD) {
              return
            }

            trackEvent(EventNames.VENDOR_NAV_GET_SUPPORT_CLICKED)
            showIntercom()
          }}
        >
          <MessageCircleQuestionIcon />
          Get Support
        </SidebarMenuButton>
      </RetailUserGate>

      <SidebarMenu>
        <SidebarMenuItem>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <SidebarMenuButton
                size="lg"
                className="data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground"
              >
                <Avatar className="h-8 w-8 rounded-lg">
                  <AvatarImage
                    src={user.data.companyImageURL || undefined}
                    alt={user.data.displayName || undefined}
                  />
                  <AvatarFallback className="rounded-lg">CN</AvatarFallback>
                </Avatar>
                <div className="grid flex-1 text-left text-sm leading-tight">
                  <span className="truncate font-semibold">
                    {user.data.displayName}
                  </span>
                  <span className="truncate text-xs">{user.data.email}</span>
                </div>
                <ChevronsUpDown className="ml-auto size-4" />
              </SidebarMenuButton>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              className="w-[--radix-dropdown-menu-trigger-width] min-w-56 rounded-lg"
              side={isMobile ? 'bottom' : 'right'}
              align="end"
              sideOffset={4}
            >
              <DropdownMenuItem
                onClick={() => {
                  history.push(routes.logout.root.path)
                }}
              >
                <LogOut />
                Log out
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </SidebarMenuItem>
      </SidebarMenu>
    </SidebarFooter>
  )
}
