import React from 'react'

import {
  DevUserGate,
  RetailUserGate,
  VendorUserGate,
} from '@vori/dashboard-components'

import { useCurrentUserState } from '@vori/dashboard-hooks/useCurrentUser'

import { TruckIcon } from 'lucide-react'

import {
  Sidebar,
  SidebarContent,
  SidebarRail,
  SidebarSeparator,
  useSidebar,
} from '@vori/dashboard-components/shadcn/ui/sidebar'

import { AppSidebarFooter } from '@vori/dashboard-components/AppSidebarFooter'
import { AppSidebarHeader } from '@vori/dashboard-components/AppSidebarHeader'
import { DevNavigation } from '../pages/dev/components'
import { RetailNavigation } from '@vori/dashboard-retail-components/RetailNavigation'
import { RetailNavigationHeader } from '@vori/dashboard-retail-components/RetailNavigationHeader'
import { AppSidebarHeader as DevAppSidebarHeader } from '../pages/dev/components/AppSidebarHeader'
import { VendorNavigation } from '../pages/vendor/components'

function AppSidebar(): JSX.Element | null {
  const { user } = useCurrentUserState()
  const { state, toggleSidebar } = useSidebar()

  if (!user.state.isLoggedIn) {
    return null
  }

  return (
    <Sidebar
      collapsible="icon"
      onClick={() => {
        if (state === 'collapsed') {
          toggleSidebar()
        }
      }}
    >
      <RetailUserGate>
        <RetailNavigationHeader />
        <SidebarContent>
          <RetailUserGate>
            <RetailNavigation />
          </RetailUserGate>
        </SidebarContent>
      </RetailUserGate>

      <DevUserGate>
        <DevAppSidebarHeader />
        <SidebarContent>
          <DevNavigation />
        </SidebarContent>
      </DevUserGate>

      <VendorUserGate>
        <AppSidebarHeader icon={TruckIcon} />
        <SidebarContent>
          <VendorNavigation />
        </SidebarContent>
      </VendorUserGate>

      <SidebarSeparator />
      <AppSidebarFooter />
      <SidebarRail />
    </Sidebar>
  )
}

export { AppSidebar }
