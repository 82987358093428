import Cookies, { CookieAttributes } from 'js-cookie'
import { User } from 'firebase/auth'
import { ENV_FIREBASE_API_KEY, ENV_PROD } from '@vori/dashboard-env'

const cookieOptions: CookieAttributes = {
  secure: ENV_PROD,
  ...(ENV_PROD && { domain: '.vori.com' }),
}

export const FIREBASE_PERSISTED_AUTH_KEY = `firebase:authUser:${ENV_FIREBASE_API_KEY}:[DEFAULT]`

/**
 * Gets the current user's Firebase authentication token and updates the shared
 * auth cookie with the Firebase persisted auth that contains the latest token.
 */
export async function getFirebaseAuthToken(
  currentFirebaseUser: User,
): Promise<string> {
  const token = await currentFirebaseUser.getIdToken()

  Cookies.set(
    'firebaseAuth',
    localStorage.getItem(FIREBASE_PERSISTED_AUTH_KEY) || '',
    cookieOptions,
  )

  return token
}

export function clearFirebaseAuthCookie() {
  Cookies.remove('firebaseAuth', cookieOptions)
}

export function hasFirebaseAuthCookie(): boolean {
  return Boolean(Cookies.get('firebaseAuth'))
}

export function hydrateFirebaseAuthFromCookie() {
  try {
    const firebaseAuthCookie = Cookies.get('firebaseAuth')

    if (firebaseAuthCookie) {
      localStorage.setItem(FIREBASE_PERSISTED_AUTH_KEY, firebaseAuthCookie)
    }
  } catch (error) {
    console.error('Error hydrating Firebase auth user in localStorage', error)
  }
}

export function userFromCookieIsDifferentFromLocalStorage(): boolean {
  try {
    const firebaseAuthCookie = Cookies.get('firebaseAuth')
    const firebaseAuthLocalStorage = localStorage.getItem(
      FIREBASE_PERSISTED_AUTH_KEY,
    )

    const parsedFirebaseAuthCookie = JSON.parse(firebaseAuthCookie || '{}')
    const parsedFirebaseAuthLocalStorage = JSON.parse(
      firebaseAuthLocalStorage || '{}',
    )

    return (
      parsedFirebaseAuthCookie.email &&
      parsedFirebaseAuthLocalStorage.email &&
      parsedFirebaseAuthCookie.email !== parsedFirebaseAuthLocalStorage.email
    )
  } catch {
    return false
  }
}
