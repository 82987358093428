import { ENV_GAE_REST_ENDPOINT } from '@vori/dashboard-env'

import { getHTTPHeaders } from './getHTTPHeaders'
import { handleHTTPResponse, withForwardSlash } from './utils'

/**
 * Executes a `POST` request against our REST API.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods/POST}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API}
 */
export async function post<
  TResponse extends Record<string, unknown> | unknown,
  TBody = BodyInit | unknown,
>(
  /**
   * The endpoint you want to execute the `GET` request against.
   */
  endpoint: string,
  /**
   * The body containing data associated with the request.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Messages#body}
   */
  body: TBody,
  /**
   * Options to be passed directly to the `fetch` API.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Request#properties}
   */
  options?: RequestInit,
): Promise<TResponse> {
  const headers = await getHTTPHeaders()

  const fetchURL = `${ENV_GAE_REST_ENDPOINT}${withForwardSlash(endpoint)}`

  const fetchRequest = {
    ...options,
    method: 'POST',
    body: body instanceof FormData ? body : JSON.stringify(body),
    headers: {
      ...headers,
      ...(!(body instanceof FormData) && {
        'Content-Type': 'application/json',
      }),
      ...options?.headers,
    },
  }

  return fetch(fetchURL, fetchRequest).then((response) =>
    handleHTTPResponse<TResponse>(fetchURL, fetchRequest, response),
  )
}

/**
 * Executes a `POST` request against our REST API, using FormData to allow for sending files.
 */
export async function postWithFileUpload<
  TResponse extends Record<string, unknown> | unknown,
>(
  /**
   * The endpoint you want to execute the `POST` request against.
   */
  endpoint: string,
  /**
   * The body containing data associated with the request.
   */
  body: FormData,
  /**
   * Options to be passed directly to the `fetch` API.
   */
  options?: RequestInit,
): Promise<TResponse> {
  const headers = await getHTTPHeaders()

  const fetchURL = `${ENV_GAE_REST_ENDPOINT}${withForwardSlash(endpoint)}`

  const fetchRequest = {
    ...options,
    method: 'POST',
    body,
    headers: {
      ...headers,
      ...options?.headers,
    },
  }

  return fetch(fetchURL, fetchRequest).then((response) =>
    handleHTTPResponse<TResponse>(fetchURL, fetchRequest, response),
  )
}
