import { UserType } from '@vori/gql-dashboard'

import {
  captureException,
  captureMessage,
} from '@vori/dashboard-integrations/Sentry/utils'

import { getFirebaseAuthToken } from './auth'
import { getAuthTokenFromUrl } from '../getAuthTokenFromUrl'
import { getFirebaseAuth } from '../getFirebaseApp'
import { getTypedTryCatchError } from '@vori/dashboard-utils/getTypedTryCatchError'

export type HTTPHeaders = {
  'X-Client-App'?: UserType | 'JWT'
  'X-Client-Platform'?: string
  authorization?: string
} & { [key in string]: string }

export async function getHTTPHeaders(): Promise<HTTPHeaders | null> {
  const JWT = getAuthTokenFromUrl()

  // If a JSON web token is present in the URL, then we use it to authenticate
  // the request. As of right now, this is only the case for the payments page,
  // where users can emit payments without being logged in.
  if (JWT != null) {
    return {
      'X-Client-Platform': 'WEB',
      authorization: `Bearer ${JWT}`,
    }
  }

  const auth = getFirebaseAuth()
  let hadInternetConnectionWhenFetchingToken: boolean | null = null

  // If we couldn't retrieve the Firebase application instance, then we can't
  // get the current user's auth token, so we log this case on Sentry and return
  // `null`. This will then trigger an HTTP request error that should be handled
  // within the context of the page/feature executing the request.
  if (auth === null) {
    if (!window.location.pathname.startsWith('/auth')) {
      captureMessage(
        () => 'Auth token not fetched: failed to retrieve Firebase app.',
      )
    }

    return null
  }

  // If we couldn't retrieve the Firebase current user object, then we can't
  // get the current user's auth token, so we log this case on Sentry and return
  // `null`. This will then trigger an HTTP request error that should be handled
  // within the context of the page/feature executing the request.
  if (auth.currentUser === null) {
    if (!window.location.pathname.startsWith('/auth')) {
      captureMessage(
        () =>
          'Auth token not fetched: failed to retrieve Firebase current user.',
      )
    }

    return null
  }

  try {
    hadInternetConnectionWhenFetchingToken = navigator.onLine

    // Get the current user's auth token to send through the `authorization``
    // HTTP header. The `getIdToken` method will automatically refresh the token
    // if it's currently expired, so we are always guaranteed to have a fresh and
    // valid auth token for each HTTP request.
    const token = await getFirebaseAuthToken(auth.currentUser)

    return {
      'X-Client-Platform': 'WEB',
      authorization: `Bearer ${token}`,
    }
  } catch (error) {
    captureException((scope) => {
      scope.setContext('Network Status', {
        hadInternetConnectionWhenFetchingToken,
      })

      return getTypedTryCatchError(error)
    })
  }

  return null
}
